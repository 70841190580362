import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const supportUserSlice = createSlice({
  name: "supportUser",
  initialState: {
    supportUser: null,
    supportSelectedEvent: {},
    supportSelectedProgram: {},
    supportDTO: {},
    filters: {
      atheleteList: null,
      activityList: null,
      participantListFilters: null,
    },
    createdChallenge:{}
  },
  reducers: {
    updateSupportUserDetails: (state, action) => {
      state.supportUser = action.payload;
    },
    updateSupportDTODetails: (state, action) => {
      state.supportDTO = action.payload;
    },
    updateSupportSelectedEvent: (state, action) => {
      state.supportSelectedEvent = action.payload;
    },
    updateSupportSelectedProgram: (state, action) => {
      state.supportSelectedProgram = action.payload;
    },
    removeSupportUserDetails: (state) => {
      state.supportUser = null;
      state.supportSelectedEvent = null;
      state.supportDTO = null;
    },
    updateAtheleteSetFilters: (state, action) => {
      state.filters.atheleteList = action.payload;
    },
    updateActivitySetFilters: (state, action) => {
      state.filters.activityList = action.payload;
    },
    updateParticipantSetFilters: (state, action) => {
      state.filters.participantListFilters = action.payload;
    },
  
    IsCurrentDraftChallengeData: (state, action) => {
      state.createdChallenge = action.payload;
    },
  },
});

export const {
  updateSupportUserDetails,
  removeSupportUserDetails,
  updateSupportDTODetails,
  updateSupportSelectedEvent,
  updateAtheleteSetFilters,
  updateActivitySetFilters,
  updateParticipantSetFilters,
  updateSupportSelectedProgram,
  IsCurrentDraftChallengeData,
} = supportUserSlice.actions;

export default supportUserSlice.reducer;
