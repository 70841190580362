import React, { useState, useRef, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import html2canvas from "html2canvas";
import { AppBar, Button, CardMedia, Toolbar } from "@material-ui/core";
import eventFrame from "../../images/FrameAttachment/EventFrame.png";
import eventFrameNull from "../../images/FrameAttachment/EventFrameNull.png";
import Snackbar from "./Snackbar";
import { PhotoCamera } from "@material-ui/icons";
import ShareIcon from "@material-ui/icons/Share";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { loading } from "./Spinner";
import { isMobile } from "react-device-detect";
import { saveUserPhotoOfFrame } from "../../apis/eventService";
import { KhadakwaslaBanner } from "../../images/FrameAttachment";

function FrameCreation({ classes }) {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [userId, setUserId] = useState(null);
  const frameRef = useRef(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let paramId = urlParams.get("id");
    setUserId(paramId);
  }, []);

  const shareImage = async () => {
    if (frameRef.current) {
      // Set loading to true before starting the sharing process
      loading(true);

      try {
        if (uploadedImage === null) {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Upload or Capture the Image",
          });
        } else {
          // Capture the frame and convert it to an image
          const canvas = await html2canvas(frameRef.current, {
            useCORS: true,
            logging: true,
            allowTaint: false,
            scale: 2, // Increase the scale factor to improve resolution
          });

          const imageBlob = await new Promise((resolve) =>
            canvas.toBlob(resolve, "image/png")
          );

          // Prepare share data
          const filesArray = [
            new File([imageBlob], "KhadakwaslaEvent.png", {
              type: imageBlob.type,
              lastModified: new Date().getTime(),
            }),
          ];

          const shareData = {
            title: "Khadakwasla Ultra 2024",
            text: "Join Us For Khadakwasla 2024",
            url: "https://khadakwaslaultra.com",
            files: filesArray,
          };

          // Attempt to share the image
          await navigator.share(shareData);

          const file = new File([imageBlob], "KhadakwaslaEvent.png", {
            type: imageBlob.type,
            lastModified: new Date().getTime(),
          });

          // Save the image to the database
          const customParams = { file };
          await saveUserPhotoOfFrame(customParams, userId);
        }
      } catch (err) {
        console.log("Error: ", err);
      } finally {
        // Set loading to false after the process finishes
        loading(false);
      }
    }
  };

  const handleImageUpload = (e) => {
    loading(true);
    setUploadedImage(null);
    const file = e.target.files[0];

    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const newImage = URL.createObjectURL(file);
      setUploadedImage(newImage);
      setTimeout(() => {
        loading(false);
      }, 2000);
    } else {
      loading(false);
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Only JPEG or PNG image types are allowed",
      });
    }
    e.target.value = null; // Reset file input
  };

  return (
    <>
      {/* <AppBar className={classes.appBar}>
        <Toolbar>Khadakwasla Ultra 2024</Toolbar>
      </AppBar> */}
      <div className={classes.main}>
        <div>
          <CardMedia className={classes.cardmedia}>
            <img
              id="banner"
              className={classes.media}
              src={KhadakwaslaBanner}
              alt={"Banner"}
            />
          </CardMedia>
        </div>

        <div className={classes.frameText}>Create Frame </div>

        <div className={classes.imageContainer}>
          <div
            ref={frameRef}
            style={{
              width: "350px",
              height: "350px",
              position: "relative",
              justifyContent: "center",
            }}
          >
            {/* Background Image (User Uploaded) */}
            <div
              style={{
                position: "absolute",
                top: 40,
                left:
                  isMobile && window.innerWidth < 380
                    ? "60px"
                    : isMobile
                    ? "76px"
                    : "80px",
                right: 0,
                bottom: 0,
                height: "78%",
                width: isMobile ? "70%" : "70%",
                backgroundImage: `url(${uploadedImage})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                transform: "rotate(-3.5deg)",
                transition: "transform 0.3s ease",
                borderRadius: "5px",
              }}
            />

            {/* Overlay Frame Image */}
            <img
              src={uploadedImage === null ? eventFrameNull : eventFrame}
              alt="Frame"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                pointerEvents: "none",
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://via.placeholder.com/150";
              }}
            />
          </div>
        </div>

        <div className={classes.buttonAlignment}>
          <Button
            color="primary"
            variant="contained"
            className={classes.actionBtn}
          >
            {/* Upload Photo <CloudUploadIcon fontSize="small" style={{ marginLeft: "8px" }}/> */}
            <label htmlFor="contained-button-file" style={{ display: "flex" }}>
              Upload Photo{" "}
              <CloudUploadIcon fontSize="small" style={{ marginLeft: "8px" }} />
            </label>
            <input
              type="file"
              id="contained-button-file"
              style={{ display: "none" }}
              accept="image/jpeg, image/png"
              onChange={handleImageUpload}
            />
          </Button>

          <Button
            color="primary"
            variant="contained"
            className={classes.actionBtn}
            onClick={shareImage}
          >
            Share
            <ShareIcon fontSize="small" style={{ marginLeft: "8px" }} />
          </Button>
        </div>
      </div>
    </>
  );
}

const styles = (theme) => ({
  appBar: {
    background: "#3a6ea5",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
    zIndex: 100,
    fontSize: "20px",
    position: "fixed",
    // fontWeight: "bold",
  },
  main: {
    // marginTop: "68px",
    marginTop: "20px",
    padding: "10px",
  },
  frameText: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
    paddingBottom: "0px",
    paddingRight: "0px",
    fontSize: "xx-large",
    fontWeight: "bold",
    [theme.breakpoints.up("lg")]: {
      margin: "auto",
      width: "47%",
    },
  },
  cardmedia: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    position: "relative",
    justifyContent: "center",
  },
  media: {
    width: "100%",
    height: "auto",
    // maxWidth: "60%",
    // minWidth: "100%",
    margin: "auto",
    objectFit: "content",
    borderRadius: "8px",
    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.15)",
    [theme.breakpoints.up("lg")]: {
      width: "auto",
      height: "400px",
    },
  },
  imageContainer: {
    display: "flex",
    borderRadius: "8px",
    justifyContent: "center",
    margin: "10px",
    padding: "10px",
  },
  buttonAlignment: {
    display: "flex",
    // marginTop: "20px",
    // padding: "15px",
    justifyContent: "center",
  },
  accessCamera: {
    position: "absolute",
    top: 75,
    left: 75,
    transform: "rotate(-3.5deg)",
    transition: "transform 0.3s ease",
  },
  actionBtn: {
    backgroundColor: "#09456C",
    width: "150px",
    alignContent: "center",
    margin: "2px",
    padding: "10px",
    color: "#fff",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "5px",
    },
  },
});

export default compose(withStyles(styles))(FrameCreation);
