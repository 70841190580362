import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import { modifyProgramChallengeParticipant } from "../../../../../apis/programService";

function VideoBlock({
  isUserRegistered,
  videoLink,
  classes,
  challengeId,
  openBlock,
  handleOpenBlock,
  currentRunningChallenge,
  isVideoViewedState,
  handleVideoViewed,
  setVideoLoaded = () => {},
}) {
  const expand = openBlock?.challengeId == challengeId;

  const handleOnProgress = (progressProp) => {
    if (ApplicationUtil.isuserLoggedIn()) {
      if (
        progressProp?.played > 0.8 &&
        !currentRunningChallenge?.isVideoViewed &&
        !isVideoViewedState
      ) {
        let runnerData = ApplicationUtil.getRunnerDetails();
        let runnerId = runnerData?.runner?.id;
        const reqParams = {
          programChallengeId: challengeId,
          isVideoViewed: true,
          runnerId: runnerId,
        };
        modifyProgramChallengeParticipant(reqParams, false).then(() => {
          // updateCurrentRunningProgramChallenge({
          //   ...this.props.currentRunningProgramChallenge,
          //   isVideoViewed: true,
          // });
          //   setIsVideoViewed(false);
          handleVideoViewed(true);
        });
      }
    }
  };

  useEffect(() => {
    if (!videoLink) {
      setVideoLoaded(true);
    }
  }, []);

  return (
    <>
      {isUserRegistered && videoLink && (
        <div className={classes?.videoWrapperClass}>
          {expand && (
            <ReactPlayer
              onReady={() => {
                if (expand) {
                  setVideoLoaded(true);
                }
              }}
              url={videoLink}
              width={"100%"}
              height={"auto"}
              className="reactPlayer"
              playing={expand}
              controls={true}
              onProgress={handleOnProgress}
              onEnded={() => {}}
            />
          )}
          <div className="expand" onClick={() => handleOpenBlock(challengeId)}>
            <span>{expand ? "Less" : "More"} details</span>
            {expand ? <ExpandLess /> : <ExpandMore />}
          </div>
        </div>
      )}
    </>
  );
}

const styles = (theme) => ({
  videoWrapperClass: {
    marginTop: "10px",
    "& .reactPlayer video": {
      maxHeight: "40vh",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "30vh",
      },
    },
    "& .expand": {
      padding: "2px",
      //   border: "1px solid #c8c8c8",
      //   borderRadius: "10px",
      alignItems: "center",
      display: "flex",
      //   justifyContent: "center",
      borderTopRightRadius: "0",
      borderTopLeftRadius: "0",
      textAlign: "center",
      cursor: "pointer",
    },
  },
});
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(VideoBlock);
