import {
  FormControl,
  Menu,
  MenuItem,
  NativeSelect,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { getProgramChallengeParticipants } from "../../../../../apis/challengeService";
import {
  addActivityFeedback,
  downloadCSV,
  generateDeliverables,
  uploadEventCSVFile,
} from "../../../../../apis/eventService";
import ApplicationConstants from "../../../../../constants/ApplicationConstants";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import DialogBox from "../../../../common/DialogBox";
import ListPageLoader from "../../../../common/ListPageLoader";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import { IconCalendarBlue } from "../../../../common/SvgIcon";
import Snackbar from "./../../../../common/Snackbar";
import OrganizerTableList from "./../OrganizerTableList";
const styles = (theme) => ({
  importContainerCss: {
    margin: "10px",
  },
  mainBody: {
    // marginLeft: 80,
  },
  searchFilter__row: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    marginBottom: "20px",
    "& .search__select": {
      display: "flex",
      alignItems: "flex-end",
    },
    "& .search ": {
      display: "flex",
      alignItems: "center",
      border: "1px solid #bfc0c0",
      borderRadius: "5px",
      height: "35px",
      "& > input": {
        height: "30px",
        outline: "none",
        border: "none",
        fontSize: "16px",
        paddingLeft: "10px",
        borderRight: "2px solid #bfc0c0",
      },
      "& .search__icon": {
        padding: "5px",
        cursor: "pointer",
      },
    },
    "& .filters": {
      display: "flex",
      alignItems: "flex-end",
      fontFamily: "Rubik,sans-serif",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    fontFamily: "Rubik, sans-serif",
  },
  select: {
    marginRight: theme.spacing(1),
  },
  selectActivity: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",

    position: "relative",
    bottom: 20,
    marginRight: theme.spacing(8),
  },
  totalRec: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    fontWeight: 600,
    width: "15%",
    marginRight: "10px",
    background: "rgba( 149, 213, 178, 0.50 )",
    boxShadow: "0 4px 3px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    // position: "relative",
    // bottom: 20,
  },
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  customDate: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  actions: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  editIcon: {
    color: "#09456C",
    cursor: "pointer",
  },
  approvalForm: {
    // width: "800px",
    // height: "auto",
    padding: "8px 24px",
  },
});

class ProgramChallengeParticipantsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerLinkSelected: localStorage.getItem("headerLinkSelected"),
      eventDetailsData: null,
      participantsList: null,
      activityType: "",
      activityOptions: [],
      categoryValue: "",
      categoryOptions: [],
      runCategoryOptions: [],
      cycleCategoryOptions: [],
      duathlonCategoryOptions: [],
      stepsCategoryOptions: [],
      dateRangeOptions: [],
      totalRecords: "",
      dateRange: "",
      searchSelectValue: "Name",
      searchValue: "",
      modifyCategoryOptions: [],
      totalRecords: null,
      startDate: moment().subtract(7, "day").format("YYYY/MM/DD"),
      endDate: moment().format("YYYY/MM/DD"),
      deliverableOptions: ApplicationConstants.participantDeliverableTypes,
      deliverableType: "",
      checkboxArr: [],
      openFeedbackForm: false,
      comment: "",
      messageType: "GENERAL",
      openEventId: null,
    };
  }

  componentDidMount() {
    this.programChallengeParticipantList();
  }

  /**
   * @Vishal
   * fetching the Challenge participant list of specific program
   */
  programChallengeParticipantList = () => {
    let requestParams = {
      // programChallengeId: 2301,
      pageCount: 20,
      pageNumber: 0,
    };
    getProgramChallengeParticipants(requestParams).then((res) => {
      if (res?.data) {
        let _role = ApplicationUtil.getSupportDetails();
        this.setState({
          participantsList: res?.data?.programChallengeParticipantDTOs,
          totalRecords: res?.data?.totalChallengeParticipant,
          isSupport: _role?.role === "SUPPORT" ? true : false,
        });
      }
    });
    this.setState({ paramObj: requestParams });
  };

  dateHandleChange = (event) => {
    const {
      categoryValue,
      activityType,
      searchSelectValue,
      searchValue,
      startDate,
      endDate,
    } = this.state;
    this.setState({ dateRange: event.target.value });
    let name;
    let bib;
    let email;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    }

    let requestParams = {
      startDate:
        event.target.value === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : event.target.value,
      endDate:
        event.target.value === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("hh:mm:ss")}`,
      categoryId: categoryValue,
      name: name,
      bib: bib,
      email: email,
    };

    getProgramChallengeParticipants(requestParams).then((res) => {
      if (res?.data) {
        this.setState({
          participantsList: res?.data?.programChallengeParticipantDTOs,
          totalRecords: res?.data?.totalChallengeParticipant,
        });
      } else {
        this.setState({ participantsList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No participants are available for this category please select
              another category`,
        });
      }
    });

    this.setState({ paramObj: requestParams });
  };

  selectHandleValue = (event) => {
    this.setState({ searchSelectValue: event.target.value });
  };

  searchHandleClick = () => {
    const {
      searchSelectValue,
      searchValue,
      categoryValue,
      activityType,
      dateRange,
      startDate,
      endDate,
    } = this.state;

    let name;
    let bib;
    let email;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    }
    let requestParams = {
      name: name,
      bib: bib,
      email: email,
      startDate:
        dateRange === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("hh:mm:ss")}`,
      activityTypeId: activityType,
      categoryId: categoryValue,
    };
    let eventId = localStorage.getItem("openChallengeId");
    if (
      requestParams.name !== "" ||
      requestParams.email !== "" ||
      requestParams.bib !== ""
    ) {
      getProgramChallengeParticipants(requestParams).then((res) => {
        if (res?.data) {
          this.setState({
            participantsList: res?.data?.programChallengeParticipantDTOs,
            totalRecords: res?.data?.totalChallengeParticipant,
          });
        } else {
          this.setState({ participantsList: [] });
          Snackbar.show({
            variant: "info",
            isAutoHide: true,
            preventDuplicate: true,
            message: `No participants are available for this category please select
                another category`,
          });
        }
      });

      this.setState({ paramObj: requestParams });
    }
  };
  searchHandleChange = (event) => {
    this.setState({ searchValue: event.target.value });
  };
  getList = (isFirstPage, page) => {
    const {
      dateRange,
      activityType,
      searchSelectValue,
      searchValue,
      categoryValue,
      startDate,
      endDate,
    } = this.state;

    let name;
    let bib;
    let email;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    }
    let requestParams = {
      startDate:
        dateRange === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("hh:mm:ss")}`,
      activityTypeId: activityType,
      categoryId: categoryValue,
      name: name,
      bib: bib,
      email: email,
      pageNumber: page ? page : 0,
      pageCount: 20,
    };
    getProgramChallengeParticipants(requestParams).then((res) => {
      if (res?.data) {
        let newData = page
          ? this.state?.participantsList.concat(
              res?.data?.programChallengeParticipantDTOs
            )
          : res?.data?.programChallengeParticipantDTOs;

        this.setState({
          participantsList:
            newData.indexOf(undefined) > -1
              ? newData.filter((item) => item !== undefined)
              : newData,
          totalRecords: res?.data?.totalChallengeParticipant,
        });
      } else {
        this.setState({ participantsList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No participants are available for this category please select
              another category`,
        });
      }
    });

    this.setState({ paramObj: requestParams });
  };
  customStartDate = (date) => {
    this.setState({ startDate: date });
    const {
      categoryValue,
      activityType,
      searchSelectValue,
      searchValue,
      endDate,
    } = this.state;

    let name;
    let bib;
    let email;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    }

    let requestParams = {
      startDate: `${moment(date).format("YYYY-MM-DD")} ${moment().format(
        "hh:mm:ss"
      )}`,
      endDate: `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
        "hh:mm:ss"
      )}`,
      activityTypeId: activityType,
      categoryId: categoryValue,
      name: name,
      bib: bib,
      email: email,
    };
    getProgramChallengeParticipants(requestParams).then((res) => {
      if (res?.data) {
        this.setState({
          participantsList: res?.data?.programChallengeParticipantDTOs,
          totalRecords: res?.data?.totalChallengeParticipant,
        });
      } else {
        this.setState({ participantsList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No participants are available for this category please select
              another category`,
        });
      }
    });

    this.setState({ paramObj: requestParams });
  };
  customEndDate = (date) => {
    this.setState({ endDate: date });
    const {
      categoryValue,
      activityType,
      searchSelectValue,
      searchValue,
      startDate,
    } = this.state;

    let name;
    let bib;
    let email;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    }

    let requestParams = {
      startDate: `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
        "hh:mm:ss"
      )}`,
      endDate: `${moment(date).format("YYYY-MM-DD")} ${moment().format(
        "hh:mm:ss"
      )}`,
      activityTypeId: activityType,
      categoryId: categoryValue,
      name: name,
      bib: bib,
      email: email,
    };
    getProgramChallengeParticipants(requestParams).then((res) => {
      if (res?.data) {
        this.setState({
          participantsList: res?.data?.programChallengeParticipantDTOs,
          totalRecords: res?.data?.totalChallengeParticipant,
        });
      } else {
        this.setState({ participantsList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No participants are available for this category please select
              another category`,
        });
      }
    });

    this.setState({ paramObj: requestParams });
  };
  getDeliverable = (value) => {
    const { checkboxArr, isAllChecked } = this.state;
    let type = value;
    this.setState({ deliverableType: type });
    let arr = [];
    checkboxArr.map((check) => {
      if (check.check) {
        arr.push(check.id);
      }
    });
    if (arr.length > 0 || isAllChecked) {
      let requestParam = {
        runnerIds: isAllChecked ? "" : arr,
        regenerate: true,
      };

      generateDeliverables(type, requestParam);
    } else {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select users",
      });
    }
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  downloadCSVFile = () => {
    const { paramObj } = this.state;
    downloadCSV(paramObj);
  };

  handleImportClose = () => {
    this.setState({
      openImportDialog: false,
    });
  };

  handleImportFileUpload = () => {
    let file = this.state.importFile;
    let eventId = this.state.eventId;
    uploadEventCSVFile(file, eventId).then((res) => {
      this.setState({
        openImportDialog: false,
      });
    });
  };
  getFeedbackForm = () => {
    const {
      classes,
      // userMessages
    } = this.props;
    const { comment, messageType } = this.state;

    return (
      <div className={classes.approvalForm}>
        <div
          style={{
            fontWeight: 600,
          }}
        >
          {/* <div style={{ display: "flex", alignItems: "flex-start" }}> */}

          <div className={classes.messageType}>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.helpContainerCss}
            >
              <span className={classes.labelCss}>Message Type</span>
              {/* <span className={classes.mandatoryCss}>*</span> */}
            </Typography>
            <FormControl className={classes.select}>
              <NativeSelect
                className={classes.selectActivity}
                value={messageType}
                onChange={(e) => this.setState({ messageType: e.target.value })}
                style={{ width: 100 }}
              >
                {["Training", "General"]?.map((value, index) => (
                  <option value={value}>{value}</option>
                ))}
              </NativeSelect>
            </FormControl>
          </div>
          <div>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.helpContainerCss}
            >
              <span className={classes.labelCss}>Comment</span>
              <span className={classes.mandatoryCss}>*</span>
            </Typography>
            <TextareaAutosize
              value={comment}
              aria-label="feedback"
              minRows={5}
              // placeholder="Minimum 3 rows"
              style={{ width: 600 }}
              onChange={(e) => {
                this.setState({
                  comment: e.target.value,
                });
              }}
            />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
          >
            <SubmitCancelBtn
              style={{ width: "200px", marginLeft: "16px" }}
              type="submit"
              // startIcon={
              //   <AddCircle style={{ fontSize: "25px", color: "#fff" }} />
              // }
              label={"Add"}
              onClick={() => this.addComment()}
            />
          </div>
        </div>
      </div>
    );
  };
  addComment = () => {
    const {
      // activityId,
      comment,
      openEventId,
      // eventId,
      // runnerId,
      // activityDetails,
      // showGlobalComment,
    } = this.state;
    let eventId = ApplicationUtil.getSupportsEventID();
    let requestParams = {
      message: comment,
      // eventId: eventId,
      openEventId: openEventId,
      recipientType: "OPEN_EVENT",
      objectId: openEventId,
      // recipientId: runnerId,
    };
    // if (activityId) {
    //   requestParams.activityId = activityId;
    // }

    if (comment?.trim() === "" || comment === undefined || comment === null) {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please add a comment",
      });
    } else {
      addActivityFeedback(requestParams)
        .then((res) => {
          this.setState({
            openFeedbackForm: false,
            activityId: null,
            comment: null,
            // showGlobalComment: false,
          });
        })
        .catch((err) => {});
      ///api call
    }
  };

  render() {
    const { classes } = this.props;
    const {
      headerLinkSelected,
      isSupport,
      participantsList,
      eventDetailsData,
      categoryOptions,
      categoryValue,
      activityType,
      activityOptions,
      dateRange,
      dateRangeOptions,
      searchSelectValue,
      searchValue,
      totalRecords,
      modifyCategoryOptions,
      csvData,
      startDate,
      endDate,
      anchorEl,
      deliverableOptions,
      isSteps,
      openEventId,
    } = this.state;

    return (
      <>
        <div className={classes.mainBody}>
          <div className={classes.top}>
            {totalRecords !== null && (
              <div className={classes.totalRec}>
                <span>
                  Participants
                  <span style={{ marginLeft: "10px" }}>{totalRecords}</span>
                </span>
              </div>
            )}

            {/* <div>
              <SubmitCancelBtn
                label="Export"
                type="submit"
                onClick={this.downloadCSVFile}
              />
            </div> */}
          </div>
          <div className={classes.searchFilter__row}>
            <div className="search__select">
              <FormControl className={classes.select}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={searchSelectValue}
                  onChange={this.selectHandleValue}
                  style={{ width: 100 }}
                >
                  {["Name", "BIB", "Email"]?.map((value, index) => (
                    <option value={value}>{value}</option>
                  ))}
                </NativeSelect>
              </FormControl>
              <form
                className="search"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.searchHandleClick();
                }}
              >
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchValue}
                  onChange={this.searchHandleChange}
                />
                <SearchIcon
                  className="search__icon"
                  onClick={this.searchHandleClick}
                />
              </form>
            </div>
            <div className="filters">
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={dateRange}
                  onChange={this.dateHandleChange}
                  style={{ width: "auto" }}
                >
                  <option value="">All </option>
                  {dateRangeOptions?.map((value, index) => (
                    <option value={value.value}>{value.label}</option>
                  ))}
                </NativeSelect>
              </FormControl>
              {dateRange === "Custom Date" && (
                <FormControl className={classes.customDate}>
                  <Typography variant="body2" gutterBottom>
                    <span>Start Date</span>
                  </Typography>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    // disableFuture={true}
                    value={startDate}
                    //error={dobError}
                    style={{
                      width: "150px",
                    }}
                    maxDate={endDate}
                    InputProps={{
                      classes: {
                        root: classes.datePicker,
                      },
                    }}
                    onChange={(date) => {
                      this.customStartDate(date);
                    }}
                    keyboardIcon={
                      <IconCalendarBlue className={classes.calendarIconCss} />
                    }
                  />
                </FormControl>
              )}
              {dateRange === "Custom Date" && (
                <FormControl className={classes.customDate}>
                  <Typography variant="body2" gutterBottom>
                    <span>End Date</span>
                  </Typography>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    // disableFuture={true}
                    value={endDate}
                    //error={dobError}
                    style={{
                      width: "150px",
                    }}
                    InputProps={{
                      classes: {
                        root: classes.datePicker,
                      },
                    }}
                    minDate={startDate}
                    onChange={(date) => {
                      this.customEndDate(date);
                    }}
                    keyboardIcon={
                      <IconCalendarBlue className={classes.calendarIconCss} />
                    }
                  />
                </FormControl>
              )}
              {isSupport && (
                <FormControl className={classes.formControl}>
                  <div className={classes.actions}>
                    <span>Actions</span>
                    <span>
                      <ArrowDropDownCircleOutlinedIcon
                        className={classes.editIcon}
                        onClick={(event) => {
                          this.setState({
                            anchorEl: event.currentTarget,
                          });
                        }}
                      />
                    </span>
                  </div>
                </FormControl>
              )}
            </div>
          </div>
          <ListPageLoader
            pageParams={{
              page: 0,
              start: 0,
              limit: 8,
              TotalRecords: this.state.totalRecords,
            }}
            totalLoadedLength={participantsList ? participantsList.length : 20}
            loadNextData={(params) => {
              if (!participantsList.includes(undefined)) {
                this.getList(false, params.page);
              }
            }}
          >
            {participantsList?.length > 0 ? (
              <OrganizerTableList
                isSupport={isSupport}
                isProgramChallenge={true}
                eventData={eventDetailsData}
                data={participantsList}
                updateParticipantsList={(data, reload) => {
                  if (reload) {
                    this.programChallengeParticipantList();
                  } else {
                    this.setState({ participantsList: data });
                  }
                }}
                email
                regType
                editReg
                activityTypeOptions={activityOptions}
                categoryTypeOptions={modifyCategoryOptions}
                updateCheckboxArr={(arr, isAllChecked) => {
                  this.setState({
                    checkboxArr: arr,
                    isAllChecked: isAllChecked,
                  });
                  let array = [];
                  arr.map((check) => {
                    if (check.check) {
                      array.push(check.id);
                    }
                  });
                  if (array.length > 0) {
                    let requestParams = {
                      runnerIds: isAllChecked ? "" : array,
                    };
                    this.setState({ paramObj: requestParams });
                  }
                }}
              />
            ) : (
              <p>
                {totalRecords === null
                  ? "Loading participants..."
                  : totalRecords === 0
                  ? "No Results"
                  : ""}
              </p>
            )}
          </ListPageLoader>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            // className="header__user--menu"
            style={{ marginTop: "40px" }}
          >
            {deliverableOptions?.map((value, index) => (
              <MenuItem onClick={() => this.getDeliverable(value.value)}>
                {value.label}
              </MenuItem>
            ))}
            <MenuItem
              onClick={() => {
                this.setState({
                  openFeedbackForm: true,
                });
              }}
            >
              {"Send Message"}
            </MenuItem>
          </Menu>
        </div>
        <DialogBox
          fullWidth
          maxWidth={"md"}
          header={"Add Feedback"}
          open={this.state.openFeedbackForm}
          message={this.getFeedbackForm()}
          onClick={() => {
            this.setState({
              openFeedbackForm: false,
            });
          }}
          closeDisloag={() => {
            this.setState({
              openFeedbackForm: false,
            });
          }}
        />
      </>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ProgramChallengeParticipantsList);
