import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import {
  downloadCSVFormat,
  downloadResultCSV,
  downloadResultFormat,
  generateDeliverables,
  getOrganizerExecutionList,
  getParticipantsActivityList,
  uploadActivityCSVFile,
  uploadResultsCSVFile,
  getRunnerActivityList,
  getSubscribedServices,
} from "../../../../apis/eventService";
import garminLogo from "../../../views/dashboard/garmin_logo.png";
import stravaLogo from "../../../views/dashboard/strava_logo.png";
import fitbitLogo from "../../../views/dashboard/fitbit_logo.png";
import mapmyrunLogo from "../../../views/dashboard/mapmyrun_logo.png";
import googlefit from "../../../views/dashboard/google_fit.png";
import OrganizerTableList from "./OrganizerTableList";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { getEventDetails } from "./../../../../apis/eventService";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import {
  Typography,
  FormControl,
  NativeSelect,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Tooltip,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SearchIcon from "@material-ui/icons/Search";
import ListPageLoader from "./../../../common/ListPageLoader";
import { CSVLink, CSVDownload } from "react-csv";
import Papa from "papaparse";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { FilterSVGIcon, IconCalendarBlue } from "../../../common/SvgIcon";
import moment from "moment";
import {
  updateSupportSelectedEvent,
  updateActivitySetFilters,
  updateAtheleteSetFilters,
} from "../../../../redux/supportUser";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import ApplicationConstants from "../../../../constants/ApplicationConstants";
import Snackbar from "../../../common/Snackbar";
import DialogBox from "../../../common/DialogBox";
import Properties from "../../../../properties/Properties";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import { withRouter } from "react-router-dom";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import ActivityList from "./ActivityList";
import { connect } from "react-redux";
import FilterIcon from "../../../common/FilterIcon";
import { CheckBox, ThreeSixty, TrendingUpTwoTone } from "@material-ui/icons";
import DrawerFilter from "../../../common/DrawerFilter";
const styles = (theme) => ({
  importContainerCss: {
    margin: "10px",
  },
  checkBox: {
    "&.Mui-checked": {
      color: "#296dfa",
    },
  },
  mainBody: {
    marginTop: "0px",
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    fontFamily: "Rubik, sans-serif",
  },
  select: {
    marginRight: theme.spacing(1),
  },
  selectActivity: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  searchFilter__row: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    position: "relative",
    bottom: 20,
    "& .search__select": {
      display: "flex",
      alignItems: "flex-end",
    },
    "& .search ": {
      display: "flex",
      alignItems: "center",
      border: "1px solid #bfc0c0",
      borderRadius: "5px",
      height: "35px",
      "& > input": {
        height: "30px",
        outline: "none",
        border: "none",
        fontSize: "16px",
        paddingLeft: "10px",
        borderRight: "2px solid #bfc0c0",
      },
      "& .search__icon": {
        padding: "5px",
        cursor: "pointer",
      },
    },
    "& .filters": {
      display: "flex",
      alignItems: "flex-end",
      fontFamily: "Rubik,sans-serif",
    },
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",

    position: "relative",
    bottom: 20,
    marginRight: theme.spacing(8),
  },
  totalRec: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    fontWeight: 600,
    width: "15%",
    marginRight: "10px",
    background: "rgba( 149, 213, 178, 0.50 )",
    boxShadow: "0 4px 3px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    // position: "relative",
    // bottom: 20,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  editIcon: {
    color: "#09456C",
    cursor: "pointer",
  },
  noResult: {
    color: "gray",
    margin: "0 auto",
    fontSize: "25px",
    textAlign: "center",
  },
  drawer: {
    padding: theme.spacing(2),
    width: "250px",
    role: "presentation",
    overflowX: "scroll",
    height: "100vh",
    position: "relative",
  },
  accordionStyling: {
    margin: "0",
    padding: "0",
    width: "100%",
  },
  accordionSummaryStyling: {
    padding: "0",
    margin: "0px 0px 0px 5px",
    fontWeight: "bold",
  },
  filterLabelStyling: {
    margin: "0",
    padding: 0,
    display: "flex",
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
  filterStyling: {
    margin: "0 0 0 5px",
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // width:'100%'
  },
  filtericonStyling: {
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px 10px 15px 0px",
    color: "#09456C",
  },
  applyBtn: {
    position: "fixed",
    lineHeight: "45px",
    bottom: "3%",
    right: "2%",
    backgroundColor: "white",
    width: "200px",
  },
});

class OrganizerExecutionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSupport: null,
      participantsList: null,
      startDate: moment().subtract(7, "day").format("YYYY/MM/DD"),
      endDate: moment().format("YYYY/MM/DD"),
      validInvalid: null,
      approvedNotapproved: null,
      activityReceived: true,
      activityTypeDropdown: "All",
      supportActivityType: "All",
      categoryValue: "",
      activityType: "",
      activityOptions: [],
      dropdownOptions: [],
      dateRangeOptions: [],
      pageNumberLoad: null,
      selectedDateOption: null,
      selectedValidInvalidOption: null,
      selectedApprovedNotpprovedOption: null,
      activityValue: "",
      eventActivityType: null,
      certificateGenerated: null,
      activityCollageGenerated: null,
      videoGenerated: null,
      searchSelectValue: "Name",
      searchValue: "",
      totalRecords: null,
      deliverableOptions: ApplicationConstants.deliverableTypes,
      anchorEl: null,
      checkboxArr: [],
      matDataChecked: false,
      showActivityListView: false,
      showAtheleteListView: true,
      activityList: null,
      activityTotalRecords: null,
      canAllCertificateGenerated: null,
      canAllCollageGenerated: null,
      canAllVideoGenerated: null,
      Action: null,
      isDrawerOpen: false,
      executionListFilters: ApplicationConstants.executionListFilters,
      atheleteListFilter: ApplicationConstants.atheleteListFilters,
      isAttended: null,
      bibGenerated: null,
      consentListFilters: "",
    };
  }
  componentDidMount() {
    this.initialDataLoad();
    //Dates Range
    let dateRange = [];
    //Last 24 hours --
    let last24Hrs = {
      label: "Last 24 hours",
      value: `${moment().subtract(1, "day").format("YYYY-MM-DD")} ${moment()
        .subtract(1, "day")
        .format("hh:mm:ss")}`,
    };
    //Last 3 days --
    let last3days = {
      label: "Last 3 days",
      value: `${moment().subtract(3, "day").format("YYYY-MM-DD")} ${moment()
        .subtract(3, "day")
        .format("hh:mm:ss")}`,
    };
    //Last 7 days --
    let last7days = {
      label: "Last 7 days",
      value: `${moment().subtract(7, "day").format("YYYY-MM-DD")} ${moment()
        .subtract(7, "day")
        .format("hh:mm:ss")}`,
    };
    //Last month --
    let lastMonth = {
      label: "Last month",
      value: `${moment().subtract(1, "months").format("YYYY-MM-DD")} ${moment()
        .subtract(1, "months")
        .format("hh:mm:ss")}`,
    };
    let customDate = {
      label: "Custom Date",
      value: "Custom Date",
    };
    dateRange.push(last24Hrs, last3days, last7days, lastMonth, customDate);
    if (dateRange.length > 0) {
      this.setState({
        dateRangeOptions: dateRange,
        // dateRange: dateRange[0]
      });
    }
    let eventId = ApplicationUtil.getSupportsEventID();
    getRunnerActivityList(eventId)
      .then((res) => {
        if (res.data) {
          let getSupportedEventDetails =
            ApplicationUtil.getSupportsEventsDetails();
          let obj = {
            ...getSupportedEventDetails,
            activities: res.data,
          };
          this.props.updateSupportSelectedEvent(obj);
          let list = [];
          for (let i = 0; i < res.data.length; i++) {
            list.push({
              displayName: res.data[i].displayName,
              type: res.data[i].type,
            });
          }
          //executionListFilters
          // this.state.executionListFilters[2].items= list;

          this.setState({
            dropdownOptions: list,
          });
        }
      })
      .catch((error) => {
        this.setState({ activityTotalRecords: 0 });
      });
    let userId = sessionStorage.getItem("consentUserId");
    if (!userId) {
      let userData = ApplicationUtil.getRunnerDetails();
      userId = userData?.id;
    }

    getSubscribedServices().then((res) => {
      if (res.data && res.data.length > 0) {
        let d = res.data;
        let consentList = [];

        for (let i = 0; i < res.data.length; i++) {
          consentList.push({
            label: res.data[i].name,
          });
        }
        this.state.executionListFilters[2].items = consentList;
        this.state.atheleteListFilter[7].items = consentList;
        this.setState({
          consentListFilters: consentList,
          userId: userId,
        });
      }
    });
  }

  initialDataLoad = () => {
    const {
      approvedNotapproved,
      validInvalid,
      executionListFilters,
      canAllCertificateGenerated,
      canAllCollageGenerated,
      canAllVideoGenerated,
      atheleteListFilter,
      activityReceived,
      isAttended,
      certificateGenerated,
      eventActivityType,
      activityCollageGenerated,
      videoGenerated,
      bibGenerated,
    } = this.state;
    let runCatOptions = [];
    let cycleCatOptions = [];
    let duathlonOptions = [];
    let stepsOptions = [];
    let catOptionsArr = [];
    let actOptions = [];
    let isChallengeEvent = localStorage.getItem("isChallengeEvent");
    let isTraining = localStorage.getItem("isTraining");
    let getSupportedEventDetails = ApplicationUtil.getSupportsEventsDetails();
    atheleteListFilter[0].selectedValue = 'activityRecieve'

    let activityObj = {
      approvedNotapproved: approvedNotapproved,
      validInvalid: validInvalid,
    };
    let atheleteObj = {
      activityReceived: activityReceived,
      validInvalid: validInvalid,
      isAttended: isAttended,
      certificateGenerated: certificateGenerated,
      bibGenerated: bibGenerated,
      activityCollageGenerated: activityCollageGenerated,
      videoGenerated: videoGenerated,
    };

    let activityRequestParams = {
      pageNumber: 0,
      pageCount: 20,
      isValid:
        validInvalid !== null
          ? validInvalid
          : this.props.activityList1 &&
            (this.props.activityList1?.validInvalid !== null ||
              this.props.activityList1?.validInvalid !== undefined)
          ? this.props.activityList1?.validInvalid
          : null,
      approved:
        this.props.activityList1?.approvedNotapproved === true
          ? this.props.activityList1?.approvedNotapproved
          : this.props.activityList1?.approvedNotapproved === false
          ? this.props.activityList1?.approvedNotapproved
          : approvedNotapproved,
      activityTypeDropdown: null,
    };

    if (this.props.activityList1 !== null) {
      this.setState({
        approvedNotapproved: this.props.activityList1.approvedNotapproved,
        validInvalid: this.props.activityList1.validInvalid,
      });
    } else {
      this.props.updateActivitySetFilters(activityObj);
    }

    getParticipantsActivityList(activityRequestParams).then(({ data }) => {
      const list = data?.activityList;

      let formattedList = [];
      let currentDate = "";

      for (let i = 0; i < list?.length; i++) {
        list[i].isExpanded = false;
        let date = ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
          list[i].startDateLocal
        );
        if (currentDate !== date) {
          let obj = {
            id: list[i].id,
            date: list[i].startDateLocal,
            isGeneral: true,
          };
          let getSameDatesActivities = list.filter(
            (x) =>
              ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                x.startDateLocal
              ) === date
          );

          obj.activities = getSameDatesActivities;
          formattedList.push(obj);
        }
        currentDate = date;
      }

      this.setState({
        activityList: formattedList,
        // activityList:list,
        activityTotalRecords: data?.totalRecords,

        displayColumns: data?.displayColumns?.split(","),
      });
    });
    this.setState({
      showActivityListView: getSupportedEventDetails?.selectedList
        ? getSupportedEventDetails?.selectedList === "ACTIVITY"
          ? true
          : false
        : true,
      showAtheleteListView: getSupportedEventDetails?.selectedList
        ? getSupportedEventDetails?.selectedList === "ATHELETE"
          ? true
          : false
        : false,
      challengeType: getSupportedEventDetails?.challengeType,
      showActivityScreenshot: getSupportedEventDetails?.activities?.some(
        (x) => x?.allowActivityUpload === true
      ),
    });

    let requestParams = {
      pageCount: 20,
      pageNumber: 0,
      activityReceived:
        this.props.atheleteList1?.activityReceived === true
          ? this.props.atheleteList1?.activityReceived
          : this.props.atheleteList1?.activityReceived === false
          ? this.props.atheleteList1?.activityReceived
          : activityReceived,
      isAttended:
        this.props.atheleteList1?.isAttended === true
          ? this.props.atheleteList1?.isAttended
          : this.props.atheleteList1?.isAttended === false
          ? this.props.atheleteList1?.isAttended
          : isAttended,
      bibGenerated:
        this.props.atheleteList1?.bibGenerated === true
          ? this.props.atheleteList1?.bibGenerated
          : this.props.atheleteList1?.bibGenerated === false
          ? this.props.atheleteList1?.bibGenerated
          : bibGenerated,
      isValid:
        this.props.atheleteList1?.validInvalid === true
          ? this.props.atheleteList1?.validInvalid
          : this.props.atheleteList1?.validInvalid === false
          ? this.props.atheleteList1?.validInvalid
          : validInvalid,
      certificateGenerated:
        this.props.atheleteList1?.certificateGenerated === true
          ? this.props.atheleteList1?.certificateGenerated
          : this.props.atheleteList1?.certificateGenerated === false
          ? this.props.atheleteList1?.certificateGenerated
          : certificateGenerated,
      activityCollageGenerated:
        this.props.atheleteList1?.activityCollageGenerated === true
          ? this.props.atheleteList1?.activityCollageGenerated
          : this.props.atheleteList1?.activityCollageGenerated === false
          ? this.props.atheleteList1?.activityCollageGenerated
          : activityCollageGenerated,
      videoGenerated:
        this.props.atheleteList1?.videoGenerated === true
          ? this.props.atheleteList1?.videoGenerated
          : this.props.atheleteList1?.videoGenerated === false
          ? this.props.atheleteList1?.videoGenerated
          : videoGenerated,
    };

    if (
      getSupportedEventDetails?.selectedList &&
      getSupportedEventDetails?.selectedList === "ATHELETE"
    ) {
      if (this.props.atheleteList1 !== null) {
        this.setState({
          activityReceived: this.props.atheleteList1?.activityReceived,
          isAttended: this.props.atheleteList1?.isAttended,
          bibGenerated: this.props.atheleteList1?.bibGenerated,
          validInvalid: this.props.atheleteList1?.validInvalid,
          certificateGenerated: this.props.atheleteList1?.certificateGenerated,
          activityCollageGenerated:
            this.props.atheleteList1?.activityCollageGenerated,
          videoGenerated: this.props.atheleteList1?.videoGenerated,
        });
      } else {
        this.props.updateAtheleteSetFilters(atheleteObj);
      }

      getOrganizerExecutionList(requestParams)
        .then((res) => {
          if (res.data) {
            this.setState({
              participantsList: res.data.data,
              totalRecords: res.data.totalRecords,
              displayColumns: res?.data?.displayColumns.split(","),
              canAllCertificateGenerated: res?.data?.canAllCertificateGenerated,
              canAllCollageGenerated: res?.data?.canAllCollageGenerated,
              canAllVideoGenerated: res?.data?.canAllVideoGenerated,
              //activityReceived: res?.data?.activityReceived,
            });
          }
        })
        .catch((error) => {
          this.setState({ totalRecords: 0, paramObj: requestParams });
        });
    }
    // }
    let eventId = ApplicationUtil.getSupportsEventID();
    let requestparam = "REGISTER_EVENT";

    getEventDetails(requestparam, eventId).then((res) => {
      let data = ApplicationUtil.getSupportDetails();
      this.setState({
        eventActivityType: !res?.data?.allowMultipleActivityUpload,
        isSupport: data?.role === "SUPPORT" ? true : false,
      });

      if (res.data.eventRunCategories) {
        res.data.eventRunCategories.map(
          ({ activityType, eventSupportedActivityType, categoryName, id }) => {
            if (eventSupportedActivityType?.activityType.type === "RUN") {
              runCatOptions.push({
                label: categoryName,
                value: categoryName,
                id: id,
                type: eventSupportedActivityType.activityType.type,
                eventSupportedActivityTypeId: eventSupportedActivityType.id,
              });
            }
            if (eventSupportedActivityType?.activityType.type === "CYCLE") {
              cycleCatOptions.push({
                label: categoryName,
                value: categoryName,
                id: id,
                type: eventSupportedActivityType.activityType.type,
                eventSupportedActivityTypeId: eventSupportedActivityType.id,
              });
            }
            if (eventSupportedActivityType?.activityType.type === "DUATHLON") {
              duathlonOptions.push({
                label: categoryName,
                value: categoryName,
                id: id,
                type: eventSupportedActivityType.activityType.type,
                eventSupportedActivityTypeId: eventSupportedActivityType.id,
              });
            }
            if (eventSupportedActivityType?.activityType.type === "STEPS") {
              stepsOptions.push({
                label: categoryName,
                value: categoryName,
                id: id,
                type: eventSupportedActivityType.activityType.type,
                eventSupportedActivityTypeId: eventSupportedActivityType.id,
              });
            }
          }
        );

        if (runCatOptions.length > 0) {
          // const filteredArr = runCatOptions.reduce((acc, current) => {
          //   const x = acc.find((item) => item.type === current.type);
          //   if (!x) {
          //     return acc.concat([current]);
          //   } else {
          //     return acc;
          //   }
          // }, []);
          this.setState({
            runCategoryOptions: runCatOptions, //filteredArr,
            categoryOptions: runCatOptions, //filteredArr,
            // categoryValue: runCatOptions[0],
          });
        }
        if (cycleCatOptions.length > 0) {
          // const filteredArr = cycleCatOptions.reduce((acc, current) => {
          //   const x = acc.find((item) => item.type === current.type);
          //   if (!x) {
          //     return acc.concat([current]);
          //   } else {
          //     return acc;
          //   }
          // }, []);
          this.setState({
            cycleCategoryOptions: cycleCatOptions, //filteredArr,
          });
        }
        if (duathlonOptions.length > 0) {
          this.setState({
            duathlonCategoryOptions: duathlonOptions,
          });
        }
        if (stepsOptions.length > 0) {
          this.setState({
            stepsCategoryOptions: stepsOptions,
          });
        }
      }
      if (res.data?.activities) {
        res.data.activities.map(
          ({ displayName, type, id, eventSupportedActivityTypeId }) => {
            actOptions.push({
              label: displayName,
              value: displayName,
              type,
              id,
              eventSupportedActivityTypeId,
            });
          }
        );
        if (actOptions.length > 0) {
          let certActivity = {
            id: 8,
            label: "Certificate Available",
            eventSupportedActivityTypeId: 8,
          };
          let collageActivity = {
            eventSupportedActivityTypeId: 9,
            id: 9,
            label: "Collage Available",
          };
          let video = {
            eventSupportedActivityTypeId: 10,
            id: 10,
            label: "Video Available",
          };
          actOptions.push(certActivity, collageActivity, video);
          this.setState({
            activityOptions: actOptions,
            // activityType: actOptions[0],
          });
        }
      }
      if (!res.data?.activities && res.data?.eventRunCategories) {
        res.data.eventRunCategories.map(({ eventSupportedActivityType }) => {
          actOptions.push({
            label: eventSupportedActivityType?.activityType?.displayName,
            value: eventSupportedActivityType?.activityType?.displayName,
            type: eventSupportedActivityType?.activityType?.type,
            id: eventSupportedActivityType?.activityType?.id,
            eventSupportedActivityTypeId: eventSupportedActivityType?.id,
          });
        });

        let certActivity = {
          eventSupportedActivityTypeId: 8,
          id: 8,
          label: "Certificate Available",
        };
        let collageActivity = {
          eventSupportedActivityTypeId: 9,
          id: 9,
          label: "Collage Available",
        };
        let video = {
          eventSupportedActivityTypeId: 10,
          id: 10,
          label: "Video Available",
        };

        actOptions.push(certActivity, collageActivity, video);
      }

      if (actOptions.length > 0) {
        const filteredArr = actOptions.reduce((acc, current) => {
          const x = acc.find(
            (item) =>
              item.eventSupportedActivityTypeId ===
              current.eventSupportedActivityTypeId
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        this.setState({
          activityOptions: filteredArr,
          // activityType: actOptions[0],
        });
      }
    });
  };
  getActivityId = (value) => {
    const { activityOptions, activityType } = this.state;
    let type = activityOptions.find(
      (x) => x.eventSupportedActivityTypeId === value
    );

    let id;
    if (type) {
      id = Number(type.id);
      return id;
    } else {
      return "";
    }
  };

  selectHandleValue = (event) => {
    this.setState({ searchSelectValue: event.target.value });
  };
  searchHandleChange = (event) => {
    this.setState({ searchValue: event.target.value, resetPagination: true });
    if (!event.target.value) {
      this.initialDataLoad();
    }
  };
 
  searchHandleClick = () => {
    const {
      searchSelectValue,
      searchValue,
      validInvalid,
      approvedNotapproved,
      activityReceived,
      certificateGenerated,
      videoGenerated,
      isAttended,
      activityCollageGenerated,
      bibGenerated,
      activityType,
      categoryValue,
      showActivityListView,
      startDate,
      endDate,
    } = this.state;

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let activityId =
      activityType === 8
        ? ""
        : activityType === 9
        ? ""
        : activityType === 10
        ? ""
        : activityType;
    let requestParams = {
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      activityReceived: activityReceived,
      isAttended: isAttended,
      bibGenerated: bibGenerated,
      isValid: validInvalid,
      certificateGenerated: certificateGenerated,
      activityCollageGenerated: activityCollageGenerated,
      videoGenerated: videoGenerated,
      eventActivityTypeId: activityId,
      activityTypeId: this.getActivityId(activityId),
      categoryId: categoryValue,
      pageNumber: 0,
      pageCount: 20,
    };

    let activityRequestParams = {
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      isValid: validInvalid,
      approved: approvedNotapproved,
      pageNumber: 0,
      pageCount: 20,
    };

    if (
      requestParams.name !== "" ||
      requestParams.email !== "" ||
      requestParams.bib !== "" ||
      requestParams.contact !== ""
    ) {
      if (showActivityListView) {
        getParticipantsActivityList(activityRequestParams).then(({ data }) => {
          const list = data?.activityList;
          let formattedList = [];
          let currentDate = "";
          for (let i = 0; i < list?.length; i++) {
            list[i].isExpanded = false;
            let date = ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
              list[i].startDateLocal
            );
            if (currentDate !== date) {
              let obj = {
                id: list[i].id,
                date: list[i].startDateLocal,
                isGeneral: true,
              };
              let getSameDatesActivities = list.filter(
                (x) =>
                  ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                    x.startDateLocal
                  ) == date
              );
              obj.activities = getSameDatesActivities;
              formattedList.push(obj);
            }
            currentDate = date;
          }

          this.setState({
            activityList: formattedList,
            activityTotalRecords: data?.totalRecords,
            resetPagination: true,
          });
        });
      } else {
        getOrganizerExecutionList(requestParams).then((res) => {
          if (res.data) {
            // let newData = page
            //   ? this.state?.participantsList?.concat(res?.data?.data)
            //   : res.data.data;
            this.setState({
              participantsList: res?.data?.data,
              totalRecords: res.data.totalRecords,
              canAllCertificateGenerated: res?.data?.canAllCertificateGenerated,
              canAllCollageGenerated: res?.data?.canAllCollageGenerated,
              canAllVideoGenerated: res?.data?.canAllVideoGenerated,
              eventActivityType: res?.data?.allowMultipleActivityUpload,
              displayColumns: res?.data?.displayColumns?.split(","),
              // activityReceived:res?.data?.activityReceived,
            });
          }
        });
        this.setState({ paramObj: requestParams, resetPagination: true });
      }
    }
  };

  activityReceivedHandleChange = (event, page) => {
    const {
      activityType,
      validInvalid,
      searchSelectValue,
      searchValue,
      categoryValue,
      videoGenerated,
      isAttended,
      activityCollageGenerated,
      bibGenerated,
    } = this.state;
    // let val = event.target.value;

    let activityReceived =
      this.state.atheleteListFilter[0].selectedValue === "activityRecieve"
        ? true
        : this.state.atheleteListFilter[0].selectedValue ===
          "activityNotRecieve"
        ? false
        : null;

    let certificateGenerated =
      this.state.atheleteListFilter[2].selectedValue === "certificateGenerate"
        ? true
        : this.state.atheleteListFilter[2].selectedValue ===
          "certificateNotGenerate"
        ? false
        : null;
    this.setState({
      activityReceived: activityReceived,
      certificateGenerated: certificateGenerated,
    });
    this.setState({ pageNumberLoad: 0 });
    let activityId =
      activityType === 8
        ? ""
        : activityType === 9
        ? ""
        : activityType === 10
        ? ""
        : activityType;
    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let requestParams = {
      eventActivityTypeId: activityId,
      activityTypeId: this.getActivityId(activityId),
      categoryId: categoryValue,
      activityReceived: activityReceived,
      isAttended: isAttended,
      bibGenerated: bibGenerated,
      isValid: validInvalid,
      certificateGenerated: certificateGenerated,
      activityCollageGenerated: activityCollageGenerated,
      videoGenerated: videoGenerated,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      categoryId: categoryValue,
      pageNumber: page ? page : 0,
      pageCount: 20,
    };

    getOrganizerExecutionList(requestParams).then((res) => {
      if (res.data) {
        this.setState({
          participantsList: res.data.data,
          totalRecords: res.data.totalRecords,
          //  activityReceived:res?.data?.activityReceived,
          canAllCertificateGenerated: res?.data?.canAllCertificateGenerated,
          canAllCollageGenerated: res?.data?.canAllCollageGenerated,
          canAllVideoGenerated: res?.data?.canAllVideoGenerated,
        });
      }
    });

    this.setState({ paramObj: requestParams });
  };

  attendedHandleChanges = (event, page) => {
    const {
      activityType,
      validInvalid,
      searchSelectValue,
      searchValue,
      categoryValue,
      activityReceived,
      isAttended,
      certificateGenerated,
      videoGenerated,
      activityCollageGenerated,
      bibGenerated,
    } = this.state;

    this.setState({ pageNumberLoad: 0 });

    let activityId =
      activityType === 8
        ? ""
        : activityType === 9
        ? ""
        : activityType === 10
        ? ""
        : activityType;

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let requestParams = {
      eventActivityTypeId: activityId,
      activityTypeId: this.getActivityId(activityId),
      categoryId: categoryValue,

      name: name,
      bib: bib,
      email: email,
      contact: contact,
      categoryId: categoryValue,
      pageNumber: page ? page : 0,
      pageCount: 20,
      activityReceived: activityReceived,
      isAttended: isAttended,
      bibGenerated: bibGenerated,
      isValid: validInvalid,
      certificateGenerated: certificateGenerated,
      activityCollageGenerated: activityCollageGenerated,
      videoGenerated: videoGenerated,
    };

    getOrganizerExecutionList(requestParams).then((res) => {
      if (res.data) {
        this.setState({
          participantsList: res?.data?.data,
          // activityReceived:res?.data?.activityReceived,
          totalRecords: res.data.totalRecords,
          canAllCertificateGenerated: res?.data?.canAllCertificateGenerated,
          canAllCollageGenerated: res?.data?.canAllCollageGenerated,
          canAllVideoGenerated: res?.data?.canAllVideoGenerated,
          displayColumns: res?.data?.displayColumns.split(","),
        });
      }
    });

    this.setState({ paramObj: requestParams });
  };
  bibGeneratedHandleChange = (event, page) => {
    const {
      activityType,
      validInvalid,
      approvedNotapproved,
      searchSelectValue,
      searchValue,
      categoryValue,
      activityReceived,
      isAttended,
      bibGenerated,
      certificateGenerated,
      videoGenerated,
      activityCollageGenerated,
    } = this.state;

    this.setState({ pageNumberLoad: 0 });

    let activityId =
      activityType === 8
        ? ""
        : activityType === 9
        ? ""
        : activityType === 10
        ? ""
        : activityType;

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let requestParams = {
      eventActivityTypeId: activityId,
      activityTypeId: this.getActivityId(activityId),
      categoryId: categoryValue,

      name: name,
      bib: bib,
      email: email,
      contact: contact,
      categoryId: categoryValue,
      pageNumber: page ? page : 0,
      pageCount: 20,
      activityReceived: activityReceived,
      isAttended: isAttended,
      bibGenerated: bibGenerated,
      isValid: validInvalid,
      certificateGenerated: certificateGenerated,
      activityCollageGenerated: activityCollageGenerated,
      videoGenerated: videoGenerated,
    };

    getOrganizerExecutionList(requestParams).then((res) => {
      if (res.data) {
        this.setState({
          participantsList: res?.data?.data,
          totalRecords: res.data.totalRecords,
          canAllCertificateGenerated: res?.data?.canAllCertificateGenerated,
          canAllCollageGenerated: res?.data?.canAllCollageGenerated,
          canAllVideoGenerated: res?.data?.canAllVideoGenerated,
          // activityReceived:res?.data?.activityReceived,
        });
      }
    });

    this.setState({ paramObj: requestParams });
  };
  certificateGeneratedHandleChange = (event, page) => {
    const {
      activityType,
      validInvalid,
      approvedNotapproved,
      certificateGenerated,
      searchSelectValue,
      searchValue,
      categoryValue,
      activityReceived,
      isAttended,
      bibGenerated,

      videoGenerated,
      activityCollageGenerated,
    } = this.state;

    this.setState({ pageNumberLoad: 0 });

    // }
    let activityId =
      activityType === 8
        ? ""
        : activityType === 9
        ? ""
        : activityType === 10
        ? ""
        : activityType;

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let requestParams = {
      eventActivityTypeId: activityId,
      activityTypeId: this.getActivityId(activityId),
      categoryId: categoryValue,

      name: name,
      bib: bib,
      email: email,
      contact: contact,
      categoryId: categoryValue,
      pageNumber: page ? page : 0,
      pageCount: 20,
      activityReceived: activityReceived,
      isAttended: isAttended,
      bibGenerated: bibGenerated,
      isValid: validInvalid,
      certificateGenerated: certificateGenerated,
      activityCollageGenerated: activityCollageGenerated,
      videoGenerated: videoGenerated,
    };

    getOrganizerExecutionList(requestParams)
      .then((res) => {
        if (res.data) {
          this.setState({
            participantsList: res?.data?.data,
            totalRecords: res.data.totalRecords,
            // activityReceived:res?.data?.activityReceived,
            // activityReceived:true
            canAllCertificateGenerated: res?.data?.canAllCertificateGenerated,
            canAllVideoGenerated: res?.data?.canAllVideoGenerated,
            canAllCollageGenerated: res?.data?.canAllCollageGenerated,
          });
        }
      })
      .catch((error) => {
        this.setState({ totalRecords: 0 });
      });
    this.setState({ paramObj: requestParams });
  };
  collageGeneratedHandleChange = (event, page) => {
    const {
      activityType,
      validInvalid,
      approvedNotapproved,
      certificateGenerated,
      activityCollageGenerated,
      searchSelectValue,
      searchValue,
      categoryValue,
      activityReceived,
      isAttended,
      bibGenerated,
      videoGenerated,
    } = this.state;

    this.setState({ pageNumberLoad: 0 });

    // }
    let activityId =
      activityType === 8
        ? ""
        : activityType === 9
        ? ""
        : activityType === 10
        ? ""
        : activityType;

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let requestParams = {
      eventActivityTypeId: activityId,
      activityTypeId: this.getActivityId(activityId),
      categoryId: categoryValue,

      name: name,
      bib: bib,
      email: email,
      contact: contact,
      categoryId: categoryValue,
      pageNumber: page ? page : 0,
      pageCount: 20,
      activityReceived: activityReceived,
      isAttended: isAttended,
      bibGenerated: bibGenerated,
      isValid: validInvalid,
      certificateGenerated: certificateGenerated,
      activityCollageGenerated: activityCollageGenerated,
      videoGenerated: videoGenerated,
    };

    getOrganizerExecutionList(requestParams)
      .then((res) => {
        if (res.data) {
          this.setState({
            participantsList: res?.data?.data,
            totalRecords: res.data.totalRecords,
            canAllCertificateGenerated: res?.data?.canAllCertificateGenerated,
            canAllCollageGenerated: res?.data?.canAllCollageGenerated,
            canAllVideoGenerated: res?.data?.canAllVideoGenerated,
            // activityReceived:res?.data?.activityReceived,
          });
        }
      })
      .catch((error) => {
        this.setState({ totalRecords: 0 });
      });
    this.setState({ paramObj: requestParams });
  };
  videoGeneratedHandleChange = (event, page) => {
    const {
      activityType,
      validInvalid,
      approvedNotapproved,
      certificateGenerated,
      activityCollageGenerated,
      videoGenerated,
      searchSelectValue,
      searchValue,
      categoryValue,
      activityReceived,
      isAttended,
      bibGenerated,
    } = this.state;

    this.setState({ pageNumberLoad: 0 });

    let activityId =
      activityType === 8
        ? ""
        : activityType === 9
        ? ""
        : activityType === 10
        ? ""
        : activityType;

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let requestParams = {
      eventActivityTypeId: activityId,
      activityTypeId: this.getActivityId(activityId),
      categoryId: categoryValue,

      name: name,
      bib: bib,
      email: email,
      contact: contact,
      categoryId: categoryValue,
      pageNumber: page ? page : 0,
      pageCount: 20,
      activityReceived: activityReceived,
      isAttended: isAttended,
      bibGenerated: bibGenerated,
      isValid: validInvalid,
      certificateGenerated: certificateGenerated,
      activityCollageGenerated: activityCollageGenerated,
      videoGenerated: videoGenerated,
    };

    getOrganizerExecutionList(requestParams)
      .then((res) => {
        if (res.data) {
          this.setState({
            participantsList: res?.data?.data,
            totalRecords: res.data.totalRecords,
            canAllCertificateGenerated: res?.data?.canAllCertificateGenerated,
            canAllCollageGenerated: res?.data?.canAllCollageGenerated,
            canAllVideoGenerated: res?.data?.canAllVideoGenerated,
            // activityReceived:res?.data?.activityReceived,
          });
        }
      })
      .catch((error) => {
        this.setState({ totalRecords: 0 });
      });
    this.setState({ paramObj: requestParams });
  };
  //custom start date function
  customStartDate = (date) => {
    this.setState({ startDate: date, pageNumberLoad: 0 });

    const {
      categoryValue,
      activityType,
      searchSelectValue,
      searchValue,
      endDate,
      selectedValidInvalidOption,

      validInvalid,
      approvedNotapproved,
      activityTypeDropdown,
      dropdownOptions,
      supportActivityType,
    } = this.state;

    let dropdownOption;
    dropdownOptions.map((options) => {
      if (supportActivityType === options.displayName) {
        dropdownOption = options.type;
      }
    });

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestParams = {
      startDate: `${moment(date).format("YYYY-MM-DD")} ${moment().format(
        "hh:mm:ss"
      )}`,
      endDate: `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
        "hh:mm:ss"
      )}`,

      name: name,
      bib: bib,
      email: email,
      contact: contact,
      pageNumber: 0,
      pageCount: 20,

      isValid: validInvalid,
      approved: approvedNotapproved,
      activityTypeDropdown: dropdownOption,
    };

    getParticipantsActivityList(requestParams).then(({ data }) => {
      const list = data?.activityList;
      let formattedList = [];
      let currentDate = "";
      for (let i = 0; i < list?.length; i++) {
        list[i].isExpanded = false;
        let date = ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
          list[i].startDateLocal
        );
        if (currentDate !== date) {
          let obj = {
            id: list[i].id,
            date: list[i].startDateLocal,
            isGeneral: true,
          };
          let getSameDatesActivities = list.filter(
            (x) =>
              ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                x.startDateLocal
              ) == date
          );
          obj.activities = getSameDatesActivities;
          formattedList.push(obj);
        }
        currentDate = date;
      }
      if (formattedList) {
        this.setState({
          activityList: formattedList,
          activityTotalRecords: data.totalRecords,
        });
      } else {
        this.setState({ activityList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No activites are available for this category please select
            another category`,
        });
      }
    });
  };

  //custom end date function
  customEndDate = (date) => {
    this.setState({ endDate: date, pageNumberLoad: 0 });
    const {
      categoryValue,
      activityType,
      searchSelectValue,
      searchValue,
      startDate,
      validInvalid,
      approvedNotapproved,
      dropdownOptions,
      supportActivityType,
    } = this.state;

    let dropdownOption;
    dropdownOptions.map((options) => {
      if (supportActivityType === options.displayName) {
        dropdownOption = options.type;
      }
    });

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestParams = {
      startDate: `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
        "hh:mm:ss"
      )}`,
      endDate: `${moment(date).format("YYYY-MM-DD")} ${moment().format(
        "hh:mm:ss"
      )}`,
      // eventActivityTypeId: activityType,
      // activityTypeId: this.getActivityId(activityType),
      // categoryId: categoryValue,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      pageNumber: 0,
      pageCount: 20,
      isValid: validInvalid,
      approved: approvedNotapproved,
      activityTypeDropdown: dropdownOption,
    };

    getParticipantsActivityList(requestParams).then(({ data }) => {
      const list = data?.activityList;
      let formattedList = [];
      let currentDate = "";
      for (let i = 0; i < list?.length; i++) {
        list[i].isExpanded = false;
        let date = ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
          list[i].startDateLocal
        );
        if (currentDate !== date) {
          let obj = {
            id: list[i].id,
            date: list[i].startDateLocal,
            isGeneral: true,
          };
          let getSameDatesActivities = list.filter(
            (x) =>
              ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                x.startDateLocal
              ) == date
          );
          obj.activities = getSameDatesActivities;
          formattedList.push(obj);
        }
        currentDate = date;
      }
      if (formattedList) {
        this.setState({
          activityList: formattedList,
          activityTotalRecords: data.totalRecords,
        });
      } else {
        this.setState({ activityList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No activites are available for this category please select
            another category`,
        });
      }
    });
  };

  dateHandleChange = (eventDate) => {
    this.setState({ pageNumberLoad: 0 });
    const {
      searchSelectValue,
      searchValue,
      startDate,
      endDate,
      approvedNotapproved,
      validInvalid,
      activityTypeDropdown,
      dateRange,
      dropdownOptions,

      supportActivityType,
    } = this.state;
    this.setState({
      dateRange: eventDate.target.value,
      selectedDateOption: eventDate.target.value,
    });

    let dropdownOption;
    dropdownOptions.map((options) => {
      if (supportActivityType === options.displayName) {
        dropdownOption = options.type;
      }
    });

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestParams = {
      startDate:
        eventDate.target.value === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : eventDate.target.value,
      endDate:
        eventDate.target.value === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("hh:mm:ss")}`,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      pageNumber: 0,
      pageCount: 20,
      approved: approvedNotapproved,
      isValid: validInvalid,
      activityTypeDropdown: dropdownOption,
    };

    // this.setState({
    //   startDate: eventDate.target.value,
    //   endDate: `${moment().format("YYYY-MM-DD")} ${moment().format(
    //     "hh:mm:ss"
    //   )}`,
    // });

    getParticipantsActivityList(requestParams).then(({ data }) => {
      const list = data?.activityList;
      let formattedList = [];
      let currentDate = "";
      for (let i = 0; i < list?.length; i++) {
        list[i].isExpanded = false;
        let date = ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
          list[i].startDateLocal
        );
        if (currentDate !== date) {
          let obj = {
            id: list[i].id,
            date: list[i].startDateLocal,
            isGeneral: true,
          };
          let getSameDatesActivities = list.filter(
            (x) =>
              ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                x.startDateLocal
              ) == date
          );
          obj.activities = getSameDatesActivities;
          formattedList.push(obj);
        }
        currentDate = date;
      }
      if (formattedList) {
        this.setState({
          activityList: formattedList,
          activityTotalRecords: data.totalRecords,
        });
      } else {
        this.setState({ activityList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No activites are available for this category please select
            another category`,
        });
      }
    });
  };

  // activity type handler function
  activityTypeHandleChange = (event) => {
    this.setState({ pageNumberLoad: 0 });
    const {
      activityType,
      searchSelectValue,
      searchValue,
      startDate,
      endDate,
      approvedNotapproved,
      validInvalid,
      activityTypeDropdown,
      dropdownOptions,
      supportActivityType,
      dateRange,
    } = this.state;

    let val = event.target.value;
    this.setState({ supportActivityType: val });
    let dropdownOption;
    dropdownOptions.map((options) => {
      if (val === options.displayName) {
        dropdownOption = options.type;
      }
    });

    this.setState({ activityTypeDropdown: val });

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let params = {
      startDate:
        dateRange === "Custom Date" //this.state.selectedDateOption
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date" //this.state.selectedDateOption
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("hh:mm:ss")}`,
      activityTypeDropdown: dropdownOption,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      pageNumber: 0,
      pageCount: 20,
      isValid: validInvalid,
      approved: approvedNotapproved,
    };

    getParticipantsActivityList(params)
      .then(({ data }) => {
        const list = data?.activityList;
        let formattedList = [];
        let currentDate = "";
        for (let i = 0; i < list?.length; i++) {
          list[i].isExpanded = false;
          let date = ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
            list[i].startDateLocal
          );
          if (currentDate !== date) {
            let obj = {
              id: list[i].id,
              date: list[i].startDateLocal,
              isGeneral: true,
            };
            let getSameDatesActivities = list.filter(
              (x) =>
                ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                  x.startDateLocal
                ) == date
            );
            obj.activities = getSameDatesActivities;
            formattedList.push(obj);
          }
          currentDate = date;
        }
        if (formattedList) {
          this.setState({
            activityList: formattedList,
            activityTotalRecords: data.totalRecords,
          });
        } else {
          this.setState({ activityList: [] });
          Snackbar.show({
            variant: "info",
            isAutoHide: true,
            preventDuplicate: true,
            message: `No activities are available for this category please select
          another category`,
          });
        }
      })
      .catch((error) => {
        this.setState({ activityTotalRecords: 0 });
      });
  };

  validHandleChange = (event, page) => {
    this.setState({ pageNumberLoad: 0 });

    const {
      activityType,
      activityReceived,
      certificateGenerated,
      activityCollageGenerated,
      videoGenerated,
      isAttended,
      bibGenerated,
      searchSelectValue,
      searchValue,
      categoryValue,
      showActivityListView,
      showAtheleteListView,
      startDate,
      endDate,
      approvedNotapproved,
      validInvalid,
      activityTypeDropdown,
      dateRange,
      supportActivityType,
      dropdownOptions,
    } = this.state;

    // let val = event.target.value;
    // this.setState({ validInvalid: event.target.value });
    // let valid;

    let dropdownOption;
    dropdownOptions.map((options) => {
      if (supportActivityType === options.displayName) {
        dropdownOption = options.type;
      }
    });

    let activityId =
      activityType === 8
        ? ""
        : activityType === 9
        ? ""
        : activityType === 10
        ? ""
        : activityType;

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let requestParams = {
      eventActivityTypeId: activityId,
      activityTypeId: this.getActivityId(activityId),
      categoryId: categoryValue,

      name: name,
      bib: bib,
      email: email,
      contact: contact,
      categoryId: categoryValue,
      pageNumber: page ? page : 0,
      pageCount: 20,
      activityReceived: activityReceived,
      isAttended: isAttended,
      bibGenerated: bibGenerated,
      isValid: validInvalid,
      certificateGenerated: certificateGenerated,
      activityCollageGenerated: activityCollageGenerated,
      videoGenerated: videoGenerated,
    };

    {
      showAtheleteListView &&
        getOrganizerExecutionList(requestParams)
          .then((res) => {
            if (res.data) {
              this.setState({
                participantsList: res?.data?.data,
                totalRecords: res?.data?.totalRecords,
                // activityReceived:res?.data?.activityReceived,
              });
            }
          })
          .catch((error) => {
            this.setState({ totalRecords: 0 });
          });
      this.setState({ paramObj: requestParams });
    }

    let Params = {
      startDate:
        dateRange === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("hh:mm:ss")}`,

      name: name,
      bib: bib,
      email: email,
      contact: contact,
      pageNumber: 0,
      pageCount: 20,
      approved: approvedNotapproved,
      isValid: validInvalid,
      activityTypeDropdown: dropdownOption,
    };

    {
      showActivityListView &&
        getParticipantsActivityList(Params)
          .then(({ data }) => {
            const list = data?.activityList;
            let formattedList = [];
            let currentDate = "";
            for (let i = 0; i < list?.length; i++) {
              list[i].isExpanded = false;
              let date = ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                list[i].startDateLocal
              );
              if (currentDate !== date) {
                let obj = {
                  id: list[i].id,
                  date: list[i].startDateLocal,
                  isGeneral: true,
                };
                let getSameDatesActivities = list.filter(
                  (x) =>
                    ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                      x.startDateLocal
                    ) == date
                );
                obj.activities = getSameDatesActivities;
                formattedList.push(obj);
              }
              currentDate = date;
            }
            if (formattedList) {
              this.setState({
                activityList: formattedList,
                activityTotalRecords: data?.totalRecords,
              });
            } else {
              this.setState({ activityList: [] });
              Snackbar.show({
                variant: "info",
                isAutoHide: true,
                preventDuplicate: true,
                message: `No activities are available for this category please select
            another category`,
              });
            }
          })
          .catch((error) => {
            this.setState({ activityTotalRecords: 0 });
          });
    }
  };

  //approved not approved
  approvedHandleChange = (value) => {
    this.setState({ pageNumberLoad: 0 });
    const {
      activityType,
      searchSelectValue,
      searchValue,
      validInvalid,
      startDate,
      endDate,
      approvedNotapproved,
      activityTypeDropdown,
      dateRange,
      supportActivityType,
      dropdownOptions,
    } = this.state;

    // let val = event.target.value;
    // this.setState({ approvedNotapproved: event.target.value });

    let dropdownOption;
    dropdownOptions.map((options) => {
      if (supportActivityType === options.displayName) {
        dropdownOption = options.type;
      }
    });

    let selectedValue = value?.map((item) => item.selectedValue);

    let approved;
    if (selectedValue?.includes("approve")) {
      approved = true;
    } else if (selectedValue?.includes("notApprove")) {
      approved = false;
    } else {
      approved = null;
    }

    let valid;
    if (selectedValue?.includes("valid")) {
      valid = true;
    } else if (selectedValue?.includes("notValid")) {
      valid = false;
    } else {
      valid = null;
    }
    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestApprovedParams = {
      startDate:
        dateRange === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("hh:mm:ss")}`,

      // activityTypeId: this.getActivityId(activityId),
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      pageNumber: 0,
      pageCount: 20,
      validStatus: valid,
      approvedStatus: approved,
      isValid: validInvalid,
      approved: approvedNotapproved,
      activityTypeDropdown: dropdownOption,
    };

    getParticipantsActivityList(requestApprovedParams)
      .then(({ data }) => {
        const list = data?.activityList;
        let formattedList = [];
        let currentDate = "";
        for (let i = 0; i < list?.length; i++) {
          list[i].isExpanded = false;
          let date = ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
            list[i].startDateLocal
          );
          if (currentDate !== date) {
            let obj = {
              id: list[i].id,
              date: list[i].startDateLocal,
              isGeneral: true,
            };
            let getSameDatesActivities = list.filter(
              (x) =>
                ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                  x.startDateLocal
                ) == date
            );
            obj.activities = getSameDatesActivities;
            formattedList.push(obj);
          }
          currentDate = date;
        }
        if (formattedList) {
          this.setState({
            activityList: formattedList,
            activityTotalRecords: data?.totalRecords,
          });
        } else {
          this.setState({ activityList: [] });
          Snackbar.show({
            variant: "info",
            isAutoHide: true,
            preventDuplicate: true,
            message: `No activities are available for this category please select
          another category`,
          });
        }
      })
      .catch((error) => {
        this.setState({ activityTotalRecords: 0 });
      });
  };

  getList = (valueee, isFirstPage, page) => {
    const {
      activityType,
      activityReceived,
      certificateGenerated,
      activityCollageGenerated,
      videoGenerated,
      isAttended,
      bibGenerated,
      searchSelectValue,
      searchValue,
      validInvalid,
      // approvedNotapproved,
      categoryValue,
    } = this.state;
    let activityId =
      activityType === 8
        ? ""
        : activityType === 9
        ? ""
        : activityType === 10
        ? ""
        : activityType;

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestParams = {
      eventActivityTypeId: activityId,
      activityTypeId: this.getActivityId(activityId),
      categoryId: categoryValue,

      name: name,
      bib: bib,
      email: email,
      contact: contact,
      categoryId: categoryValue,
      pageNumber: page ? page : 0,
      pageCount: 20,
      activityReceived: activityReceived,
      isAttended: isAttended,
      bibGenerated: bibGenerated,
      isValid: validInvalid,
      certificateGenerated: certificateGenerated,
      activityCollageGenerated: activityCollageGenerated,
      videoGenerated: videoGenerated,
    };

    getOrganizerExecutionList(requestParams)
      .then((res) => {
        if (res.data) {
          let newData = page
            ? this.state.participantsList.concat(res.data.data)
            : res.data.data;
          this.setState({
            participantsList:
              newData.indexOf(undefined) > -1
                ? newData.filter((item) => item !== undefined)
                : newData,
            totalRecords: res.data.totalRecords,
            canAllCertificateGenerated: res?.data?.canAllCertificateGenerated,
            canAllCollageGenerated: res?.data?.canAllCollageGenerated,
            canAllVideoGenerated: res?.data?.canAllVideoGenerated,
            resetPagination: false,
            // activityReceived:res?.data?.activityReceived,
          });
        }
      })
      .catch((error) => {
        this.setState({ totalRecords: 0 });
      });
    this.setState({ paramObj: requestParams, pageNumberLoad: null });
  };
  categoryHandleChange = (event, page) => {
    const {
      activityType,
      activityReceived,
      certificateGenerated,
      activityCollageGenerated,
      bibGenerated,
      isAttended,
      videoGenerated,
      searchSelectValue,
      searchValue,
      validInvalid,
      approvedNotapproved,
      dateRange,
      startDate,
      endDate,
      categoryValue,
    } = this.state;
    let val = event.target.value;
    this.setState({ categoryValue: val, pageNumberLoad: 0 });
    // let valid =
    //   validInvalid === "Valid"
    //     ? true
    //     : validInvalid === "Invalid"
    //     ? false
    //     : null;
    // let approved =
    //   approvedNotapproved === "Approved"
    //     ? true
    //     : approvedNotapproved === "Not Approved"
    //     ? false
    //     : null;
    let activityId =
      activityType === 8
        ? ""
        : activityType === 9
        ? ""
        : activityType === 10
        ? ""
        : activityType;
    // let received =
    //   activityReceived === "Activity Received"
    //     ? true
    //     : activityReceived === "Activity Not Received"
    //     ? false
    //     : null;
    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let requestParams = {
      eventActivityTypeId: activityId,
      activityTypeId: this.getActivityId(activityId),
      categoryId: categoryValue,

      name: name,
      bib: bib,
      email: email,
      contact: contact,
      pageNumber: page ? page : 0,
      pageCount: 20,
      activityReceived: activityReceived,
      isAttended: isAttended,
      bibGenerated: bibGenerated,
      isValid: validInvalid,
      certificateGenerated: certificateGenerated,
      activityCollageGenerated: activityCollageGenerated,
      videoGenerated: videoGenerated,
    };

    getOrganizerExecutionList(requestParams)
      .then((res) => {
        if (res.data) {
          this.setState({
            participantsList: res?.data?.data,
            totalRecords: res?.data?.totalRecords,
            canAllCertificateGenerated: res?.data?.canAllCertificateGenerated,
            canAllCollageGenerated: res?.data?.canAllCollageGenerated,
            canAllVideoGenerated: res?.data?.canAllVideoGenerated,
            // activityReceived:res?.data?.activityReceived,
          });
        }
      })
      .catch((error) => {
        this.setState({ totalRecords: 0 });
      });
    this.setState({ paramObj: requestParams });

    let requestActivityParams = {
      startDate:
        dateRange === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("hh:mm:ss")}`,
      eventActivityTypeId: activityType,
      activityTypeId: this.getActivityId(activityType),
      categoryId: event.target.value,
      name: name,
      bib: bib,
      email: email,
      isValid: validInvalid,
      approved: approvedNotapproved,
    };

    getParticipantsActivityList(requestActivityParams).then((res) => {
      if (res.data) {
        this.setState({
          activityList: res.data.activityList,
          totalRecords: res.data.totalRecords,
        });
      } else {
        this.setState({ activityList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No activities are available for this category please select
          another category`,
        });
      }
    });
    this.setState({ paramObj: requestParams });
  };
  getDeliverable = (value) => {
    const { checkboxArr, isAllChecked } = this.state;
    let type = value;

    let arr = [];
    checkboxArr.map((check) => {
      if (check.check) {
        arr.push(check.id);
      }
    });
    if (arr.length > 0 || isAllChecked) {
      let requestParam = {
        // runnerIds: isAllChecked ? "" : arr,
        runnerIds: arr,
        regenerate: true,
      };

      generateDeliverables(type, requestParam);
    } else {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select users",
      });
    }
    this.handleClose();
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
      layoutAnchorEl: null,
    });
  };
  downloadCSVFile = () => {
    const { paramObj } = this.state;
    downloadResultCSV(paramObj);
  };

  getImportComponent = () => {
    const { classes } = this.props;
    return (
      <div className={classes.importContainerCss}>
        <div>
          <Checkbox
            // color="primary"
            className={classes.checkBox}
            onChange={(e) => {
              this.setState({
                matDataChecked: e.target.checked,
              });
            }}
          />
          <span style={{ color: "#727272" }}>{Properties.MatData_label}</span>
        </div>

        <div>
          <input
            className={classes.input}
            id="csv-file-input"
            type="file"
            accept=".csv"
            onClick={this.clearImage}
            onChange={this.multipleUpload}
          />

          <SubmitCancelBtn
            style={{ width: "130px" }}
            label="Import"
            type="submit"
            onClick={() => {
              this.handleImportFileUpload();
            }}
            isDisabled={!this.state.importFile}
          />
        </div>
        <div>
          {this.state.matDataChecked === false && (
            <a
              style={{ fontSize: "14px" }}
              onClick={() => this.handleCSVFormatDownload()}
            >
              Download CSV File Format
            </a>
          )}
        </div>
      </div>
    );
  };

  handleCSVFormatDownload = () => {
    let eventId = ApplicationUtil.getSupportsEventID();
    downloadResultFormat(eventId).then((res) => {});
  };

  handleImportClick = () => {
    this.setState({
      openImportDialog: true,
    });
  };

  handleImportClose = () => {
    this.setState({
      openImportDialog: false,
    });
  };

  multipleUpload = (e) => {
    if (e.target.id == "csv-file-input") {
      // function ispositive(element, array) {
      //   return "csv" == element.name.split(".").pop();
      // }

      let file = e.target.files[0];
      // Array.from(files).every(ispositive);
      if (file.name.split(".").pop() !== "csv") {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "only csv file is allowed",
        });
      } else {
        this.setState({
          importFile: file,
        });
      }
    }
  };

  handleImportFileUpload = () => {
    let file = this.state.importFile;
    let eventId = ApplicationUtil.getSupportsEventID();
    if (this.state.matDataChecked) {
      uploadActivityCSVFile(file, eventId).then((res) => {
        this.setState({
          openImportDialog: false,
        });
      });
    } else {
      uploadResultsCSVFile(file, eventId).then((res) => {
        this.setState({
          openImportDialog: false,
        });
      });
    }
  };
  getActivityList = (isFirstPage, page) => {
    const {
      activityList,
      validInvalid,
      approvedNotapproved,
      supportActivityType,
      startDate,
      endDate,
      dropdownOptions,
      dateRange,
      executionListFilters,
      pageNumberLoad,
      searchSelectValue,
      searchValue,
    } = this.state;

    let selectedValue = executionListFilters?.map((item) => item.selectedValue);

    let approved;
    if (selectedValue?.includes("approve")) {
      approved = true;
    } else if (selectedValue?.includes("notApprove")) {
      approved = false;
    } else {
      approved = null;
    }

    let valid;
    if (selectedValue?.includes("valid")) {
      valid = true;
    } else if (selectedValue?.includes("notValid")) {
      valid = false;
    } else {
      valid = null;
    }

    let dropdownOption;
    dropdownOptions.map((options) => {
      if (supportActivityType === options.displayName) {
        dropdownOption = options.type;
      }
    });
    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestParams = {
      pageNumber: page,
      pageCount: 20,
      isValid: validInvalid,
      approved: approvedNotapproved,
      validStatus: valid,
      approvedStatus: approved,
      startDate:
        dateRange === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "hh:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("hh:mm:ss")}`,
      activityTypeDropdown: dropdownOption,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
    };

    getParticipantsActivityList(requestParams)
      .then(({ data }) => {
        const list = data?.activityList;
        let formattedList = [];
        let currentDate = "";
        for (let i = 0; i < list?.length; i++) {
          list[i].isExpanded = false;
          let date = ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
            list[i].startDateLocal
          );
          if (currentDate !== date) {
            let obj = {
              id: list[i].id,
              date: list[i].startDateLocal,
              isGeneral: true,
            };
            let getSameDatesActivities = list.filter(
              (x) =>
                ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                  x.startDateLocal
                ) == date
            );
            obj.activities = getSameDatesActivities;
            formattedList.push(obj);
          }
          currentDate = date;
        }
        this.setState({
          activityList: activityList.concat(formattedList),
          activityTotalRecords: data?.totalRecords,
          pageNumberLoad: null,
          resetPagination: false,
        });
      })
      .catch((error) => {
        this.setState({ activityTotalRecords: 0 });
      });
  };
  getFilteredList = () => {
    this.setState({ isFiltered: !this.state.isFiltered });
  };
  // pageRefresh=()=>{
  //   this.props.updateAtheleteSetFilters(null);
  //  this.props.updateActivitySetFilters(null);
  //  this.props.updateParticipantSetFilters(null);
  // }
  updateFilterValues = (data) => {
    this.setState({ executionListFilters: data });
    for (let i = 0; i < data.length; i++) {
      let ele = data[i];
      if (ele.filterType === "Approval") {
        this.setState({
          approvedNotapproved:
            ele.selectedValue === "approve"
              ? true
              : ele.selectedValue === "notApprove"
              ? false
              : null,
        });
      } else if (ele.filterType === "Validity") {
        this.setState({
          validInvalid:
            ele.selectedValue === "valid"
              ? true
              : ele.selectedValue === "notValid"
              ? false
              : null,
        });
      }
    }
    this.props.updateActivitySetFilters({
      ...this.props.activityList1,
      approvedNotapproved:
        this.state.executionListFilters[0].selectedValue === "approve"
          ? true
          : this.state.executionListFilters[0].selectedValue === "notApprove"
          ? false
          : null,
      validInvalid:
        this.state.executionListFilters[1].selectedValue === "valid"
          ? true
          : this.state.executionListFilters[1].selectedValue === "notValid"
          ? false
          : null,
    });
  };
  updateAtheleteFilterValues = (data) => {
    this.setState({ atheleteListFilter: data });
    for (let i = 0; i < data.length; i++) {
      let ele = data[i];
      if (ele.filterType === "Certificate") {
        this.setState({
          certificateGenerated:
            ele.selectedValue === "certificateGenerate"
              ? true
              : ele.selectedValue === "certificateNotGenerate"
              ? false
              : null,
        });
      } else if (ele.filterType === "Collage") {
        this.setState({
          activityCollageGenerated:
            ele.selectedValue === "collageGenerate"
              ? true
              : ele.selectedValue === "collageNotGenerate"
              ? false
              : null,
        });
      } else if (ele.filterType === "Video") {
        this.setState({
          videoGenerated:
            ele.selectedValue === "videoGenerate"
              ? true
              : ele.selectedValue === "videoNotGenerate"
              ? false
              : null,
        });
      } else if (ele.filterType === "Recieve") {
        this.setState({
          activityReceived:
            ele.selectedValue === "activityRecieve"
              ? true
              : ele.selectedValue === "activityNotRecieve"
              ? false
              : null,
        });
      } else if (ele.filterType === "Attend") {
        this.setState({
          isAttended:
            ele.selectedValue === "attended"
              ? true
              : ele.selectedValue === "notAttended"
              ? false
              : null,
        });
      } else if (ele.filterType === "bib") {
        this.setState({
          bibGenerated:
            ele.selectedValue === "bibGenerated"
              ? true
              : ele.selectedValue === "bibNotGenerated"
              ? false
              : null,
        });
      } else if (ele.filterType === "validity") {
        this.setState({
          validInvalid:
            ele.selectedValue === "valid"
              ? true
              : ele.selectedValue === "notValid"
              ? false
              : null,
        });
      }
    }

    this.props.updateAtheleteSetFilters({
      ...this.props.atheleteList1,
      certificateGenerated:
        this.state.atheleteListFilter[2].selectedValue === "certificateGenerate"
          ? true
          : this.state.atheleteListFilter[2].selectedValue ===
            "certificateNotGenerate"
          ? false
          : null,
      activityReceived:
        this.state.atheleteListFilter[0].selectedValue === "activityRecieve"
          ? true
          : this.state.atheleteListFilter[0].selectedValue ===
            "activityNotRecieve"
          ? false
          : null,
      activityCollageGenerated:
        this.state.atheleteListFilter[3].selectedValue === "collageGenerate"
          ? true
          : this.state.atheleteListFilter[3].selectedValue ===
            "collageNotGenerate"
          ? false
          : null,
      bibGenerated:
        this.state.atheleteListFilter[5].selectedValue === "bibGenerated"
          ? true
          : this.state.atheleteListFilter[5].selectedValue === "bibNotGenerated"
          ? false
          : null,
      videoGenerated:
        this.state.atheleteListFilter[4].selectedValue === "videoGenerate"
          ? true
          : this.state.atheleteListFilter[4].selectedValue ===
            "videoNotGenerate"
          ? false
          : null,
      validInvalid:
        this.state.atheleteListFilter[6].selectedValue === "valid"
          ? true
          : this.state.atheleteListFilter[6].selectedValue === "notValid"
          ? false
          : null,
      isAttended:
        this.state.atheleteListFilter[1].selectedValue === "attended"
          ? true
          : this.state.atheleteListFilter[1].selectedValue === "notAttended"
          ? false
          : null,
    });
  };

  render() {
   
    const {
      isSupport,
      participantsList,
      activityReceived,

      validInvalid,
      approvedNotapproved,
      activityType,
      activityOptions,
      dropdownOptions,
      dropdownOption,
      eventActivityType,
      searchSelectValue,
      searchValue,
      totalRecords,
      displayColumns,
      csvData,
      categoryOptions,
      categoryValue,
      deliverableOptions,
      anchorEl,
      layoutAnchorEl,
      isActivityReceived,
      isCertificateGenerated,
      isActivityCollageGenerated,
      isVideoGenerated,
      activityTotalRecords,
      canAllCertificateGenerated,
      canAllCollageGenerated,

      canAllVideoGenerated,
      showActivityListView,
      showAtheleteListView,
      activityList,
      // activityReceived ,
      // isAttended ,
      // bibGenerated,
      //  validInvalid,
      certificateGenerated,
      activityCollageGenerated,
      videoGenerated,
      dateRange,
      dateRangeOptions,
      startDate,
      endDate,
      supportActivityType,
      pageNumberLoad,
      isFiltered,
      isDrawerOpen,
      executionListFilters,
      atheleteListFilter,
      bibGenerated,
      isAttended,
      challengeType,
      showActivityScreenshot,
      resetPagination,
    } = this.state;

    const { classes } = this.props;
    return (
      <div className={classes.mainBody}>
        <div className={classes.top}>
          {(totalRecords !== null || activityTotalRecords !== null) && (
            <div className={classes.totalRec}>
              <span>
                {showActivityListView ? "Activities" : "Participants"}
                <span style={{ marginLeft: "10px" }}>
                  {showActivityListView ? activityTotalRecords : totalRecords}
                </span>
              </span>
            </div>
          )}

          {/* {isSupport && (
            <div style={{ marginRight: "4px" }}>
              <SubmitCancelBtn
                label="Import"
                type="submit"
                onClick={this.handleImportClick}
              />
            </div>
          )} */}

          <div>
            {totalRecords > 0 && (
              <SubmitCancelBtn
                label="Export"
                type="submit"
                onClick={this.downloadCSVFile}
              />
            )}
          </div>

          <Tooltip title="Change Layout">
            <ViewQuiltIcon
              className={classes.editIcon}
              style={{ fontSize: "40px" }}
              onClick={(event) => {
                this.setState({
                  layoutAnchorEl: event.currentTarget,
                });
              }}
            />
          </Tooltip>
        </div>
        <div className={classes.searchFilter__row}>
          <div className="search__select">
            <FormControl className={classes.select}>
              <NativeSelect
                className={classes.selectActivity}
                value={searchSelectValue}
                onChange={this.selectHandleValue}
                style={{ width: 100 }}
              >
                {["Name", "BIB", "Email", "Contact"]?.map((value, index) => (
                  <option value={value}>{value}</option>
                ))}
              </NativeSelect>
            </FormControl>
            <form
              className="search"
              onSubmit={(e) => {
                e.preventDefault();
                this.searchHandleClick();
              }}
            >
              <input
                type="search"
                placeholder="Search..."
                value={searchValue}
                onChange={this.searchHandleChange}
              />
              <SearchIcon
                className="search__icon"
                onClick={this.searchHandleClick}
              />
            </form>
          </div>
          <div className="filters">
            {/* {showAtheleteListView && (
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={activityType}
                  onChange={this.activityHandleChange}
                  style={{ width: 130 }}
                >
                  <option value="">All activities</option>
                  {showAtheleteListView &&
                    activityOptions?.map((value, index) => (
                      <option value={value.eventSupportedActivityTypeId}>
                        {value.label}
                      </option>
                    ))}
                </NativeSelect>
              </FormControl>
            )} */}
            {/* category */}

            {activityType && categoryOptions.length > 0 && (
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={categoryValue}
                  onChange={this.categoryHandleChange}
                >
                  <option value="">All</option>
                  {categoryOptions?.map((value, index) => (
                    <>
                      <option value={value.id}>{value.label}</option>{" "}
                    </>
                  ))}
                </NativeSelect>
              </FormControl>
            )}
            {/* {!showActivityListView && (
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={activityReceived}
                  onChange={this.activityReceivedHandleChange}
                  style={{ width: 150 }}
                >
                  <option value="">All</option>
                  {["Activity Received", "Activity Not Received"]?.map(
                    (value, index) => (
                      <option value={value}>{value}</option>
                    )
                  )}
                </NativeSelect>
              </FormControl>
            )} */}

            {showActivityListView && (
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={dateRange}
                  onChange={this.dateHandleChange}
                  style={{ width: "auto" }}
                >
                  <option value="">All</option>
                  {dateRangeOptions?.map((value, index) => (
                    <option value={value.value}>{value.label}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            )}
            {showActivityListView && (
              <>
                {dateRange === "Custom Date" && (
                  <FormControl className={classes.customDate}>
                    <Typography variant="body2" gutterBottom>
                      <span>Start Dates</span>
                    </Typography>
                    <KeyboardDatePicker
                      autoOk
                      clearable
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      // disableFuture={true}
                      value={startDate}
                      //error={dobError}
                      style={{
                        width: "150px",
                      }}
                      maxDate={endDate}
                      InputProps={{
                        classes: {
                          root: classes.datePicker,
                        },
                      }}
                      onChange={(date) => {
                        this.customStartDate(date);
                      }}
                      keyboardIcon={
                        <IconCalendarBlue className={classes.calendarIconCss} />
                      }
                    />
                  </FormControl>
                )}
                {dateRange === "Custom Date" && (
                  <FormControl className={classes.customDate}>
                    <Typography variant="body2" gutterBottom>
                      <span>End Date</span>
                    </Typography>
                    <KeyboardDatePicker
                      autoOk
                      clearable
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      // disableFuture={true}
                      value={endDate}
                      //error={dobError}
                      style={{
                        width: "150px",
                      }}
                      InputProps={{
                        classes: {
                          root: classes.datePicker,
                        },
                      }}
                      minDate={startDate}
                      onChange={(date) => {
                        this.customEndDate(date);
                      }}
                      keyboardIcon={
                        <IconCalendarBlue className={classes.calendarIconCss} />
                      }
                    />
                  </FormControl>
                )}
              </>
            )}

            {showActivityListView && dropdownOptions.length > 0 && (
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={supportActivityType}
                  onChange={this.activityTypeHandleChange}
                  style={{ width: 130 }}
                >
                  <option value="">All activity types</option>
                  {showActivityListView &&
                    dropdownOptions?.map((value, index) => (
                      <option value={value.displayName}>
                        {value.displayName}
                      </option>
                    ))}
                </NativeSelect>
              </FormControl>
            )}

            {!showActivityListView && isSupport && (
              <FormControl className={classes.formControl}>
                <div className={classes.actions}>
                  <span>Actions</span>
                  <span>
                    <ArrowDropDownCircleOutlinedIcon
                      className={classes.editIcon}
                      onClick={(event) => {
                        this.setState({
                          anchorEl: event.currentTarget,
                        });
                      }}
                    />
                  </span>
                </div>
              </FormControl>
            )}
            <FilterIcon
              pageParams={{
                page: 0,
                start: 0,
                limit: 8,
                TotalRecords: this.state.totalRecords,
                pageNumberLoad: pageNumberLoad,
              }}
              filter={this.getFilteredList}
              isFiltered={isFiltered}
              open={() => this.setState({ isDrawerOpen: true })}
            />
            <DrawerFilter
              open={isDrawerOpen}
              pageParams={{
                page: 0,
                start: 0,
                limit: 8,
                TotalRecords: this.state.totalRecords,
                pageNumberLoad: pageNumberLoad,
              }}
              onClose={() => this.setState({ isDrawerOpen: false })}
              onApplyFilter={
                showAtheleteListView
                  ? this.activityReceivedHandleChange ||
                    this.attendedHandleChanges ||
                    this.bibGeneratedHandleChange ||
                    this.videoGeneratedHandleChange ||
                    this.collageGeneratedHandleChange ||
                    this.validHandleChange
                  : this.approvedHandleChange || this.validHandleChange
              }
              filterData={
                showAtheleteListView ? atheleteListFilter : executionListFilters
              }
              onChange={
                showAtheleteListView
                  ? this.updateAtheleteFilterValues
                  : this.updateFilterValues
              }
            />
          </div>
        </div>

        {showAtheleteListView && (
          <ListPageLoader
            pageParams={{
              page: 0,
              start: 0,
              limit: 8,
              TotalRecords: this.state.totalRecords,
              pageNumberLoad: pageNumberLoad,
            }}
            resetLoader={resetPagination}
            totalLoadedLength={participantsList ? participantsList.length : 20}
            loadNextData={(params) => {
          
              if (!participantsList.includes(undefined)) {
                this.getList(false, params.page);
              }
            }}
          >
            <Grid container>
              {participantsList?.length > 0 ? (
                <OrganizerTableList
                  isSupport={isSupport}
                  data={participantsList}
                  displayColumns={displayColumns}
                  bib
                  distance
                  completionTime
                  screenshot
                  activityReceived
                  url
                  validInvalid
                  cert={isCertificateGenerated}
                  collage={isActivityCollageGenerated}
                  video={isVideoGenerated}
                  resultAction
                  eventType={eventActivityType}
                  approved
                  updateCheckboxArr={(arr, isAllChecked) => {
                    this.setState({
                      checkboxArr: arr,
                      isAllChecked: isAllChecked,
                    });
                    let array = [];
                    arr.map((check) => {
                      if (check.check) {
                        array.push(check.id);
                      }
                    });
                    if (array.length > 0) {
                      let requestParam = {
                        // runnerIds: isAllChecked ? "" : array,
                        runnerIds: array,
                        activityReceived: activityReceived,
                        isValid: validInvalid,
                      };
                      this.setState({ paramObj: requestParam });
                    }
                  }}
                />
              ) : (
                <div className={classes.noResult}>
                  {totalRecords === 0
                    ? "No Participants"
                    : totalRecords === null
                    ? "Loading participants"
                    : ""}
                </div>
              )}
            </Grid>
          </ListPageLoader>
        )}
        {showActivityListView && (
          <ListPageLoader
            pageParams={{
              page: 0,
              start: 0,
              limit: 8,
              TotalRecords: this.state.activityTotalRecords,
              pageNumberLoad: pageNumberLoad,
            }}
            resetLoader={resetPagination}
            totalLoadedLength={activityList ? activityList.length : 20}
            loadNextData={(params) => {
              if (!activityList.includes(undefined)) {
                this.getActivityList(false, params.page);
              }
            }}
          >
            <Grid container>
              {activityList?.length > 0 ? (
                <ActivityList
                  approvedNotapproved
                  isSupport={isSupport}
                  data={activityList}
                  showActivityScreenshot={showActivityScreenshot}
                  isTraining
                  isGeneral
                  screenshot
                  validInvalid
                  url
                  challengeType={challengeType}
                  updateActivityList={(data, reload) => {
                    if (reload) {
                      this.initialDataLoad();
                    } else {
                      this.setState({ activityList: data });
                    }
                    // this.initialDataLoad();
                  }}
                  // onClick={() => this.pageRefresh()}
                  displayColumns={displayColumns}
                />
              ) : (
                <div className={classes.noResult}>
                  {activityTotalRecords === 0
                    ? "No result found"
                    : activityTotalRecords === null
                    ? "Loading activities"
                    : ""}
                </div>
              )}
            </Grid>
          </ListPageLoader>
        )}
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          // className="header__user--menu"
          style={{ marginTop: "40px" }}
        >
          {/* {deliverableOptions?.map((value, index) => (
            <MenuItem onClick={() => this.getDeliverable(value.value)}>
              {value.label}
            </MenuItem>
          ))} */}
          {canAllCertificateGenerated && (
            <MenuItem onClick={() => this.getDeliverable("CERTIFICATE")}>
              Generate Certificate
            </MenuItem>
          )}

          <MenuItem onClick={() => this.getDeliverable("BIB")}>
            Generate Bib
          </MenuItem>
          {canAllCollageGenerated && (
            <MenuItem onClick={() => this.getDeliverable("COLLAGE")}>
              Generate Collage
            </MenuItem>
          )}
          {canAllVideoGenerated && (
            <MenuItem onClick={() => this.getDeliverable("VIDEO")}>
              Generate Video
            </MenuItem>
          )}
        </Menu>
        <Menu
          anchorEl={layoutAnchorEl}
          keepMounted
          open={Boolean(layoutAnchorEl)}
          onClose={this.handleClose}
          // className="header__user--menu"
          style={{ marginTop: "40px" }}
        >
          <MenuItem
            onClick={() => {
              // users list
              let getSupportedEventDetails =
                ApplicationUtil.getSupportsEventsDetails();
              let obj = {
                ...getSupportedEventDetails,
                selectedList: "ATHELETE",
              };

              this.props.updateSupportSelectedEvent(obj);
              let requestParam = {
                pageCount: 20,
                pageNumber: 0,
                activityReceived: activityReceived,
                isAttended: isAttended,
                bibGenerated: bibGenerated,
                isValid: validInvalid,
                certificateGenerated: certificateGenerated,
                activityCollageGenerated: activityCollageGenerated,
                videoGenerated: videoGenerated,
              };

              getOrganizerExecutionList(requestParam)
                .then((res) => {
                  if (res.data) {
                    this.setState({
                      showActivityListView: false,
                      showAtheleteListView: true,
                      participantsList: res.data.data,
                      totalRecords: res.data.totalRecords,
                      displayColumns: res?.data?.displayColumns.split(","),
                      canAllCertificateGenerated:
                        res?.data?.canAllCertificateGenerated,
                      // activityReceived:res?.data?.activityReceived,
                      canAllVideoGenerated: res?.data?.canAllVideoGenerated,
                      canAllCollageGenerated: res?.data?.canAllCollageGenerated,
                      searchSelectValue: "Name",
                      searchValue: "",
                      isValid: validInvalid,
                    });
                  }
                })
                .catch((error) => {
                  this.setState({ totalRecords: 0, paramObj: requestParam });
                });

              this.handleClose();
            }}
          >
            Athelete List
          </MenuItem>
          <MenuItem
            onClick={() => {
              let getSupportedEventDetails =
                ApplicationUtil.getSupportsEventsDetails();
              let obj = {
                ...getSupportedEventDetails,
                selectedList: "ACTIVITY",
              };
              this.props.updateSupportSelectedEvent(obj);
              let activityRequestParams = {
                pageNumber: 0,
                pageCount: 20,
                isValid: validInvalid,
                approved: approvedNotapproved,
              };
              getParticipantsActivityList(activityRequestParams).then(
                ({ data }) => {
                  const list = data?.activityList;
                  let formattedList = [];
                  let currentDate = "";
                  for (let i = 0; i < list?.length; i++) {
                    list[i].isExpanded = false;
                    let date =
                      ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                        list[i].startDateLocal
                      );
                    if (currentDate !== date) {
                      let obj = {
                        id: list[i].id,
                        date: list[i].startDateLocal,
                        isGeneral: true,
                      };
                      let getSameDatesActivities = list.filter(
                        (x) =>
                          ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                            x.startDateLocal
                          ) == date
                      );
                      obj.activities = getSameDatesActivities;
                      formattedList.push(obj);
                    }
                    currentDate = date;
                  }

                  this.setState({
                    showActivityListView: true,
                    showAtheleteListView: false,
                    activityList: formattedList,
                    activityTotalRecords: data?.totalRecords,
                    searchSelectValue: "Name",
                    searchValue: "",
                  });
                }
              );

              this.handleClose();
            }}
          >
            Activity List
          </MenuItem>
        </Menu>
        {/* import dialog */}
        <DialogBox
          signup={false}
          open={this.state.openImportDialog}
          message={this.getImportComponent()}
          header={Properties.ImportResultsLabel}
          onClick={() => {
            this.handleImportClose();
          }}
          closeDisloag={() => this.handleImportClose()}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    supportSelectedEvent: state.supportUser.supportSelectedEvent,
    atheleteList1: state.supportUser.filters.atheleteList,
    activityList1: state.supportUser.filters.activityList,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateSupportSelectedEvent,
    updateActivitySetFilters,
    updateAtheleteSetFilters,
  })
)(OrganizerExecutionList);
