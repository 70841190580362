import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import PeopleIcon from "@material-ui/icons/People";
import CloseIcon from "@material-ui/icons/Close";
import {
  Checkbox,
  FormControlLabel,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import OrganizerCommonHeader from "../OrganizerCommonHeader";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import FullCalenderComponent from "../../../../common/FullCalenderComponent";
import {
  updateSupportSelectedEvent,
  IsCurrentDraftChallengeData,
} from "../../../../../redux/supportUser";
import { connect } from "react-redux";
import Breadcrumbs from "../../../../common/Breadcrumbs";
import { getProgramEventList } from "../../../../../apis/eventService";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import moment from "moment";
import httpService from "../../../../../apis/httpService";
import eventImg from "../../../../../images/Run.png";
import peopleImg from "../../../../../images/people.svg";
import editImg from "../../../../../images/edit-icon.svg";
export const INITIAL_EVENTS = [
  {
    id: 1,
    title: "Cooper Test 1",
    start: "2023-11-26",
    end: "2023-12-03",
    fontSize: "20px",
    extendedProps: {
      eventDetails: {
        name: "Cooper Test",
        dateFrom: "26-11-2023",
        dateTo: "03-12-2023",
        category: "Distance - 2km",
      },
    },
  },

  {
    id: 104,
    display: "block",
    id: 210,
    start: "2023-11-27T05:30:00+00:00",
    end: "2023-11-27T06:30:00+00:00",
    title: "Yoga Session",
    description: "Hello there",
    backgroundColor: "#a7c957",
    borderColor: "#a7c957",
    extendedProps: {
      day: "Tuesday",
      time: "5:00am - 6:00am",
      meetingLink: "https://meet.google.com/iqg-wwsx-kxw",
    },
  },
  {
    id: 104,
    display: "block",
    id: 210,
    start: "2023-11-29 10:00:00",
    title: "Nutrition Session",
    backgroundColor: "#a7c957",
    borderColor: "#a7c957",
    extendedProps: {
      day: "Tuesday",
      time: "10:00am - 11:00am",
      meetingLink: "https://meet.google.com/iqg-wwsx-kxw",
    },
  },
  {
    id: 104,
    display: "block",
    id: 210,
    start: "2023-12-01 16:00:00",
    title: "Mental Awareness",
    backgroundColor: "#a7c957",
    borderColor: "#a7c957",
    extendedProps: {
      day: "Tuesday",
      time: "4:00pm - 5:00pm",
      meetingLink: "https://meet.google.com/iqg-wwsx-kxw",
    },
  },

  {
    id: 202,
    title: "Stepathlon",
    start: "2023-12-10",
    end: "2024-01-01",
    backgroundColor: "brown",
    extendedProps: {
      eventDetails: {
        name: "Stepathlon",
        dateFrom: "10-12-2023",
        dateTo: "01-01-2024",
        category: "Steps - 10000",
      },
    },
  },

  {
    display: "block",
    id: 210,
    start: "2023-12-12 05:00:00",
    title: "Yoga Session",
    backgroundColor: "#a7c957",
    borderColor: "#a7c957",
    extendedProps: {
      day: "Tuesday",
      meetingLink: "https://meet.google.com/iqg-wwsx-kxw",
    },
  },
  {
    display: "block",
    id: 211,
    start: "2023-12-18 12:00:00",
    title: "Nutrition Session",
    backgroundColor: "#a7c957",
    borderColor: "#a7c957",
    extendedProps: {
      day: "Monday",
      time: "12:00pm - 01:00pm",
      meetingLink: "https://meet.google.com/iqg-wwsx-kxw",
    },
  },
  {
    display: "block",
    id: 210,
    start: "2023-12-28 05:00:00",
    title: "Yoga Session",
    backgroundColor: "#a7c957",
    borderColor: "#a7c957",
    extendedProps: {
      day: "Thursday",
      time: "05:00am - 06:00am",
      meetingLink: "https://meet.google.com/iqg-wwsx-kxw",
    },
  },
  {
    display: "block",
    id: 211,
    start: "2023-12-14 17:00:00",
    title: "Nutrition Session",
    backgroundColor: "#a7c957",
    borderColor: "#a7c957",
    extendedProps: {
      day: "Thursday",
      time: "05:00pm - 06:00pm",
      meetingLink: "https://meet.google.com/iqg-wwsx-kxw",
    },
  },
  {
    display: "block",
    id: 210,
    start: "2023-12-04 06:00:00",
    title: "Yoga Session",
    backgroundColor: "#a7c957",
    borderColor: "#a7c957",
    extendedProps: {
      day: "Monday",
      time: "06:00am - 07:00am",
      meetingLink: "https://meet.google.com/iqg-wwsx-kxw",
    },
  },
  {
    display: "block",
    id: 211,
    start: "2023-12-09 10:00:00",
    title: "Nutrition Session",
    backgroundColor: "#a7c957",
    borderColor: "#a7c957",
    extendedProps: {
      day: "Saturday",
      time: "10:00am - 11:00am",
      meetingLink: "https://meet.google.com/iqg-wwsx-kxw",
    },
  },
  {
    display: "block",
    id: 211,
    start: "2024-01-02 18:00:00",
    title: "Mental Wellness Session",
    backgroundColor: "#a7c957",
    borderColor: "#a7c957",
    extendedProps: {
      day: "Tuesday",
      time: "06:00pm - 07:00pm",
      meetingLink: "https://meet.google.com/iqg-wwsx-kxw",
    },
  },
  {
    id: 5,
    title: "Cooper Test 2",
    start: "2024-01-01",
    end: "2024-01-07",
    extendedProps: {
      eventDetails: {
        name: "Cooper Test 2",
        dateFrom: "01-01-2024",
        dateTo: "07-01-2024",
        category: "Distance - 2 Km",
      },
    },
  },
];
const API_URL_FILE = httpService.API_URL();

export class CalenderHomeView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calenderEvents: INITIAL_EVENTS,
      selectedEventDetails: {},
      programEventList: {},
    };
    this.calendarRef = React.createRef();
  }

  componentDidMount = () => {
    localStorage.removeItem("isProgramChallenge");
    localStorage.removeItem("selectedProgramParticpantTypeList");
    localStorage.removeItem("selectedList");
  };

  handleEventClick = (info) => {
    if (window.innerWidth < 600) {
      this.setState({ selectedEventDetails: info });
    }
    if (info?.view?.type !== "weekProgram") {
      this.setState({ selectedEventDetails: info });
    }
    if (info?.jsEvent?.target?.id === "editProgram") {
      this.setState({ selectedEventDetails: info });
      this.navigateToSetup(info?.event?.extendedProps?.programDetails?.details);
    }
    if (info?.jsEvent?.target?.id === "programParticipants") {
      this.setState({ selectedEventDetails: info });
      this.navigateToParticipants(
        info?.event?.extendedProps?.programDetails?.details
      );
    }
    if (info?.jsEvent?.target?.id === "editChallenge") {
      this.setState({ selectedEventDetails: info });
      this.navigateToChallengeSetup(
        info?.event?.extendedProps?.challengeEventDetails?.challengeDetails
      );
    }
    if (info?.jsEvent?.target?.id === "challengeParticipants") {
      this.setState({ selectedEventDetails: info });
      this.navigateToChallengeParticipants(
        info?.event?.extendedProps?.challengeEventDetails?.challengeDetails
      );
    }
  };
  // handleEvents = (info) => {
  //   console.log("event set", info);

  // };
  handleCalenderViewDate = (info) => {
    let startDate = new Date(info.start).toISOString().replace(/T.*$/, "");
    let endDate = new Date(info.end).toISOString().replace(/T.*$/, "");
    let programDetails = ApplicationUtil.getSupportsProgramDetails();
    let params = {
      fromDate: startDate + " 00:00:01",
      toDate: endDate + " 23:59:59",
    };
    getProgramEventList({ programId: programDetails?.id }).then(({ data }) => {
      let eventDTO = data?.eventDTO;
      let sessions = data?.programSessions;
      let challenges = data?.programChallenges;
      this.setState({ programEventList: data });
      let calenderData = [];
      for (let i = 0; i < eventDTO?.length; i++) {
        let event = eventDTO[i];
        // let trainingPlan = data[i]?.trainingPlan;
        // let result = data[i]?.result;
        // let color = this.dynamicColors()

        if (event) {
          let object = {};
          object.id = event?.id;
          object.title = event?.name;
          object.challengeType = event?.challengeType;
          object.start = event?.localStartDate;
          object.fontSize = "20px";
          object.end = moment(event?.localEndDate)
            .add(1, "day")
            .format("YYYY-MM-DD");
          object.editable = false;
          object.extendedProps = {
            programDetails: {
              name: event?.name,
              dateFrom: ApplicationUtil.changeDateFormat(event?.localStartDate),
              dateTo: ApplicationUtil.changeDateFormat(event?.localEndDate),
              category: `${event?.eventRunCategories[0]?.categoryName} - ${event?.eventRunCategories[0]?.category}`,
              details: event,
              organizers: data?.organizers,
              image: data?.image,
            },
          };
          // object.backgroundColor = color
          calenderData.push(object);
        }
      }

      if (challenges) {
        for (let i = 0; i < challenges.length; i++) {
          let challenge = challenges[i];
          if (challenge) {
            let object = {};
            object.id = challenge?.id;
            object.title = challenge?.name;
            object.programChallengeType = challenge?.programChallengeType;
            object.start = moment(
              challenge?.challengeStartDate,
              "DD-MM-YYYY HH:mm:ss"
            ).format("YYYY-MM-DD");
            object.end = moment(
              challenge?.challengeEndDate,
              "DD-MM-YYYY HH:mm:ss"
            )
              .add(1, "day")
              .format("YYYY-MM-DD");
            object.fontSize = "20px";
            object.editable = false;
            object.extendedProps = {
              challengeEventDetails: {
                name: challenge?.name,
                dateFrom: moment(
                  challenge?.challengeStartDate,
                  "DD-MM-YYYY HH:mm:ss"
                ).format("DD-MM-YYYY"),
                dateTo: moment(
                  challenge?.challengeEndDate,
                  "DD-MM-YYYY HH:mm:ss"
                ).format("DD-MM-YYYY"),
                questionnaires: challenge?.questionnaires,
                challengeDetails: challenge,
                organizers: data?.organizers,
              },
            };
            calenderData.push(object);
          }
        }
      }

      if (sessions) {
        for (let i = 0; i < sessions?.length; i++) {
          let session = sessions[i];
          // let trainingPlan = data[i]?.trainingPlan;
          // let result = data[i]?.result;
          // let color = this.dynamicColors()
          if (session && session?.name) {
            let object = {};
            object.id = session?.id;
            object.title = session?.name;
            object.start = ApplicationUtil.changeDateFormatDate(
              session?.sessionStartDate
            );
            object.end = ApplicationUtil.changeDateFormatDate(
              session?.sessionStartDate
            );
            object.fontSize = "20px";
            object.editable = false;
            object.extendedProps = {
              sessionDetails: {
                name: session?.name,
                day: moment(
                  ApplicationUtil.changeDateFormatDate(
                    session?.sessionStartDate
                  )
                ).format("dddd"),
                time: `${moment(
                  `${ApplicationUtil.changeDateFormatDate(
                    session?.sessionStartDate
                  )} ${session?.sessionStartDate?.split(" ")[1]}`
                ).format("LT")} - ${moment(
                  `${ApplicationUtil.changeDateFormatDate(
                    session?.sessionEndDate
                  )} ${session?.sessionEndDate?.split(" ")[1]}`
                ).format("LT")}`,
                endDate: `${moment(
                  `${ApplicationUtil.changeDateFormatDate(
                    session?.sessionEndDate
                  )} ${session?.sessionEndDate?.split(" ")[1]}`
                ).format("L LT")}`,
                meetingLink: session?.meetingLink,
                faculty: session?.facultyName || "",
                organizers: data?.organizers,
              },
            };
            // object.backgroundColor = color
            calenderData.push(object);
          }
        }
      }

      this.setState({ calenderEvents: calenderData, programName: data?.name });
    });
  };
  dynamicColors = function () {
    return (
      "hsl(" +
      360 * Math.random() +
      "," +
      (25 + 70 * Math.random()) +
      "%," +
      (65 + 10 * Math.random()) +
      "%)"
    );
  };
  uniqueId = () => {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
  };
  //   handleEventHover = (info) => {
  // console.log('mouse hover',info.event._def.publicId)
  // this.setState({eventHoverDetails:info})
  //   }
  //   handleEventMouseLeave = (info) => {
  //     console.log('mouse leave',info)
  //     this.setState({eventHoverDetails:{}})

  //   }
  handleEventMount = (info) => {
    info.el.setAttribute("id", `event-${info.event.id}`);
  };
  navigateToParticipants = (details) => {
    this.props.updateSupportSelectedEvent(details);
    this.props.IsCurrentDraftChallengeData(null);
    localStorage.setItem("challengeType", details?.challengeType);
    localStorage.setItem("editView", true);
    localStorage.removeItem("isChallengeResponse");
    localStorage.setItem("sidebarSelected", "Participants");
    localStorage.setItem("hideOrgSidebar", true);
    this.props.history.push("/organizer/calendar/list");
  };
  navigateToSetup = (details) => {
    this.props.updateSupportSelectedEvent(details);
    localStorage.setItem("challengeType", details?.challengeType);
    this.props.IsCurrentDraftChallengeData(null);
    localStorage.setItem("editView", true);
    localStorage.removeItem("isChallengeResponse");
    localStorage.setItem("sidebarSelected", "Setup");
    localStorage.setItem("hideOrgSidebar", true);
    this.props.history.push("/organizer/calendar/list");
  };
  navigateToChallengeSetup = (details) => {
    this.props.IsCurrentDraftChallengeData(details);
    this.props.updateSupportSelectedEvent(details);
    localStorage.setItem("challengeType", details?.programChallengeType);
    localStorage.setItem("isProgramChallenge", true);
    localStorage.setItem("editView", true);
    localStorage.setItem("isChallengeResponse", true);
    localStorage.setItem("sidebarSelected", "Setup");
    localStorage.setItem("hideOrgSidebar", true);
    this.props.history.push("/organizer/calendar/list");
  };
  navigateToChallengeParticipants = (details) => {
    this.props.IsCurrentDraftChallengeData(details);
    this.props.updateSupportSelectedEvent(details);
    localStorage.setItem("isProgramChallenge", true);
    localStorage.setItem("isChallengeResponse", true);
    localStorage.setItem("challengeType", details?.programChallengeType);
    // localStorage.setItem("editView", true);
    localStorage.setItem("sidebarSelected", "Participants");
    localStorage.setItem("hideOrgSidebar", true);
    this.props.history.push("/organizer/calendar/list");
  };
  weekSessionView = (info) => {
    let { sessionDetails } = info?.event?.extendedProps;
    let programTitle = info.event?.title;
    let endDate = sessionDetails?.endDate;
    let currentDate = moment().format("L");
    let isSessionOver = moment(endDate).isAfter(currentDate);

    /**
     * old hard coded logo of session
     * <img src='https://www.shutterstock.com/image-vector/yoga-logo-vector-emblem-260nw-571076140.jpg' alt='yoga' height=30 width=30 style='border-radius:50%; object-fit:cover' class='fc-laptopContent'/>
     * <img src='https://www.shutterstock.com/image-vector/yoga-logo-vector-emblem-260nw-571076140.jpg' alt='yoga' height=30 width=30 style='border-radius:50%; object-fit:cover' class='fc-mobileLogo'/>
     */

    let html = `<div class='fc-htmlEventContent'>
    <div style='display:flex; align-items:flex-start'>


    <img src=${
      sessionDetails?.organizers && sessionDetails?.organizers?.length > 0
        ? `${API_URL_FILE}${sessionDetails?.organizers[0]?.organizerLogo}`
        : eventImg
    } alt='yoga' class='fc-laptopContent' height=30 width=30 style='border-radius:50%; object-fit:cover' />
    <img src=${
      sessionDetails?.organizers && sessionDetails?.organizers?.length > 0
        ? `${API_URL_FILE}${sessionDetails?.organizers[0]?.organizerLogo}`
        : eventImg
    } alt='yoga' height=30 width=30 style='border-radius:50%; object-fit:cover' class='fc-mobileLogo'/>



    <div style='margin-left:5px; margin-bottom:5px' class='fc-laptopContent'>
    <p class='fc-htmlEventContent-span'>Time: <span>${
      sessionDetails?.time
    }</span></p>
   
   <p class='fc-htmlEventContent-span'>Day: <span>${
     sessionDetails?.day
   }</span></p>
    </div>
    </div>
    <p class='fc-htmlEventContent-span-title fc-laptopContent'>${programTitle}</p>
   
    <p class='fc-htmlEventContent-span fc-laptopContent'>Faculty: <span>${
      sessionDetails?.faculty
    }</span></p>
   
    ${
      sessionDetails?.meetingLink && isSessionOver
        ? `<a class='join-link fc-laptopContent' target='_blank' href=${sessionDetails?.meetingLink}>Join Here</a>`
        : ""
    }
    </div>`;
    return html;
  };
  onWeekProgramDetailClick = () => {
    console.log("clicked");
  };
  weekProgramDetailsView = (info) => {
    let { programDetails } = info?.event?.extendedProps;
    let challengeType = info?.event?.extendedProps?.challengeType;
    let html = `<div class='fc-htmlEventContent'>
    
    <div style='display:flex; align-items:center'>
    <img src=${
      programDetails?.organizers && programDetails?.organizers?.length > 0
        ? `${API_URL_FILE}${programDetails?.organizers[0]?.organizerLogo}`
        : eventImg
    } alt='yoga' class='fc-laptopContent' height=30 width=30 style='border-radius:50%; object-fit:cover' />
    <img src=${
      programDetails?.organizers && programDetails?.organizers?.length > 0
        ? `${API_URL_FILE}${programDetails?.organizers[0]?.organizerLogo}`
        : eventImg
    } alt='yoga' height=30 width=30 style='border-radius:50%; object-fit:cover' class='fc-mobileLogo'/>
    <div style='margin-left:10px;' >
    <p class='fc-htmlEventContent-span fc-laptopContent'>From: <span>${
      programDetails?.dateFrom
    }</span></p>
 
    <p class='fc-htmlEventContent-span fc-laptopContent'>To: <span>${
      programDetails?.dateTo
    }</span></p>
    </div>
    </div>
    <p class='fc-htmlEventContent-span-title fc-laptopContent'>${
      info.event.title
    }</p>

    <p class='fc-htmlEventContent-span fc-laptopContent'>${
      programDetails?.category && challengeType !== "STAIR_STEPPER"
        ? `${programDetails?.category}`
        : ""
    }</p>
  <div class='fc-actionContainer' >
 <img src=${editImg} height=18 width=20 style='color:'white' id='editProgram' />

  <img src=${peopleImg} height=30 width=30 style='color:'white' id='programParticipants'/>
  </div>
   
    </div>`;
    return html;
  };
  weekActivityView = (info) => {
    let { activity } = info?.event?.extendedProps;
    let title = info.event.title;
    let html = `<div class='fc-htmlEventContent'>
    <p class='fc-htmlEventContent-span-title'>${title}</p>
    <br/>
    ${
      activity &&
      activity.displayColumns
        ?.split(",")
        .filter((x) => x)
        .map(
          (activity) =>
            `
          <p key=${activity} class='fc-htmlEventContent-span'>
            ${labels[activity]}: 
           <span> ${
             activity === "distance"
               ? parseFloat(activity[activity] / 1000).toFixed(2) + " km"
               : activity[activity]
           }
            </span>
          </p>
          <br/>
          `
        )
        .join("")
    }
    </div>`;
    return html;
  };
  weekChallengeDetailsResponseView = (info) => {
    let { challengeEventDetails } = info?.event?.extendedProps;
    let html = `<div class='fc-htmlEventContent'>

    <div style='display:flex; align-items:center'>


    <img src=${
      challengeEventDetails?.organizers &&
      challengeEventDetails?.organizers?.length > 0
        ? `${API_URL_FILE}${challengeEventDetails?.organizers[0]?.organizerLogo}`
        : eventImg
    } alt='' class='fc-laptopContent' height=30 width=30 style='border-radius:50%; object-fit:cover' />
    <img src=${
      challengeEventDetails?.organizers &&
      challengeEventDetails?.organizers?.length > 0
        ? `${API_URL_FILE}${challengeEventDetails?.organizers[0]?.organizerLogo}`
        : eventImg
    } alt='' height=30 width=30 style='border-radius:50%; object-fit:cover' class='fc-mobileLogo'/>


    <div style='margin-left:10px;' >
    <p class='fc-htmlEventContent-span fc-laptopContent'>From: <span>${
      challengeEventDetails?.dateFrom
    }</span></p>
 
    <p class='fc-htmlEventContent-span fc-laptopContent'>To: <span>${
      challengeEventDetails?.dateTo
    }</span></p>
    </div>
    </div>
    <p class='fc-htmlEventContent-span-title fc-laptopContent'>${
      info.event.title
    }</p>
    <div class='fc-actionContainer' >
 <img src=${editImg} height=18 width=20 style='color:'white' id='editChallenge' />

  <img src=${peopleImg} height=30 width=30 style='color:'white' id='challengeParticipants'/>
  </div>
   
    </div>
    </div>`;
    return html;
  };
  weekProgramEventContent = (info) => {
    if (info?.view?.type === "weekProgram") {
      let eventHtml = "";
      let sessionHtml = "";
      let activityHtml = "";
      let challengeHtml = "";

      if (info.event.extendedProps?.challengeEventDetails) {
        challengeHtml += this.weekChallengeDetailsResponseView(info);
      }
      if (info.event.extendedProps?.activity) {
        activityHtml += this.weekActivityView(info);
      }
      if (info.event.extendedProps?.programDetails) {
        eventHtml += this.weekProgramDetailsView(info);
      }
      if (info.event.extendedProps?.sessionDetails) {
        sessionHtml += this.weekSessionView(info);
      }

      return {
        html: info.event?.extendedProps?.sessionDetails
          ? sessionHtml
          : info.event.extendedProps?.activity
          ? activityHtml
          : info.event.extendedProps?.challengeEventDetails
          ? challengeHtml
          : eventHtml,
      };
    }
  };
  getProgramDuration = () => {
    let programDetails = ApplicationUtil.getSupportsProgramDetails();

    let wholeProgramDurationMonths = moment(programDetails?.localEndDate).diff(
      moment(programDetails?.localStartDate),
      "months",
      true
    );

    const remainingDays = moment(programDetails.localEndDate).diff(
      moment(programDetails.localStartDate).add(
        wholeProgramDurationMonths,
        "months"
      ),
      "days"
    );

    if (remainingDays > 0) {
      wholeProgramDurationMonths += 1;
    }
    
    const finalMonthDuration = wholeProgramDurationMonths
      ? wholeProgramDurationMonths < 1
        ? 1
        : Math.round(wholeProgramDurationMonths)
      : Math.round(wholeProgramDurationMonths);

    return finalMonthDuration;
  };

  render() {
    const {
      calenderEvents,
      selectedEventDetails,
      programName,
      programEventList,
    } = this.state;
    const { classes } = this.props;
    return (
      <>
        <OrganizerCommonHeader
          title={`${programName || ""} Calender` || "Wellness Calender"}
          logo
        />
        {/* <Header label={"Wellness Calender"} hideDrawer /> */}
        <div className={classes.calenderContainer}>
          <Breadcrumbs />
          {/* {programEventList?.summaryGenerated && ( */}
          <SubmitCancelBtn
            label={"Dashboard"}
            onClick={() => {
              this.props.history.push("/organizer/calendar/programDashboard");
            }}
            type={"submit"}
            style={{
              width: "160px",
            }}
          />
          {/* )} */}
          <SubmitCancelBtn
            onClick={() => {
              this.props.history.push("/organizer/calendar/participants");
            }}
            label={"Participants"}
            type={"submit"}
            style={{
              width: "160px",
              marginLeft: "10px",
            }}
          />
          <FullCalenderComponent
            events={calenderEvents}
            views={{
              weekProgram: {
                type: "dayGridWeek", //'dayGridWeek',
                buttonText: "Week",
                eventContent: (info) => {
                  return this.weekProgramEventContent(info);
                },
                dayHeaderContent: (args) => {
                  return moment(args.date).format("ddd Do");
                },
                dayMaxEventRows: 2,
                // duration: { days: 7 },
              },
              monthProgram: {
                type: "dayGridMonth",
                buttonText: "Month",
                displayEventTime: false,
                dayMaxEventRows: 2,
              },
              wholeProgram: {
                buttonText: "Program",
                type: "dayGridMonth",
                displayEventTime: false,
                dayMaxEventRows: 2,
                // type:'multiMonth',
                initialDate: new Date("2023-12-01"),
                titleFormat: { month: 'short', year: 'numeric' },
                // startParam:'2023-12-01',
                // endParam:'2024-01-30',
                duration: {
                  months: this.getProgramDuration(),
                },
              },
            }}
            handleDateSelect={this.handleDateSelect}
            renderEventContent={this.renderEventContent}
            handleEventClick={this.handleEventClick}
            handleEvents={this.handleEvents}
            calenderRef={this.calendarRef}
            handleCalenderViewDate={this.handleCalenderViewDate}
            handleEventHover={this.handleEventHover}
            handleEventMouseLeave={this.handleEventMouseLeave}
            handleEventMount={this.handleEventMount}
          />
          <Popover
            id={selectedEventDetails?.event?.id}
            open={Boolean(selectedEventDetails?.event)}
            anchorEl={selectedEventDetails?.el}
            onClose={() => this.setState({ selectedEventDetails: {} })}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <div className={classes.popoverContent}>
              {selectedEventDetails?.event?.extendedProps?.activity && (
                <div className="block">
                  {selectedEventDetails?.event?.extendedProps?.activity.displayColumns
                    ?.split(",")
                    .filter((x) => x)
                    .map((activity) => (
                      <Typography
                        key={activity}
                        className={classes.contentTypo}
                      >
                        <span>{`${labels[activity]}: `}</span>
                        {`${
                          activity === "distance"
                            ? parseFloat(
                                selectedEventDetails?.event?.extendedProps
                                  ?.activity[activity] / 1000
                              ).toFixed(2) + " km"
                            : selectedEventDetails?.event?.extendedProps
                                ?.activity[activity]
                        }`}
                      </Typography>
                    ))}
                </div>
              )}

              {selectedEventDetails?.event?.extendedProps?.sessionDetails && (
                <div className="block">
                  <Typography className={classes.contentTypo}>
                    <span className="popover-session-title">
                      {selectedEventDetails?.event?.title}
                    </span>
                    <span>
                      {
                        selectedEventDetails?.event?.extendedProps
                          ?.sessionDetails?.day
                      }
                      ,{" "}
                      {`${selectedEventDetails?.event?.extendedProps?.sessionDetails?.time}`}
                    </span>
                    <br />

                    <span
                      style={{ marginBottom: "5px" }}
                    >{`Faculty - ${selectedEventDetails?.event?.extendedProps?.sessionDetails?.faculty}`}</span>
                    <br />
                    {selectedEventDetails?.event?.extendedProps?.sessionDetails
                      ?.meetingLink &&
                      moment(
                        selectedEventDetails?.event?.extendedProps
                          ?.sessionDetails?.endDate
                      ).isAfter(moment().format("L")) && (
                        <a
                          className={classes.joinLink}
                          target="_blank"
                          href={
                            selectedEventDetails?.event?.extendedProps
                              ?.sessionDetails?.meetingLink
                          }
                        >
                          Join Here
                        </a>
                      )}
                  </Typography>
                </div>
              )}
              {selectedEventDetails?.event?.extendedProps?.programDetails && (
                <div>
                  <div className={classes.eventDetailsIconContainer}>
                    <CreateIcon
                      className="create icon"
                      onClick={() =>
                        this.navigateToSetup(
                          selectedEventDetails?.event?.extendedProps
                            ?.programDetails?.details
                        )
                      }
                    />
                    <PeopleIcon
                      className="people icon"
                      onClick={() =>
                        this.navigateToParticipants(
                          selectedEventDetails?.event?.extendedProps
                            ?.programDetails?.details
                        )
                      }
                    />
                    <CloseIcon
                      className="close icon"
                      onClick={() =>
                        this.setState({ selectedEventDetails: {} })
                      }
                    />
                  </div>
                  <Typography className={classes.detailsContentTypo}>
                    <span className="title" style={{ marginBottom: "5px" }}>
                      {
                        selectedEventDetails?.event?.extendedProps
                          ?.programDetails?.name
                      }
                    </span>

                    <span className="sub-title">
                      {`${selectedEventDetails?.event?.extendedProps?.programDetails?.dateFrom} to ${selectedEventDetails?.event?.extendedProps?.programDetails?.dateTo}`}
                    </span>
                  </Typography>
                  <Typography
                    className={classes.detailsContentTypo}
                    style={{ marginTop: 0 }}
                  >
                    <span className="sub-title">
                      {selectedEventDetails?.event?.extendedProps
                        ?.programDetails?.category &&
                      selectedEventDetails?.event?.extendedProps?.programDetails
                        ?.details?.challengeType !== "STAIR_STEPPER"
                        ? `${selectedEventDetails?.event?.extendedProps?.programDetails?.category}`
                        : ""}
                    </span>
                  </Typography>
                </div>
              )}
              {selectedEventDetails?.event?.extendedProps
                ?.challengeEventDetails && (
                <div>
                  <div className={classes.eventDetailsIconContainer}>
                    <CreateIcon
                      className="create icon"
                      onClick={() =>
                        this.navigateToChallengeSetup(
                          selectedEventDetails?.event?.extendedProps
                            ?.challengeEventDetails?.challengeDetails
                        )
                      }
                    />
                    <PeopleIcon
                      className="people icon"
                      onClick={() =>
                        this.navigateToChallengeParticipants(
                          selectedEventDetails?.event?.extendedProps
                            ?.challengeEventDetails?.challengeDetails
                        )
                      }
                    />
                    <CloseIcon
                      className="close icon"
                      onClick={() =>
                        this.setState({ selectedEventDetails: {} })
                      }
                    />
                  </div>
                  <Typography className={classes.detailsContentTypo}>
                    <span className="title" style={{ marginBottom: "5px" }}>
                      {
                        selectedEventDetails?.event?.extendedProps
                          ?.challengeEventDetails?.name
                      }
                    </span>

                    <span className="sub-title">
                      {`${selectedEventDetails?.event?.extendedProps?.challengeEventDetails?.dateFrom} to ${selectedEventDetails?.event?.extendedProps?.challengeEventDetails?.dateTo}`}
                    </span>
                  </Typography>
                </div>
              )}
            </div>
          </Popover>
        </div>
      </>
    );
  }
}

const labels = {
  distance: "Distance",
  steps: "Steps",
  completionTime: "Duration",
  target: "Target",
  achieved: "Achieved",
};
const styles = (theme) => ({
  calenderContainer: {
    margin: "20px",
    marginTop: "60px",
    "& .fc .fc-popover": {
      zIndex: 999,
    },
    "& .fc-event-title": {
      fontSize: "17px",
      fontWeight: 600,
      textWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
      },
    },
    "& .fc .fc-daygrid-more-link": {
      whiteSpace: "normal",
    },
    "& .fc .fc-daygrid-day-top": {
      minHeight: "38px",
    },
    "& .fc-daygrid-day-number, & .fc-daygrid-month-start": {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10px !important",
      },
    },
    "& .fc-daygrid-month-start": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.6em",
        wordBreak: "break-word",
      },
    },
    "& .fc-daygrid-day-frame": {
      [theme.breakpoints.down("sm")]: {
        minHeight: "150px !important",
      },
    },
    "& .fc-toolbar": {
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-evenly",
      },
    },
    "& .fc-button-group": {
      marginTop: "10px",
    },
    "& .fc-toolbar-title": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginRight: "0px",
    },
    "& .fc-htmlEventContent": {
      padding: "5px",
      overflowX: "hidden",
      textWrap: "wrap",
      "& p": {
        margin: "3px 0px",
      },
      "& .join-link": {
        lineHeight: "30px",
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "#ffffff",
        // padding: "2px",
        borderRadius: "10px",
        color: "#3788d8",
        width: "fit-content",
        paddingInline: "10px",
        [theme.breakpoints.down("sm")]: {
          lineHeight: "13px",
          fontSize: "12px",
          textAlign:"center",
          padding: "4px",
        },
      },
      "& .fc-htmlEventContent-span": {
        // lineHeight: "22px",
        textWrap: "wrap",
        fontSize: "16px",
        "& span": {
          fontWeight: "bold",
        },
        [theme.breakpoints.down("lg")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
        },
      },
      "& .fc-htmlEventContent-span-title": {
        // lineHeight: "20px",
        fontSize: "16px",
        fontWeight: "bold",
      },
      "& .fc-mobileLogo": {
        display: "block",
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },
      "& .fc-laptopContent": {
        display: "none",
        [theme.breakpoints.up("sm")]: {
          display: "block",
        },
      },
    },
    "& .fc-actionContainer": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      // justifyContent: "center",
    },
  },
  contentTypo: {
    fontSize: "15px",
    lineHeight: "20px",
    "& span": {
      fontWeight: "bold",
    },
  },
  detailsContentTypo: {
    marginTop: "10px",
    paddingLeft: "8px",
    paddingRight: "8px",
    lineHeight: "20px",
    "& .title": {
      display: "block",
      fontSize: "20px",
      fontWeight: "bold",
      color: "#3788d8",
    },
    "& .sub-title": {
      fontWeight: "bold",
      fontSize: "15px",
      display: "block",
      color: "black",
    },
  },
  attendContainer: {
    marginTop: "10px",
  },
  popoverContent: {
    paddingTop: "10px",
    paddingBottom: "10px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
    "& .block": {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
    "& .popover-session-title": {
      display: "block",
      fontSize: "20px",
      fontWeight: "bold",
      color: "#3788d8",
    },
  },
  eventDetailsIconContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& .icon": {
      color: "gray",
      width: "30px",
      height: "25px",
    },
  },
  joinLink: {
    lineHeight: "30px",
    fontSize: "16px",

    fontWeight: "bold",
    backgroundColor: "#3788d8",
    padding: "5px",
    borderRadius: "10px",
    color: "#ffffff",
  },
});
function mapStateToProps(state) {
  return {
    supportDTO: state.supportUser.supportDTO,
    supportSelectedEvent: state.supportUser.supportSelectedEvent,
  };
}
export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    updateSupportSelectedEvent,
    IsCurrentDraftChallengeData,
  })
)(CalenderHomeView);
