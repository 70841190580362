import React from "react";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isBrowser } from "react-device-detect";
import { withRouter } from "react-router-dom";
import { NavigateBefore } from "@material-ui/icons";
import ApplicationUtil from "../../utils/ApplicationUtil";

const useStyles = makeStyles((theme) => ({
  active: {
    margin: "4px",
    fontSize: "22px",
  },
  nonActive: {
    fontSize: "16px",
    margin: "4px",
  },
}));
function Breadcrumbs(props) {
  const classes = useStyles();
  const {
    history,
    location: { pathname },
  } = props;
  let isProgramDashboard = window.location.href
    .toLowerCase()
    .includes("programdashboard");
  let pathnames = pathname.split("/").filter((x) => x);
  let listname = localStorage.getItem("sidebarSelected");
  const isCreateChallenge = localStorage.getItem("createChallenge");
  let isChallengeEvent = localStorage.getItem("isChallengeEvent");
  let isTraining = localStorage.getItem("isTraining");
  let selectedProgramDetails = ApplicationUtil.getSupportsProgramDetails();
  let isChallengeResponse = localStorage.getItem("isChallengeResponse");
  //"Summary", "Participants", "Results", "Setup", "Leaderboard";
  if (listname) {
    if (listname === "Setup") {
      let isEdit = localStorage.getItem("editView");
      if (isEdit) {
        listname = isChallengeResponse ? "Modify Challenge" : "Modify Event";
      } else {
        listname = isChallengeResponse ? "Create Challenge" : "Create Event";
      }
    }
    //  else if (listname === "Leaderboard") listname = "Leaderboard";
    // else if (listname === "Summary") listname = "Summary";
    else listname = listname;
  }

  const mapBreadcrumbs = {
    // "/organizer": isChallenge ? "Challenges" : "Events",
    "/organizer": isCreateChallenge
      ? "Program"
      : isChallengeEvent
      ? "Challenges"
      : isTraining
      ? "Training"
      : selectedProgramDetails?.programStartDate
      ? "Program"
      : "Events",
    "/organizer/list": isChallengeEvent
      ? "Challenges"
      : isTraining
      ? "Training"
      : selectedProgramDetails?.programStartDate
      ? "Program"
      : "Events",
    "/organizer/list": listname ? listname : "Results",
    "/organizer/list/details": `${
      listname === "Results" ? "Result" : listname
    } Details`,
    "/organizer/createChallenge": "Create Challenge",
    "/organizer/calendar": "Calendar",
    "/organizer/calendar/participants": "Program Participants",
    "/organizer/calendar": "Calendar",
    "/organizer/calendar/list": listname ? listname : "Participants",
    "/organizer/calendar/list/details": "Participant Details",
    "/organizer/calendar/createSession": "Add Session",
    "/organizer/calendar/programDashboard": "Dashboard",
    "/organizer/calendar/participants/info": "Info",
    "/programDashboard": "Dashboard",
    "/calender": "Calendar",
  };

  const NavigateRoute = (route) => {
    if (route === "/organizer") {
      history.push("/organizer/eventlist");
      localStorage.setItem("headerLinkSelected", mapBreadcrumbs[route]);
      localStorage.removeItem("sidebarSelected");
    } else {
      history.push(route);
    }
  };

  return isBrowser ? (
    <MUIBreadcrumbs aria-label="breadcrumb" separator=">">
      {pathnames.map((name, index, ele) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <div
            className={classes.active}
            key={ele}
            style={{ fontWeight: isProgramDashboard && "bold" }}
          >
            <span key={name}>{mapBreadcrumbs[routeTo]}</span>
          </div>
        ) : (
          <div
            className={classes.nonActive}
            key={ele}
            style={{ fontWeight: isProgramDashboard && "bold" }}
            onClick={() => NavigateRoute(routeTo)}
          >
            <Link key={name}>
              {/* {name} */}
              {mapBreadcrumbs[routeTo]}
            </Link>
          </div>
        );
      })}
    </MUIBreadcrumbs>
  ) : null;
}
export default withRouter(Breadcrumbs);
