import { Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Properties from "../../../../../../properties/Properties";
import BasicView from "./TabsView/BasicView";
import SettingsView from "./TabsView/SettingsView";
import ImagesView from "./TabsView/ImagesView";
import CertificatesView from "./TabsView/CertificatesView";
import { compose } from "recompose";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";
import ProductConfigurationView from "./TabsView/ProductConfigurationView";

const CreateChallenge = ({ classes }) => {
  let activeTab = localStorage.getItem("ActiveTab");
  let isEdit = localStorage.getItem("editView");
  const currentChallenge = ApplicationUtil?.getProgramUnderCurrentChallenge();

  let tabDisabled = false;

  let activeTabIndex = 0;
  if (activeTab === "ZERO") {
    activeTabIndex = 0;
  } else if (activeTab === "ONE") {
    activeTabIndex = 1;
  }
  if (activeTab === "TWO") {
    activeTabIndex = 2;
  } else if (activeTab === "THREE") {
    activeTabIndex = 3;
  } else if (activeTab === "FOUR") {
    activeTabIndex = 4;
  }

  const [createState, setCreateState] = useState({
    ActiveTabIndex: activeTabIndex,
    isEdit: false,
    tabsDisabled: tabDisabled,
  });

  useEffect(() => {
    if (!currentChallenge) {
      setCreateState((prev) => ({
        ...prev,
        tabsDisabled: true,
      }));
    }
  }, []);

  const { ActiveTabIndex, tabsDisabled } = createState;

  const handleTabChange = (event, newValue) => {
    let isEdit = localStorage.getItem("editView");
    setCreateState((prev) => ({
      ...prev,
      ActiveTabIndex: newValue,
      isEdit: isEdit,
    }));
  };

  return (
    <div>
      <Tabs
        value={ActiveTabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        variant={isMobile ? "scrollable" : "fullWidth"}
        scrollButtons={isMobile ? "on" : "off"}
      >
        <Tab
          value={0}
          label={Properties.BasicTabLabel}
          className={classes.textTransformCss}
        />
        <Tab
          value={1}
          label={Properties.SettingsTabLabel}
          className={classes.textTransformCss}
          disabled={tabsDisabled}
        />
        <Tab
          value={2}
          label={"Images"}
          className={classes.textTransformCss}
          disabled={tabsDisabled}
        />
        {/* {role == "SUPPORT" && ( */}

        {/* Certificates tab  */}
        {/* <Tab
          value={3}
          label={Properties.DeliverableTabLabel}
          className={classes.textTransformCss}
          disabled={tabsDisabled}
        /> */}

        <Tab
          value={4}
          label={"Product Configuration"}
          className={classes.textTransformCss}
          disabled={tabsDisabled}
        />
        {/* )} */}
      </Tabs>

      <div
      //   className={classes.root}
      >
        {ActiveTabIndex === 0 && (
          <>
            <BasicView
              onSuccess={(e) => {
                setCreateState((prev) => ({
                  ...prev,
                  tabsDisabled: false,
                }));
                handleTabChange(e, 1);
              }}
            />
          </>
        )}

        {ActiveTabIndex === 1 && <SettingsView />}

        {ActiveTabIndex === 2 && <ImagesView />}
        {ActiveTabIndex === 3 && <CertificatesView />}
        {ActiveTabIndex === 4 && <ProductConfigurationView />}
      </div>
    </div>
  );
};

const styles = (theme) => ({
  textTransformCss: {
    textTransform: "none",
    fontSize: "22px",
    fontWeight: "bold",
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(CreateChallenge);
