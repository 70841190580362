import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { isBrowser } from "react-device-detect";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import {
  createTipsUnderChallenge,
  editTipsUnderChallenge,
} from "../../../../../../../apis/eventService";
import DialogBox from "../../../../../../common/DialogBox";
import NewTextEditor from "../../../../../../common/NewTextEditor";
import Snackbar from "../../../../../../common/Snackbar";
import SubmitCancelBtn from "../../../../../../common/SubmitCancelBtn";

const TipCreationComponent = ({
  classes,
  isPastChallenge,
  editTipData = null,
  onTipEditClear = () => {},
  getAllTips = () => {},
}) => {
  /** COMPONENT MAIN STATE */
  const [tipState, setTipState] = useState({
    TipText: null,
    openTextEditor: false,
    textError: false,
    htmlTextValue: "",
    openEditConfirmPopup: false,
  });

  const { TipText } = tipState;
  let { htmlTextValue, openTextEditor, textError, openEditConfirmPopup } =
    tipState;

  /**
   * -Vishal
   * getting text editor for embedd the hyperlink in the tip
   * @returns
   */
  const _getTextEditor = () => {
    return (
      <div
        style={{
          width: "80%",
          height: "auto",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <NewTextEditor
          htmlText={htmlTextValue ? htmlTextValue : ""}
          getEditedValue={(value) => {
            let formattedValue = value === "<p></p>" ? null : value.toString();

            setTipState((prev) => ({
              ...prev,
              htmlTextValue: formattedValue,
            }));
          }}
          isResponses={(isResponded) => {
            setTipState((prev) => ({
              ...prev,
              textError: !isResponded,
            }));
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <SubmitCancelBtn
            type="submit"
            label={"Submit"}
            onClick={() => {
              setTipState((prev) => ({
                ...prev,
                openTextEditor: false,
              }));
            }}
          />
        </div>
      </div>
    );
  };

  /**
   * final call for save new tip
   */
  const SaveTipsOption = () => {
    // if (TipText) {
    if (!textError && htmlTextValue) {
      // createTipsUnderChallenge({ tip: TipText }).then((res) => {
      createTipsUnderChallenge({ tip: htmlTextValue }).then((res) => {
        Snackbar.show({
          variant: "success",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Tip created successfully",
        });
        setTipState((prev) => ({
          ...prev,
          htmlTextValue: "",
          // TipText: "",
        }));
        getAllTips();
      });
    } else {
      setTipState((prev) => ({
        ...prev,
        textError: true,
      }));

      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Fill the Tip text",
      });
    }
  };

  /**
   * -Vishal
   * Final call for update the Tip
   */
  const handleEditTip = () => {
    // alert("Updated");
    // console.log({ _pl });
    let _pl = { id: editTipData?.id, tip: htmlTextValue };
    editTipsUnderChallenge(_pl).then((res) => {
      Snackbar.show({
        variant: "success",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Tip updated successfully",
      });
      setTipState((prev) => ({
        ...prev,
        htmlTextValue: "",
        // TipText: "",
      }));
      getAllTips();
    });

    closeEditConfirmPopup();
  };

  /**
   * when user click on Edit button then checking if tip is present and error not present
   * then open confiramtion dialogue as - Are you sure want to update this tip ?
   */
  const handleEditTipConfirmation = () => {
    if (!textError && htmlTextValue) {
      setTipState((prev) => ({
        ...prev,
        //open confirmation dialog
        openEditConfirmPopup: true,
      }));
    } else {
      //validation
      setTipState((prev) => ({
        ...prev,
        textError: true,
      }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Fill the Tip text",
      });
    }
  };

  /**
   * Tip Edit confirmation pop-up
   * @returns
   */
  const getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{`Are you sure want to update this tip ?`}</span>
        </Typography>
      </div>
    );
  };

  /**
   * When user cancel/denied the confirmation of edit tip - Are you sure want to update this tip ?
   * close dialogue
   */
  const closeEditConfirmPopup = () => {
    setTipState((prev) => ({
      ...prev,
      openEditConfirmPopup: false,
    }));

    //for clear the edit mode from parent componet
    onTipEditClear();
  };

  /**
   * editTipData - recieves from parent - all tips list- edit action
   * check if is in edit mode or not
   * if it is then set data and open textEditor
   */
  useEffect(() => {
    let _isEdit = !!editTipData;
    setTipState((prev) => ({
      ...prev,
      htmlTextValue: _isEdit ? editTipData?.tip : "",
      openTextEditor: _isEdit,
      textError: false,
    }));
  }, [editTipData]);

  return (
    <div style={{ paddingTop: "10px" }}>
      <Grid
        container
        spacing={1}
        style={{
          padding: "30px 0 ",
        }}
      >
        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ width: "15%" }}>
            <span className={classes.labelCss}>
              {" "}
              Tip<span style={{ color: "red" }}>*</span> : &nbsp;
            </span>
          </Typography>

          {/* vishal - New - Text editor for embedd the hyperlink in the tip */}
          <div
            style={{
              width: "440px",
              height: "115px",
              borderRadius: "3px",
              padding: "0 5px",
              border: textError ? "1px solid red" : "1px solid #08456C",
              overflowY: "auto",
            }}
            onClick={() => {
              setTipState((prev) => ({
                ...prev,
                openTextEditor: isPastChallenge ? false : true,
              }));
            }}
          >
            {htmlTextValue && (
              <NewTextEditor
                readOnly
                htmlText={htmlTextValue}
                propsText={htmlTextValue}
              />
            )}
          </div>

          {/* Old - normal textfield for tip */}

          {/* <CustomTextField
            className={classes.fieldCss}
            type="text"
            placeholder="Enter Tip "
            value={TipText}
            handleChange={(prop) => (e) => {
              setTipState((prev) => ({
                ...prev,
                TipText: e.target.value,
              }));
            }}
            readOnly={isPastChallenge}
          /> */}
          {!isPastChallenge && (
            <>
              {editTipData ? (
                <SubmitCancelBtn
                  onClick={() => handleEditTipConfirmation()}
                  label={"Edit"}
                  type="submit"
                  style={{ width: "100px", marginLeft: "8px" }}
                />
              ) : (
                <SubmitCancelBtn
                  onClick={() => SaveTipsOption()}
                  label={"Add"}
                  type="submit"
                  style={{ width: "100px", marginLeft: "8px" }}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>

      {/*vishal- for open the text editor in dialog  */}
      <DialogBox
        fullWidth
        maxWidth={"md"}
        open={openTextEditor}
        message={_getTextEditor()}
        header={"Tip"}
        closeDialog={() =>
          setTipState((prev) => ({
            ...prev,
            openTextEditor: false,
          }))
        }
      />

      {/* cancel dialog */}
      <DialogBox
        signup={true}
        open={openEditConfirmPopup}
        message={getDialogMsg()}
        header={""}
        label={"No"}
        onClick={closeEditConfirmPopup}
        labelTwo={"Yes"}
        onClickTwo={handleEditTip}
        closeDisloag={() => {}}
      />
    </div>
  );
};

const styles = {
  labelCss: {
    color: "#727272",
  },
  fieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(TipCreationComponent);
