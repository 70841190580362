import React, { Component, Fragment, useEffect, useState } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import ListPageLoader from "./../../../common/ListPageLoader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Menu,
  MenuItem,
  Typography,
  Grid,
  TextareaAutosize,
  NativeSelect,
  FormControl,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import {
  addActivityFeedback,
  deleteEventParticipants,
  deleteProgramChallengeParticipants,
  deleteProgramParticipants,
  displayCertificate,
  displayRunnerCollageFile,
  downloadImage,
  generateDeliverables,
  getRegisteredEventDetails,
  modifyProgramChallengeParticipant,
  OrganizerUpdateForm,
  regenerateEmails,
  resendEmails,
} from "./../../../../apis/eventService";
import DialogBox from "./../../../common/DialogBox";
import ImageSlider from "./../../../common/ImageSlider";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { withRouter } from "react-router-dom";
import ImageDialogBox from "../../../common/ImageDialogBox";
import PlayVideo from "./../../registration/events/viewResults/PlayVideo";
import Popover from "./../../../common/Popover";
import TextField from "@material-ui/core/TextField";
import MultiSelect from "./../../../common/MultiSelect";
import SubmitCancelBtn from "./../../../common/SubmitCancelBtn";
import Snackbar from "./../../../common/Snackbar";
import Properties from "./../../../../properties/Properties";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import UploadForm from "../../registration/UploadForm";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import { connect } from "react-redux";
import {
  updateSupportDTODetails,
  updateSupportSelectedEvent,
} from "../../../../redux/supportUser";
import moment from "moment";
import RegisterProgram from "../../registration/program/RegisterProgram";
const styles = (theme) => ({
  tableCellCls: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  tableList: {
    // marginTop: "10px",
    // overflowX: "scroll",
  },
  tableHead: {
    backgroundColor: "#ced4da",
    "& th": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
    },
  },
  editIcon: {
    color: "#09456C",
  },
  iconText: {
    display: "flex",
    alignItems: "center",
    "& p": {
      marginLeft: "10px",
    },
  },
  tableRow: {
    cursor: "pointer",
    "& td": {
      fontWeight: "bold",
      fontFamily: "Rubik, sans-serif",
    },

    "&.Mui-selected": {
      backgroundColor: "#e9ecef",
      "&.MuiTableRow-hover": {
        backgroundColor: "#e9ecef",
      },
    },
  },
  textLink: {
    backgroundColor: "#ade8f4",
    color: "#023e8a",
    cursor: "pointer",
    borderRadius: "5px",
    width: "60px",
    margin: "auto",
    "&:hover": {
      color: "#03045e",
    },
  },
  urlLink: {
    textDecoration: "none",
    // backgroundColor: "#e0aaff",
    // color: "#023e8a",
    cursor: "pointer",
    borderRadius: "5px",
    width: "60px",
    margin: "auto",
    "&:hover": {
      color: "#03045e",
    },
  },
  updateForm: {
    padding: "8px 24px",

    "& > div": {
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      // width: "60%",
      "& .label": {
        color: "#000",
      },
    },
  },
  labelCss: {
    color: "#727272",
  },
  mandatoryCss: { color: "red" },
  approvalForm: {
    // width: "800px",
    // height: "auto",
    padding: "8px 24px",
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    fontFamily: "Rubik, sans-serif",
  },
  select: {
    marginRight: theme.spacing(1),
  },
  selectActivity: {
    // marginTop: theme.spacing(2),
    textAlign: "center",
    color: "#727272",
    fontSize: 18,
  },
  messageType: {
    marginBottom: theme.spacing(2),
  },
});

class OrganizerTableList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      showImage: false,
      imagePath: "",
      displayImages: [],
      showForm: false,
      showActivityUploadForm: false,
      firstName: "",
      lastName: "",
      email: "",

      editRegistration: false,
      eventId: null, // ApplicationUtil.getRunnersEventID(),
      runnerId: "",
      categoryId: "",
      anchorEl: null,
      resultAnchorEl: null,
      activityType: "",
      categoryType: "",
      shippingAddress: "",
      runCategoryOptions: [],
      cycleCategoryOptions: [],
      duathlonCategoryOptions: [],
      stepsCategoryOptions: [],
      categoryTypeOptions: [],
      activityTypeOptions: [],
      challengeType: localStorage.getItem("challengeType"),
      resultBib: false,
      resultCertificate: false,
      resultCollage: false,
      checkboxArr: [],
      isAllChecked: false,
      apiCalled: true,
      openFeedbackForm: false,
      comment: "",
      messageType: "General",
      isChallengePastDate: false,
      indeterminateCheck: false,
      headerLinkSelected: localStorage.getItem("headerLinkSelected"),
    };
  }

  // update form
  getFormComponent = (isProgram) => {
    const { classes, categoryTypeOptions, isProgramChallenge } = this.props;
    const {
      editRegistration,
      firstName,
      lastName,
      email,
      activityType,
      categoryType,
      currentCategoryTypeOptions,
      shippingAddress,
      activityTypeOptions,
      rowDetails,
      eventItems,
    } = this.state;
    let activity = "";
    const actHandleChange = (selectedValues) => {
      let catList = [];
      categoryTypeOptions.forEach((element) => {
        //having supportedActivityType
        if (element?.eventSupportedActivityType?.id) {
          if (
            // eventParticipantType === "ALL" &&
            // showParticipationType &&
            selectedValues.eventSupportedActivityTypeId &&
            selectedValues.eventSupportedActivityTypeId ===
              element.eventSupportedActivityType.id
            // &&
            // element.participantType === participationType?.value
          ) {
            if (!element.hidden) {
              catList.push(element);
            } else {
              return;
            }
          } else if (
            // eventParticipantType !== "ALL" &&
            // !showParticipationType &&
            selectedValues.eventSupportedActivityTypeId &&
            selectedValues.eventSupportedActivityTypeId ===
              element.eventSupportedActivityType.id
          ) {
            if (!element.hidden) {
              catList.push(element);
            } else {
              return;
            }
          }
        } else {
          //not having supportedActivityType
          if (this.state.activityType.type === element.activityType.type) {
            catList.push(element);
          }
        }
      });
      this.setState({
        activityType: selectedValues,
        currentCategoryTypeOptions: catList,
        categoryType: catList[0],
      });
    };
    const catHandleChange = (value) => {
      this.setState({ categoryType: value, categoryId: value.id });
    };
    if (isProgram) {
      return (
        <RegisterProgram
          modifyForm={true}
          closeModifyWindow={() => this.closeWindow()}
          participantData={{
            ...this.state.rowDetails,
            programData: this.props.eventData?.programData,
            department: {
              label: this.state.rowDetails?.department,
              value: this.state.rowDetails?.department,
            },
            climate: this.state.rowDetails?.climate
              ? {
                  label: this.state.rowDetails?.climate,
                  value: this.state.rowDetails?.climate,
                }
              : null,
            medicalCondition: {
              label: this.state.rowDetails?.medicalCondition ? "Yes" : "No",
              value: this.state.rowDetails?.medicalCondition,
            },
          }}
          updateParticipantsList={() =>
            this.props.updateParticipantsList({}, "reload")
          }
        />
      );
    }
    return (
      <div className={classes.updateForm}>
        <div>
          <span className="label">First Name</span>
          <TextField
            placeholder="John"
            value={firstName}
            variant="outlined"
            size="small"
            error={firstName ? false : true}
            onChange={(e) => {
              this.setState({ firstName: e.target.value });
            }}
          />
        </div>
        {/* {lastName && ( */}
        <div>
          <span className="label"> Last Name</span>
          <TextField
            placeholder="Doe"
            value={lastName}
            variant="outlined"
            size="small"
            error={lastName ? false : true}
            onChange={(e) => {
              this.setState({ lastName: e.target.value });
            }}
          />
        </div>
        {/* )} */}
        <div>
          <span className="label">Email</span>

          <TextField
            placeholder="abc@gmail.com"
            variant="outlined"
            size="small"
            value={email}
            error={email ? false : true}
            onChange={(e) => {
              this.setState({ email: e.target.value });
            }}
          />
        </div>
        {!isProgramChallenge && (
          <>
            {editRegistration && (
              <>
                <div>
                  <span className="label">Activity Type</span>
                  <MultiSelect
                    value={activityType}
                    options={activityTypeOptions}
                    onChange={actHandleChange}
                  />
                </div>
                <div>
                  <span className="label">Category</span>
                  <MultiSelect
                    value={categoryType}
                    options={currentCategoryTypeOptions}
                    onChange={catHandleChange}
                  />
                </div>
              </>
            )}
            <div>
              {this.props.eventData?.eventItems &&
              this.props.eventData?.eventItems.length > 0 ? (
                this.props.eventData?.eventItems.map((row, index) => (
                  <>
                    <Table aria-label="customized table">
                      <TableBody>
                        <TableRow
                          key={index}
                          style={
                            index > 0
                              ? {
                                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                                  marginLeft: "20px ",
                                  marginBottom: "100px",
                                }
                              : {
                                  marginLeft: "20px ",
                                  marginBottom: "100px",
                                  // textAlign: "center"
                                }
                          }
                        >
                          <TableCell
                            style={{
                              width: "36%",
                              // textAlign: "center"
                            }}
                          >
                            {row.itemName}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "20%",
                            }}
                          >
                            &#8377; {row.pricePerUnit ? row.pricePerUnit : 0}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "20%",
                              border: "1 px solid gray",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                                padding: "10px",
                                color: "#683594",
                              }}
                              onClick={() => this.decreaseCounter(row)}
                            >
                              -
                            </span>

                            <span>
                              {row.itemQuantity ? row.itemQuantity : 0}
                            </span>

                            <span
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                                padding: "10px",
                                color: "#683594",
                              }}
                              onClick={() => this.increaseQuantity(row)}
                            >
                              +
                            </span>
                          </TableCell>
                          <TableCell>
                            &#8377; {row.rowTotal ? row.rowTotal : 0}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    {row.itemName == "T-Shirt" && row.itemQuantity > 0 && (
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          gutterBottom
                          style={{ display: "inline" }}
                        >
                          <span style={{ color: "#727272" }}>
                            {Properties.Label_Select_Size}
                          </span>
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Grid container xs={12} spacing={1}>
                          <Grid item xs={5}>
                            <MultiSelect
                              value={this.state.selectedSize}
                              error={this.state.selectedSizeError}
                              options={this.state.sizeArray}
                              onChange={this.handleSizeChange}
                            />
                          </Grid>
                          {this.state.TShirtSizeChartFile && (
                            <Grid
                              item
                              xs={7}
                              style={{
                                paddingLeft: "5px",
                                margin: "auto",
                              }}
                            >
                              <a onClick={this.viewChart}>Size Chart</a>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </>
                ))
              ) : (
                <span></span>
              )}
            </div>
            {(this.props?.eventData?.shippingRequired ||
              this.props.eventData?.eventItems?.length > 0) && (
              <div>
                <span className="label">Shipping address</span>
                <TextField
                  placeholder="Bavdhan,Pune"
                  variant="outlined"
                  size="small"
                  value={shippingAddress}
                  error={shippingAddress ? false : true}
                  onChange={(e) => {
                    this.setState({ shippingAddress: e.target.value });
                  }}
                />
              </div>
            )}
          </>
        )}
        <div style={{ width: "30%", margin: "auto" }}>
          <SubmitCancelBtn
            label={"Update"}
            type="submit"
            onClick={() => {
              if (this.props?.isProgramChallenge) {
                this.updateProgramChallengeParticipant();
              } else {
                this.getUpdateForm();
              }
            }}
          />
        </div>
      </div>
    );
  };

  handleSizeChange = (selectedValues) => {
    var arr = [];

    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        selectedSize: selectedValues,
        selectedSizeError: false,
      });
    } else {
      this.setState({
        selectedSizeError: true,
      });
    }
  };
  increaseQuantity = (row) => {
    if (row.itemQuantity === undefined) {
      row.itemQuantity = 0;
    }
    row.itemQuantity++;

    let data = this.state.runnerData;
    let gender = data.gender;
    if (gender == "MALE" || gender == "OTHER") {
      if (row.sizeMale) {
        let sizes = row.sizeMale.split(",");
        let newSizes = [];
        sizes.forEach((element) => {
          let object = {};
          object.label = element;
          object.value = element;
          newSizes.push(object);
        });
        this.setState({ sizeArray: newSizes });
      }
    } else if (gender == "FEMALE") {
      if (row.sizeFemale) {
        let sizes = row.sizeFemale.split(",");
        let newSizes = [];
        sizes.forEach((element) => {
          let object = {};
          object.label = element;
          object.value = element;
          newSizes.push(object);
        });
        this.setState({ sizeArray: newSizes });
      }
    }

    if (row.maxQuantity < row.itemQuantity) {
      row.itemQuantity--;
      row.rowTotal =
        row.itemQuantity > 0 ? row.pricePerUnit * row.itemQuantity : 0;
      this.calculateTotal();
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "You reached max quantity.",
      });
      return row;
    } else {
      row.rowTotal =
        row.itemQuantity > 0 ? row.pricePerUnit * row.itemQuantity : 0;
      this.calculateTotal();

      return row;
    }
  };
  decreaseCounter = (row) => {
    // if (row.itemQuantity <= 1 && row.type == "IMPLICITE") {
    //   return;
    // } else {
    if (row.itemQuantity == 0) {
      row.rowTotal = 0;
      this.calculateTotal();
      return row;
    } else {
      row.itemQuantity--;
      row.rowTotal =
        row.itemQuantity > 0 ? row.pricePerUnit * row.itemQuantity : 0;
      this.calculateTotal();
      return row;
    }
    // }
  };
  calculateTotal = () => {
    let total = this.state.registrationFee;
    const { eventItems } = this.props.eventData;
    eventItems.forEach((element) => {
      if (element.rowTotal) {
        total = total + element.rowTotal;
      }
    });
  };
  getActivityUploadForm = () => {
    const {
      selectedCategory,
      eventCategoryList,
      dateError,
      date,
      showActivityDate,
      timeValidation,

      runnerId,
      eventId,
      apiCalled,
      eventDetails,
      activityTypeList,
      selectedActivityType,
    } = this.state;
    let selected_Category = selectedCategory;

    // if (apiCalled === false) {

    return (
      <UploadForm
        eventId={eventId}
        runnerId={runnerId}
        selectedCategory={{
          label:
            eventDetails?.registration?.eventRunCategories[0]?.categoryName,
          value:
            eventDetails?.registration?.eventRunCategories[0]?.categoryName,
        }}
        eventCategoryList={eventDetails?.event?.eventRunCategories}
        dateError={dateError}
        date={date}
        handleDateChange={this.handleDateChange}
        handleNavigationAfterSuccessfulUpload={
          this.handleNavigationAfterSuccessfulUpload
        }
        displayColumns={eventDetails?.event?.displayColumns.split(",")}
        timeValidation={timeValidation}
        showActivityDate={true}
        activityTypeList={activityTypeList}
        selectedActivityType={selectedActivityType}
        handleActivityTypeChange={this.handleActivityTypeChange}
      />
    );
    // }
  };
  handleActivityTypeChange = (value) => {
    this.setState({
      selectedActivityType: value,
    });
  };
  isValidDate = (d) => {
    let days = localStorage.getItem("days");

    if (days) {
      if (d) {
        let newd =
          d?.getDate() + "-" + (d?.getMonth() + 1) + "-" + d?.getFullYear();
        let start = localStorage.getItem("start");
        let end = localStorage.getItem("end");
        if (newd >= start && newd <= end) {
          this.setState({ dateError: false });
        } else {
          this.setState({ dateError: true });
        }
      } else {
        this.setState({ dateError: true });
      }
    }

    return d instanceof Date && !isNaN(d);
  };

  handleDateChange = (date, dateError) => {
    if (dateError) {
      this.setState({ dateError: true });
    } else
      this.setState({
        date: this.isValidDate(date) ? date : "",
        dateError: false,
      });
  };
  handleNavigationAfterSuccessfulUpload = () => {
    this.closeWindow();
  };

  /**
   * For modify / update the details of program challenge participant
   */
  updateProgramChallengeParticipant = () => {
    const {
      rowDetails: { id, programChallengeId },
      firstName,
      lastName,
      email,
      runnerId,
    } = this.state;

    let requestParam = { id, runnerId, programChallengeId };
    if (firstName) {
      requestParam.firstName = firstName;
    }
    requestParam.lastName = lastName ? lastName : "";
    if (email) {
      requestParam.email = email;
    }
    modifyProgramChallengeParticipant(requestParam).then((res) => {
      if (res?.data?.code === "200") {
        Snackbar.show({
          variant: "success",
          isAutoHide: true,
          preventDuplicate: true,
          message: res?.data?.verbose ?? "Updated Successfully",
        });
        this.props.updateParticipantsList([], "reload");
        this.setState({
          showForm: false,
        });
      }
      if (res?.data?.code === "409") {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: res?.data?.verbose,
        });
      }
      if (res?.data?.code === "404") {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: res?.data?.verbose,
        });
      }
      this.closeWindow();
    });
  };
  getUpdateForm = () => {
    const {
      firstName,
      lastName,
      email,
      eventId,
      runnerId,
      categoryId,
      shippingAddress,
    } = this.state;

    let itemsSelected = false;

    this.props.eventData.eventItems.forEach((element) => {
      if (element.itemQuantity > 0) {
        itemsSelected = true;
      }
    });
    let tshirt = this.props.eventData.eventItems.find(
      (el) => el.itemName == "T-Shirt"
    );
    if (tshirt?.itemQuantity > 0 && this.state.selectedSize == null) {
      this.setState({ selectedSizeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select T-Shirt size.",
      });
    } else {
      let requestParam = {
        eventId: eventId,
        runnerId: runnerId,
      };
      if (firstName) {
        requestParam.firstName = firstName;
      }
      // if (lastName) {
      requestParam.lastName = lastName ? lastName : "";
      // }
      if (email) {
        requestParam.email = email;
      }
      if (categoryId) {
        requestParam.categoryId = categoryId;
      }
      let data = [];

      if (this.props?.eventData?.eventItems) {
        this.props.eventData.eventItems.forEach((element) => {
          if (element.itemQuantity && element.itemQuantity > 0) {
            let object = {};
            object.id = element.id;
            object.itemName = element.itemName;
            object.pricePerUnit = element.pricePerUnit;
            object.quantity = element.itemQuantity;
            if (element.itemName == "T-Shirt") {
              let data = this.state.runnerData;
              let gender = data.gender;
              if (gender == "MALE" || gender == "OTHER") {
                object.sizeMale = this.state.selectedSize.value;
              }

              if (gender == "FEMALE") {
                object.sizeFemale = this.state.selectedSize.value;
              }
              // object.size = this.state.selectedSize.value;
            }
            data.push(object);
          }
        });
      }
      if (shippingAddress) {
        requestParam.shippingAddress = shippingAddress;
      }

      requestParam.items = data;

      OrganizerUpdateForm(requestParam).then((res) => {
        if (res.data.success.code === "200") {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: res.data.success.verbose,
          });

          let newData = this.props.data;
          let selectedCatIndex = this.props.categoryTypeOptions.findIndex(
            (o) => o.id === categoryId
          );

          let index = newData.findIndex((o) => o.runner.id === runnerId);
          newData[index] = {
            ...newData[index],
            runner: {
              ...newData[index].runner,
              name: firstName + " " + lastName,
              email: email,
            },
            participantPaymentTrack: {
              ...newData[index].runner,
              shippingAddress: shippingAddress,
            },
            eventRunCategory: {
              ...newData[index].eventRunCategory,
              activityType: this.state.activityType,
              category: this.props.categoryTypeOptions[selectedCatIndex]?.value, //"1K"
              categoryName:
                this.props.categoryTypeOptions[selectedCatIndex]?.label,
              // endTime: "27-09-2021 00:00:00"
              id: this.props.categoryTypeOptions[selectedCatIndex]?.id,
            },
          };
          // window.location.reload(true);
          this.setState({
            showForm: false,
          });
        }
        if (res.data.success.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res.data.success.verbose,
          });
        }
        if (res.data.success.code === "404") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res.data.success.verbose,
          });
        }
        this.closeWindow();
      });
    }
  };

  handleClick = (
    event,
    editRegistration,
    runnerId,
    shippingAddress,
    firstName,
    lastName,
    // name,
    email,
    row,
    isProgram
  ) => {
    let fName;
    let lName;
    // let fullName = name.split(" ");
    fName = firstName; // fullName[0];
    lName = lastName; //fullName[1];

    const { activityTypeOptions, categoryTypeOptions } = this.props;

    let isChallenge = false;
    isChallenge = localStorage.getItem("isChallenge");
    if (isChallenge) {
      this.props.updateSupportSelectedEvent({ id: row.eventId });

      localStorage.setItem("editView", true); ///to open setup in edit view
      this.setState({
        isChallenge: true,
        eventId: row?.eventId,
        isChallengePastDate: moment(row?.eventEndDate).isBefore(
          moment().format("YYYY-MM-DD")
        ),
      });
    } else {
      this.setState({
        eventId: ApplicationUtil.getSupportsEventID(),
      });
    }

    let rowCat = {
      id: row?.eventRunCategory?.id,
      type: row?.eventRunCategory?.activityType?.type,
      label: row?.eventRunCategory?.categoryName,
      value: row?.eventRunCategory?.categoryName,
    };
    let rowAct = {};

    if (!isProgram) {
      activityTypeOptions.map((e) => {
        if (
          row.eventRunCategory.eventSupportedActivityType.activityType.type ===
            e.type &&
          e.eventSupportedActivityTypeId ==
            row?.eventRunCategory.eventSupportedActivityType.id
        ) {
          rowAct = e;
        }
      });
    }

    if (
      this.props.eventData?.eventItems?.length > 0 &&
      row?.participantPaymentTrack?.items?.length > 0
    ) {
      let newItems = this.props.eventData?.eventItems;
      let selectedItems = row?.participantPaymentTrack?.items;
      let newSizes = [];
      let selectedSize;
      for (let j = 0; j < newItems.length; j++) {
        for (let i = 0; i < selectedItems.length; i++) {
          if (selectedItems[i].itemName === newItems[j].itemName) {
            newItems[j].itemQuantity = selectedItems[i].quantity;

            if (selectedItems[i].itemName === "T-Shirt") {
              let data = row.runner;
              let gender = data.gender;
              let sizes;
              if (gender == "MALE" || gender == "OTHER") {
                if (newItems[j].sizeMale) {
                  sizes = newItems[j].sizeMale.split(",");
                  sizes.forEach((element) => {
                    let object = {};
                    object.label = element;
                    object.value = element;
                    newSizes.push(object);
                  });
                }
              } else if (gender == "FEMALE") {
                if (newItems[j].sizeFemale) {
                  sizes = newItems[j].sizeFemale.split(",");
                  sizes.forEach((element) => {
                    let object = {};
                    object.label = element;
                    object.value = element;
                    newSizes.push(object);
                  });
                }
              }

              newSizes.map((e) => {
                if (selectedItems[i].size === e.value) {
                  selectedSize = e;
                }
              });
            }
          }
        }
      }
      this.setState({
        eventItems: newItems,
        sizeArray: newSizes,
        selectedSize: selectedSize,
      });
    }

    this.setState({
      anchorEl: event.currentTarget,
      editRegistration: editRegistration,
      runnerId: runnerId,

      shippingAddress: shippingAddress,
      firstName: fName,
      lastName: lName,
      email: email,
      activityType: !isProgram ? rowAct : "", //activityTypeOptions[0],
      categoryType: !isProgram ? rowCat : "", //categoryTypeOptions[0],
      categoryId: !isProgram ? categoryTypeOptions[0]?.id : "",
      activityTypeOptions: !isProgram ? activityTypeOptions : "",
      completeEventCategories: !isProgram ? categoryTypeOptions : "",
      welcomeSent: row?.welcomeSent,
      consentSent: row?.consentSent,
      bibGenerated: row?.bibGenerated,
    });
  };
  handleClose = () => {
    this.setState({ anchorEl: null, resultAnchorEl: null });
  };
  handleModifyEvent = () => {
    const { isChallengePastDate } = this.state;
    localStorage.removeItem("ActiveTab");
    localStorage.setItem("isChallengePastDate", isChallengePastDate);
    localStorage.setItem("sidebarSelected", "Setup");
    this.props.changeBar();
    // this.props.history.push("/organizer/list");
  };
  modifyHandleSelect = (isProgram = false) => {
    const { editRegistration, runnerId, activityType } = this.state;
    let catList = [];
    if (!isProgram) {
      const { categoryTypeOptions } = this.props;

      categoryTypeOptions.forEach((element) => {
        //having supportedActivityType
        if (element?.eventSupportedActivityType?.id) {
          if (
            // eventParticipantType === "ALL" &&
            // showParticipationType &&
            activityType.eventSupportedActivityTypeId &&
            activityType.eventSupportedActivityTypeId ===
              element.eventSupportedActivityType.id
            // &&
            // element.participantType === participationType?.value
          ) {
            if (!element.hidden) {
              catList.push(element);
            } else {
              return;
            }
          }
        } else {
          //not having supportedActivityType
          if (activityType.type === element.activityType.type) {
            catList.push(element);
          }
        }
      });
    }

    this.setState({
      anchorEl: null,
      showForm: true,
      showActivityUploadForm: false,
      editRegistration: editRegistration,
      runnerId: runnerId,
      currentCategoryTypeOptions: isProgram ? [] : catList,
      categoryType: isProgram ? [] : catList[0],
      // categoryId: row?.eventRunCategory?.id,
    });
  };

  handleActivityUpload = () => {
    const { editRegistration, eventId, runnerId } = this.state;

    this.setState(
      {
        anchorEl: null,
        showForm: false,
        runnerId: runnerId,
        selectedCategory: {},
        eventCategoryList: [],
        dateError: false,
        date: null,
        displayColumns: "",
        timeValidation: false,
        showActivityDate: true,
        apiCalled: false,
      },
      () => {
        getRegisteredEventDetails(runnerId, eventId).then((res) => {
          if (res.data?.event?.activities) {
            res.data.event.activities.map(
              (r) => (
                (r.label = r.displayName),
                (r.value = r.type),
                (r.displayColumns = r?.primaryDisplayParams
                  ?.toLowerCase()
                  .split(","))
              )
            );
            this.setState({
              activityTypeList: res.data?.event?.activities,
              selectedActivityType: res.data?.event?.activities[0],
            });
          }
          this.setState({
            apiCalled: true,
            eventDetails: res.data,
            showActivityUploadForm: true,
          });
        });
      }
    );
  };

  getDeleteParticipantWarning = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.DeleteParticipantWarning}</span>
        </Typography>
      </div>
    );
  };
  handleParticipantDelete = (isProgram) => {
    const {
      rowDetails: { programChallengeId },
      runnerId,
    } = this.state;
    //For Program Paricipants
    if (isProgram) {
      deleteProgramParticipants(runnerId).then((res) => {
        if (res?.data?.success?.code === "200") {
          this.setState({
            deleteParticipantWarningOpen: false,
            anchorEl: null,
          });
          this.props.updateParticipantsList({}, "reload");
        } else {
          this.setState({
            deleteParticipantWarningOpen: false,
            anchorEl: null,
          });
        }
      });
    } else if (
      // For Program Challenge Participants
      this.state.headerLinkSelected === "Program" &&
      this.props?.isProgramChallenge
    ) {
      deleteProgramChallengeParticipants(programChallengeId, runnerId).then(
        (res) => {
          if (res?.data?.code === "200") {
            this.setState({
              deleteParticipantWarningOpen: false,
              anchorEl: null,
            });
            this.props.updateParticipantsList([], "reload");
          } else {
            this.setState({
              deleteParticipantWarningOpen: false,
              anchorEl: null,
            });
          }
        }
      );
    } else {
      //For Event participants
      deleteEventParticipants(runnerId).then((res) => {
        if (res?.data?.success?.code === "200") {
          this.setState({
            deleteParticipantWarningOpen: false,
            anchorEl: null,
          });
          let newData = this.props.data;

          let index = newData.findIndex((o) => o.runner.id === runnerId);
          newData.splice(index, 1);
          this.props.updateParticipantsList(newData, "reload");
        } else {
          this.setState({
            deleteParticipantWarningOpen: false,
            anchorEl: null,
          });
        }
      });
    }
  };
  sendWelcomeEmail = () => {
    resendEmails("WELCOME_EMAIL");
    this.setState({ anchorEl: null });
  };
  sendConsentEmail = () => {
    resendEmails("CONSENT_EMAIL");
    this.setState({ anchorEl: null });
  };
  sendBIBEmail = () => {
    resendEmails("BIB_EMAIL");
    this.setState({ anchorEl: null, resultAnchorEl: null });
  };
  sendCertificate = () => {
    resendEmails("CERTIFICATE_EMAIL");
    this.setState({ resultAnchorEl: null });
  };
  sendCollage = () => {
    resendEmails("RUNNER_ACTIVITY_COLLAGE_EMAIL");
    this.setState({ resultAnchorEl: null });
  };
  regenerateBIB = () => {
    const { runnerId } = this.state;
    let requestParam = {
      runnerIds: [runnerId],
      regenerate: true,
    };
    generateDeliverables("BIB", requestParam);
    this.setState({ resultAnchorEl: null });
  };
  regenerateCertificate = () => {
    const { runnerId } = this.state;
    let requestParam = {
      runnerIds: [runnerId],
      regenerate: true,
    };
    generateDeliverables("CERTIFICATE", requestParam);
    this.setState({ resultAnchorEl: null });
  };
  regenerateCollage = () => {
    const { runnerId } = this.state;
    let requestParam = {
      runnerIds: [runnerId],
      regenerate: true,
    };
    generateDeliverables("COLLAGE", requestParam);
    this.setState({ resultAnchorEl: null });
  };
  getDialogComponent = () => {
    return (
      <div>
        <ImageSlider files={this.state.displayImages} />
      </div>
    );
  };
  closeWindow = () => {
    this.setState({
      showDialog: false,
      showImage: false,
      showForm: false,
      showActivityUploadForm: false,
    });
  };
  displayScreenshot = (id) => {
    let eventId = ApplicationUtil.getSupportsEventID();
    downloadImage(id, eventId).then((res) => {
      this.setState({ showDialog: true, displayImages: res });
    });
  };
  navigateToDetails = (id) => {
    localStorage.setItem("supportRunnerId", id);

    this.props.history.push("list/details");
  };

  /**
   * navigate to the navigateToProgramParticipantInfo
   * @param {*} id
   */
  navigateToProgramParticipantInfo = (id) => {
    localStorage.setItem("supportRunnerId", id);
    this.props.history.push("participants/info");
  };

  navigateToProgramChallengeParticipntDetails = (id, programChallengeId) => {
    localStorage.setItem("supportRunnerId", id);
    localStorage.setItem("programChallengeId", programChallengeId);

    this.props.history.push("list/details");
  };

  isSelected = (id) => {
    let arr = this.state.checkboxArr;
    let i = arr.findIndex((x) => x.id === id);
    if (i !== -1) {
      if (arr[i].check) return true;
    } else {
      return false;
    }
  };
  handleSelectAll = (e) => {
    const { data } = this.props;
    let val = e.target.checked;

    let arr = val
      ? this.props.data?.map((d) => ({
          id: d.runner?.id,
          check: true,
        }))
      : [];
    // data.map((d) => {
    //   arr.push({
    //     check: val,
    //     id: d.runner.id,
    //   });
    // });
    // if (arr.length > 0) {
    //   this.props.updateCheckboxArr(arr);
    // }

    this.setState({
      isAllChecked: val,
      checkboxArr: arr,
    });

    this.props.updateCheckboxArr(arr, val);
  };
  handleSelect = (e, id, row) => {
    let isChallenge = false;
    isChallenge = localStorage.getItem("isChallenge");
    if (isChallenge) {
      this.props.updateSupportSelectedEvent({ id: row.eventId });
    }
    let arr = [...this.state.checkboxArr];
    let i = arr.findIndex((x) => x.id === id);
    if (i != -1) {
      arr[i].id = id;
      arr[i].check = e.target.checked;
      this.setState({
        checkboxArr: arr,
      });
      this.props.updateCheckboxArr(arr);
    } else {
      let arr = [
        ...this.state.checkboxArr,
        {
          check: e.target.checked,
          id: id,
        },
      ];
      this.setState({
        checkboxArr: arr,
      });
      this.props.updateCheckboxArr(arr);
    }
    this.setState({
      // indeterminateCheck: this.state?.isAllChecked ?  true : false,
      isAllChecked: false,
    });
  };

  getFeedbackForm = () => {
    const {
      classes,
      // userMessages
    } = this.props;
    const { comment, messageType, activityDetails } = this.state;

    return (
      <div className={classes.approvalForm}>
        <div
          style={{
            fontWeight: 600,
          }}
        >
          {/* <div style={{ display: "flex", alignItems: "flex-start" }}> */}

          <div className={classes.messageType}>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.helpContainerCss}
            >
              <span className={classes.labelCss}>Message Type</span>
              {/* <span className={classes.mandatoryCss}>*</span> */}
            </Typography>
            <FormControl className={classes.select}>
              <NativeSelect
                className={classes.selectActivity}
                value={messageType}
                onChange={(e) => this.setState({ messageType: e.target.value })}
                style={{ width: 100 }}
              >
                {["Training", "General"]?.map((value, index) => (
                  <option value={value}>{value}</option>
                ))}
              </NativeSelect>
            </FormControl>
          </div>
          <div>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.helpContainerCss}
            >
              <span className={classes.labelCss}>Comment</span>
              <span className={classes.mandatoryCss}>*</span>
            </Typography>
            <TextareaAutosize
              value={comment}
              aria-label="feedback"
              minRows={5}
              // placeholder="Minimum 3 rows"
              style={{ width: 600 }}
              onChange={(e) => {
                this.setState({
                  comment: e.target.value,
                });
              }}
            />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
          >
            <SubmitCancelBtn
              style={{ width: "200px", marginLeft: "16px" }}
              type="submit"
              // startIcon={
              //   <AddCircle style={{ fontSize: "25px", color: "#fff" }} />
              // }
              label={"Add"}
              onClick={() => this.addComment()}
            />
          </div>
        </div>
      </div>
    );
  };
  addComment = () => {
    const {
      // activityId,
      comment,
      // eventId,
      runnerId,
      messageType,
      // activityDetails,
      // showGlobalComment,
    } = this.state;
    const { openEventId } = this.props;
    let eventId = ApplicationUtil.getSupportsEventID();
    let requestParams = {
      message: comment,
      eventId: eventId,
      recipientId: runnerId,
      recipientType: "INDIVIDUAL",
      objectId: runnerId,
    };
    if (messageType) {
      requestParams.messageType = messageType.toUpperCase();
    }
    if (openEventId) {
      requestParams.openEventId = openEventId;
    }

    if (comment?.trim() === "" || comment === undefined || comment === null) {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please add a comment",
      });
    } else {
      addActivityFeedback(requestParams)
        .then((res) => {
          // if (showGlobalComment) {
          //   let data = this.props.userMessages? this.props.userMessages :[];
          //   data.push({
          //     messageDate: moment().format("YYYY-MM-DD"),
          //     message: comment,
          //   });
          //   this.props.updateUserMessages(data);
          // } else {
          //   let newData = this.props.data;

          //   let index = newData.findIndex(
          //     (o) => o.id === this.state.activityId
          //   );
          //   let data = newData[index];
          //   data.comments.push({
          //     messageDate: moment().format("YYYY-MM-DD"),
          //     message: comment,
          //   });
          //   newData[index] = data;
          //   this.props.updateActivityList(newData);
          // }

          this.setState({
            openFeedbackForm: false,
            activityId: null,
            comment: null,
            // showGlobalComment: false,
          });
        })
        .catch((err) => {});
      ///api call
    }
  };

  /**
   * for reset the selected checkboxes after program certificate generation
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.programActionOptions?.resetCheckBoxesState !==
      prevProps.programActionOptions?.resetCheckBoxesState
    ) {
      if (this.props.programActionOptions?.resetCheckBoxesState) {
        this.setState({ isAllChecked: false, checkboxArr: [] });
        this.props.updateCheckboxArr([], false);
      }
    }
  }

  render() {
    const {
      data,
      isProgramChallenge,
      classes,
      email,
      editReg,
      activityType,
      regType,
      bib,
      distance,
      TotalRecords,
      completionTime,
      displayColumns,
      resultAction,
      screenshot,
      url,
      validInvalid,
      cert,
      collage,
      video,
      eventType,
      approved,
      startsFrom,
      endsOn,
      isSteps,
      isChallenge,
      minimumDistance,
      totalTarget,
      eventData,
      isSupport,
      isProgram,
      programActionOptions,
      generateCertificateofProgram,
    } = this.props;


    const {
      editRegistration,
      anchorEl,
      challengeType,
      resultBib,
      resultCertificate,
      resultCollage,
      canGenerateCertificate,
      canGenerateCollage,
      canGenerateVideo,
      resultAnchorEl,
      isAllChecked,
      checkboxArr,
      welcomeSent,
      consentSent,
      bibGenerated,
      currentCategoryTypeOptions,
      isChallengePastDate,
      runnerId,
    } = this.state;

    return (
      <>
        <TableContainer component={Paper} className={classes.tableList}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHead}>
                {isSupport &&
                  (!isProgram || programActionOptions?.multiCheckbox) && (
                    <TableCell component="th">
                      <Checkbox
                        checked={isAllChecked}
                        onChange={this.handleSelectAll}
                        color="primary"
                        // indeterminate = {this.state.indeterminateCheck}
                      />
                    </TableCell>
                  )}
                {isSupport && resultAction && (
                  <TableCell component="th"></TableCell>
                )}

                {isSupport && editReg && <TableCell component="th"></TableCell>}
                <TableCell
                  component="th"
                  style={{ width: "300px", maxWidth: "120px" }}
                >
                  Name
                </TableCell>
                {email && (
                  <TableCell
                    component="th"
                    style={{ width: "300px", maxWidth: "120px" }}
                  >
                    Email
                  </TableCell>
                )}
                {startsFrom && (
                  <TableCell component="th">Starts From</TableCell>
                )}
                {endsOn && <TableCell component="th">Ends On</TableCell>}
                {minimumDistance && (
                  <TableCell component="th">
                    {isSteps == "STEPS" ? (
                      <span>Daily Minimum Steps</span>
                    ) : (
                      <span>Daily Minimum Distance</span>
                    )}
                  </TableCell>
                )}
                {totalTarget && (
                  <TableCell component="th">
                    {isSteps == "STEPS" ? (
                      <span>Total Target Steps</span>
                    ) : (
                      <span>Total Target Distance</span>
                    )}
                  </TableCell>
                )}
                {activityType && <TableCell component="th">Category</TableCell>}
                {activityType && (
                  <TableCell component="th">Activity Type</TableCell>
                )}
                {isProgram && (
                  <TableCell style={{ maxWidth: "200px" }} component="th">
                    Events
                  </TableCell>
                )}
                {isProgram && (
                  <TableCell style={{ maxWidth: "200px" }} component="th">
                    Challenges
                  </TableCell>
                )}
                {isProgram && (
                  <TableCell style={{ maxWidth: "200px" }} component="th">
                    Sessions
                  </TableCell>
                )}
                {regType && (
                  <TableCell component="th">Registration Type</TableCell>
                )}

                {bib && <TableCell component="th">BIB No.</TableCell>}
                {(challengeType === "TEAM" || challengeType === "BOTH" || challengeType === "TEAM_RELAY") && !isProgram && (
                  <TableCell component="th">Team Name</TableCell>
                )}
                {displayColumns?.length > 0 && displayColumns[0] !== "" ? (
                  displayColumns?.map((column) => (
                    <TableCell component="th">
                      {column === "totalActivityDistance" &&
                        Properties.Distance_label}
                      {column === "totalActivityTime" && Properties.Time_label}
                      {column === "totalCalories" && Properties.CaloriesLabel}
                      {column === "totalSteps" && Properties.StepsLabel}
                      {column === "totalElevationGain" &&
                        Properties.Elevation_label}
                    </TableCell>
                  ))
                ) : (
                  <>
                    {distance && <TableCell component="th">Distance</TableCell>}
                    {completionTime && (
                      <TableCell component="th">Time</TableCell>
                    )}
                  </>
                )}
                {eventType && screenshot && (
                  <TableCell component="th"> Screenshot</TableCell>
                )}
                {eventType && url && (
                  <TableCell component="th">Activity URL</TableCell>
                )}
                {validInvalid && <TableCell component="th">Valid</TableCell>}
                {approved && <TableCell component="th">Approved</TableCell>}
                {cert && <TableCell component="th">Certificate</TableCell>}
                {collage && <TableCell component="th"> Collage </TableCell>}
                {video && <TableCell component="th">Video link</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => {
                const isItemSelected = this.isSelected(row?.runner?.id);

                return (
                  <TableRow
                    hover
                    key={row?.name}
                    component={Paper}
                    className={classes.tableRow}
                    selected={isAllChecked ? isAllChecked : isItemSelected}
                  >
                    {isSupport &&
                      (!isProgram ||
                        isProgramChallenge ||
                        programActionOptions?.multiCheckbox) && (
                        <TableCell component="td" align="center">
                          <Checkbox
                            checked={
                              isAllChecked ? isAllChecked : isItemSelected
                            }
                            onChange={(e) =>
                              this.handleSelect(e, row?.runner?.id, row)
                            }
                            color="primary"
                          />
                        </TableCell>
                      )}
                    {isSupport && resultAction && (
                      <TableCell component="td" align="center">
                        <IconButton>
                          <ArrowDropDownCircleOutlinedIcon
                            className={classes.editIcon}
                            onClick={(e) => {
                              let isChallenge = false;
                              isChallenge = localStorage.getItem("isChallenge");
                              if (isChallenge) {
                                this.props.updateSupportSelectedEvent({
                                  id: row?.eventId,
                                });
                                // localStorage.setItem("editView", true); ///to open setup in edit view
                                this.setState({
                                  isChallenge: true,
                                  eventId: row?.eventId,
                                  isChallengePastDate: moment(
                                    row?.eventEndDate
                                  ).isBefore(moment().format("YYYY-MM-DD")),
                                });
                              } else {
                                this.setState({
                                  eventId: ApplicationUtil.getSupportsEventID(),
                                });
                              }
                              localStorage.setItem(
                                "supportRunnerId",
                                row?.runner?.id
                              );
                              this.setState({
                                resultAnchorEl: e.currentTarget,
                                resultBib: row?.bibGenerated,
                                canGenerateCertificate:
                                  row?.canGenerateCertificate,
                                canGenerateCollage: row?.canGenerateCollage,
                                canGenerateVideo: row?.canGenerateVideo,
                                resultCertificate: row?.certificateGenerated,
                                resultCollage: row?.activityCollageGenerated,
                                runnerId: row?.runner?.id,
                              });
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    )}
                    {isSupport && editReg && (
                      <TableCell component="td" align="center">
                        <IconButton>
                          <ArrowDropDownCircleOutlinedIcon
                            onClick={(e) => {
                              localStorage.setItem(
                                "supportRunnerId",
                                row?.runner?.id
                              );

                              this.handleClick(
                                e,
                                row?.editRegistration,
                                row?.runner?.id,
                                row?.participantPaymentTrack?.shippingAddress,
                                row?.runner?.firstName,
                                row?.runner?.lastName,
                                // row?.runner?.name,
                                row?.runner?.email,
                                row,
                                isProgram
                              );
                              this.setState({
                                runnerData: row?.runner,
                                rowDetails: row,
                              });
                            }}
                            className={classes.editIcon}
                          />
                        </IconButton>
                      </TableCell>
                    )}
                    <TableCell
                      style={{ width: "300px" }}
                      component="td"
                      align="left"
                      onClick={() => {
                        if (!isProgram) {
                          if (isChallenge) {
                            this.props.updateSupportSelectedEvent({
                              id: row?.eventId,
                            });
                          }
                          // this.navigateToDetails(row?.runner?.id);
                          if (isProgramChallenge) {
                            this.navigateToProgramChallengeParticipntDetails(
                              row?.runner?.id,
                              row?.programChallengeId
                            );
                          } else {
                            this.navigateToDetails(row?.runner?.id);
                          }
                        } else if (programActionOptions) {
                          this.navigateToProgramParticipantInfo(
                            row?.runner?.id
                          );
                        }
                      }}
                    >
                      <p
                        style={{
                          paddingLeft: "20px",
                          width: "280px",
                        }}
                      >
                        {row?.runner?.name || row?.runner?.fullName}
                      </p>
                    </TableCell>
                    {email && (
                      <TableCell
                        style={{ width: "300px" }}
                        component="td"
                        align="left"
                        onClick={() => {
                          if (!isProgram) {
                            if (isChallenge) {
                              this.props.updateSupportSelectedEvent({
                                id: row?.eventId,
                              });
                            }
                            // this.navigateToDetails(row?.runner?.id);
                            if (isProgramChallenge) {
                              this.navigateToProgramChallengeParticipntDetails(
                                row?.runner?.id,
                                row?.programChallengeId
                              );
                            } else {
                              this.navigateToDetails(row?.runner?.id);
                            }
                          } else if (programActionOptions) {
                            this.navigateToProgramParticipantInfo(
                              row?.runner?.id
                            );
                          }
                        }}
                      >
                        <p
                          style={{
                            paddingLeft: "20px",
                            width: "300px",
                          }}
                        >
                          {row?.runner?.email}
                        </p>
                      </TableCell>
                    )}

                    {startsFrom && (
                      <TableCell
                        component="td"
                        align="left"
                        onClick={() => {
                          if (!isProgram) {
                            this.navigateToDetails(row?.runner?.id);
                            if (isChallenge) {
                              this.props.updateSupportSelectedEvent({
                                id: row?.eventId,
                              });
                            }
                          }
                        }}
                      >
                        <p style={{ width: "100px", paddingLeft: "10px" }}>
                          {row?.eventStartDate}
                        </p>
                      </TableCell>
                    )}
                    {endsOn && (
                      <TableCell
                        component="td"
                        align="left"
                        onClick={() => {
                          if (!isProgram) {
                            this.navigateToDetails(row?.runner?.id);
                            if (isChallenge) {
                              this.props.updateSupportSelectedEvent({
                                id: row?.eventId,
                              });
                            }
                          }
                        }}
                      >
                        <p style={{ width: "100px", paddingLeft: "10px" }}>
                          {row?.eventEndDate}
                        </p>
                      </TableCell>
                    )}

                    {minimumDistance && (
                      <TableCell component="th" align="center">
                        {row?.userMinChallengeValue > 0 ? (
                          <span
                            // style={{ paddingRight: "15px" }}
                            className={classes.tableCellCls}
                          >
                            {parseFloat(
                              Number(row?.userMinChallengeValue) / 1000
                            ).toFixed(2) + " Km"}
                          </span>
                        ) : (
                          <span
                            // style={{ paddingRight: "15px" }}
                            className={classes.tableCellCls}
                          >
                            -
                          </span>
                        )}
                      </TableCell>
                    )}
                    {totalTarget && (
                      <TableCell
                        component="th"
                        align="center" //"right"
                      >
                        {row?.totalTarget > 0 ? (
                          <span
                            // style={{ paddingRight: "15px" }}
                            className={classes.tableCellCls}
                          >
                            {isSteps == "STEPS" ? (
                              <> {row?.totalTarget}</>
                            ) : (
                              <>
                                {parseFloat(
                                  Number(row?.totalTarget) / 1000
                                ).toFixed(2) + " Km"}
                              </>
                            )}
                          </span>
                        ) : (
                          <span
                            // style={{ paddingRight: "15px" }}
                            className={classes.tableCellCls}
                          >
                            -
                          </span>
                        )}
                      </TableCell>
                    )}
                    {activityType && (
                      <TableCell
                        component="td"
                        align="left"
                        onClick={() => {
                          if (!isProgram) {
                            this.navigateToDetails(row?.runner?.id);
                            if (isChallenge) {
                              this.props.updateSupportSelectedEvent({
                                id: row?.eventId,
                              });
                            }
                          }
                        }}
                      >
                        {row?.eventRunCategory?.categoryName ? (
                          <p style={{ paddingLeft: "20px" }}>
                            {row?.eventRunCategory?.categoryName}
                          </p>
                        ) : (
                          <p>-</p>
                        )}
                      </TableCell>
                    )}
                    {activityType && (
                      <TableCell component="td" align="left">
                        <p
                          onClick={() => {
                            if (!isProgram) {
                              this.navigateToDetails(row?.runner?.id);
                              if (isChallenge) {
                                this.props.updateSupportSelectedEvent({
                                  id: row?.eventId,
                                });
                              }
                            }
                          }}
                          style={{ paddingLeft: "20px" }}
                        >
                          {`${row?.eventRunCategory?.activityType?.type} - ${row?.eventRunCategory?.eventSupportedActivityType?.displayName}`}
                        </p>
                      </TableCell>
                    )}
                    {/* Events */}
                    {isProgram && (
                      <TableCell
                        component="td"
                        align="center"
                        style={{ width: "350px" }}
                      >
                        <p
                          style={{
                            wordWrap: "break-word",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          <ListComponent list={row?.eventDTOs} />
                        </p>
                      </TableCell>
                    )}
                    {/* Challenges Added by Vishal */}
                    {isProgram && (
                      <TableCell
                        component="td"
                        align="center"
                        style={{ width: "350px" }}
                      >
                        <p
                          style={{
                            wordWrap: "break-word",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          <ListComponent
                            list={row?.programChallengeDTOs ?? []}
                          />
                        </p>
                      </TableCell>
                    )}
                    {/* Sessions */}
                    {isProgram && (
                      <TableCell
                        component="td"
                        align="center"
                        style={{ width: "350px" }}
                      >
                        <p
                          style={{
                            wordWrap: "break-word",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          <ListComponent list={row?.programSessionList} />
                        </p>
                      </TableCell>
                    )}
                    {regType && (
                      <TableCell component="td" align="center">
                        {row?.imported ? (
                          <p style={{ color: "red", fontWeight: 800 }}>
                            {"OFFLINE"}
                          </p>
                        ) : (
                          <p style={{ color: "green", fontWeight: 800 }}>
                            {"ONLINE"}
                          </p>
                        )}
                      </TableCell>
                    )}
                    {bib && (
                      <TableCell component="td" align="center">
                        <p
                          onClick={() => {
                            if (!isProgram) {
                              this.navigateToDetails(row?.runner?.id);
                              if (isChallenge) {
                                this.props.updateSupportSelectedEvent({
                                  id: row?.eventId,
                                });
                              }
                            }
                          }}
                        >
                          {row?.bibNumber}
                        </p>
                      </TableCell>
                    )}
                    {(challengeType === "TEAM" || challengeType === "BOTH" || challengeType === "TEAM_RELAY") && !isProgram && (
                      <TableCell component="td" align="left">
                        {row?.runner?.isTeamMember && (
                          <p
                            style={{ paddingLeft: "20px" }}
                            onClick={() => {
                              if (!isProgram) {
                                this.navigateToDetails(row?.runner?.id);
                                if (isChallenge) {
                                  this.props.updateSupportSelectedEvent({
                                    id: row?.eventId,
                                  });
                                }
                              }
                            }}
                          >
                            {row?.teamName}
                          </p>
                        )}
                      </TableCell>
                    )}
                    {displayColumns?.length > 0 && displayColumns[0] !== "" ? (
                      displayColumns.map((column) => (
                        <TableCell
                          component="td"
                          align="center"
                          onClick={() => {
                            if (!isProgram) {
                              this.navigateToDetails(row?.runner?.id);
                              if (isChallenge) {
                                this.props.updateSupportSelectedEvent({
                                  id: row?.eventId,
                                });
                              }
                            }
                          }}
                        >
                          {column === "totalActivityDistance" &&
                            row?.runnerActivity && (
                              <>
                                {row?.runnerActivity?.totalActivityDistance ? (
                                  <p>
                                    {parseFloat(
                                      Number(
                                        row?.runnerActivity
                                          ?.totalActivityDistance
                                      ) / 1000
                                    ).toFixed(2) + " Km"}
                                  </p>
                                ) : (
                                  <p>-</p>
                                )}
                              </>
                            )}
                          {column === "totalActivityTime" && (
                            <>
                              {row?.runnerActivity?.totalActivityTime ? (
                                <p>{row?.runnerActivity?.totalActivityTime}</p>
                              ) : (
                                <p>-</p>
                              )}
                            </>
                          )}
                          {column === "totalCalories" && (
                            <>
                              {row?.runnerActivity?.totalCalories ? (
                                <p>
                                  {
                                    row?.runnerActivity?.totalCalories
                                    // ? row?.runnerActivity?.totalCalories > -1
                                    // : "-"
                                  }
                                </p>
                              ) : (
                                <p>-</p>
                              )}
                            </>
                          )}
                          {column === "totalSteps" && (
                            <>
                              {row?.runnerActivity?.totalSteps ? (
                                <p>
                                  {
                                    row?.runnerActivity?.totalSteps
                                    // ? row.totalSteps > -1
                                    // : "-"
                                  }
                                </p>
                              ) : (
                                <p>-</p>
                              )}
                            </>
                          )}
                          {column === "totalElevationGain" && (
                            <>
                              {row?.runnerActivity?.totalElevationGain ? (
                                <p>
                                  {
                                    row?.runnerActivity?.totalElevationGain
                                    // ? row.totalElevationGain > -1
                                    // : "-"
                                  }
                                </p>
                              ) : (
                                <p>-</p>
                              )}
                            </>
                          )}
                        </TableCell>
                      ))
                    ) : (
                      <>
                        {distance && (
                          <TableCell
                            component="td"
                            align="center"
                            onClick={() => {
                              if (!isProgram) {
                                this.navigateToDetails(row?.runner?.id);
                                if (isChallenge) {
                                  this.props.updateSupportSelectedEvent({
                                    id: row?.eventId,
                                  });
                                }
                              }
                            }}
                          >
                            {row?.runnerActivity && (
                              <>
                                {row?.runnerActivity?.totalActivityDistance ? (
                                  <p>
                                    {parseFloat(
                                      Number(
                                        row?.runnerActivity
                                          ?.totalActivityDistance
                                      ) / 1000
                                    ).toFixed(2) + " Km"}
                                  </p>
                                ) : (
                                  <p>-</p>
                                )}
                              </>
                            )}
                          </TableCell>
                        )}
                        {completionTime && (
                          <TableCell
                            component="td"
                            align="center"
                            onClick={() => {
                              if (!isProgram) {
                                this.navigateToDetails(row?.runner?.id);
                                if (isChallenge) {
                                  this.props.updateSupportSelectedEvent({
                                    id: row?.eventId,
                                  });
                                }
                              }
                            }}
                          >
                            <>
                              {row?.runnerActivity?.totalActivityTime ? (
                                <p>{row?.runnerActivity?.totalActivityTime}</p>
                              ) : (
                                <p>-</p>
                              )}
                            </>
                          </TableCell>
                        )}
                      </>
                    )}

                    {eventType && screenshot && (
                      <TableCell component="td" align="center">
                        {row?.isActivityScreenshotsAvailable ? (
                          <p
                            className={classes.textLink}
                            onClick={() => {
                              this.displayScreenshot(row?.runner?.id);
                            }}
                          >
                            View
                          </p>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    )}
                    {eventType && url && (
                      <TableCell component="td" align="center">
                        {row?.activityReceived &&
                        row?.runnerActivity?.activityUrl ? (
                          <p className={classes.urlLink}>
                            <a
                              href={row?.runnerActivity?.activityUrl}
                              target="_blank"
                            >
                              <LinkIcon />
                            </a>
                          </p>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    )}
                    {validInvalid && (
                      <TableCell component="td" align="center">
                        {row?.runnerActivity ? (
                          row?.runnerActivity?.valid === true ? (
                            <CheckCircleIcon style={{ color: "#40916c" }} />
                          ) : row?.runnerActivity?.validationReason ? (
                            <Popover
                              title={
                                <CancelIcon style={{ color: "#f94144" }} />
                              }
                              message={row?.runnerActivity?.validationReason}
                            />
                          ) : (
                            <CancelIcon style={{ color: "#f94144" }} />
                          )
                        ) : (
                          <p>{"-"}</p>
                        )}
                      </TableCell>
                    )}
                    {approved && (
                      <TableCell component="td" align="center">
                        {row?.runnerActivity ? (
                          row?.runnerActivity?.approved ? (
                            <CheckCircleIcon style={{ color: "#40916c" }} />
                          ) : (
                            <CancelIcon style={{ color: "#f94144" }} />
                          )
                        ) : (
                          <p>{"-"}</p>
                        )}
                      </TableCell>
                    )}
                    {cert && (
                      <TableCell component="td" align="center">
                        {row?.certificateGenerated ? (
                          <p
                            className={classes.textLink}
                            onClick={() => {
                              if (!isProgram) {
                                let isChallenge = false;
                                isChallenge =
                                  localStorage.getItem("isChallenge");
                                if (isChallenge) {
                                  this.props.updateSupportSelectedEvent({
                                    id: row?.eventId,
                                  });
                                }
                                displayCertificate(row?.runner?.id).then(
                                  (res) => {
                                    this.setState({
                                      dialogTitle: "Certificate",
                                      showImage: true,
                                      imagePath: `data:image/png;base64,${res.imageData}`,
                                    });
                                  }
                                );
                              }
                            }}
                          >
                            View
                          </p>
                        ) : (
                          <p>-</p>
                        )}
                      </TableCell>
                    )}
                    {collage && (
                      <TableCell component="td" align="center">
                        {row?.activityCollageGenerated ? (
                          <p
                            className={classes.textLink}
                            onClick={() => {
                              if (!isProgram) {
                                let isChallenge = false;
                                isChallenge =
                                  localStorage.getItem("isChallenge");
                                if (isChallenge) {
                                  this.props.updateSupportSelectedEvent({
                                    id: row?.eventId,
                                  });
                                }
                                displayRunnerCollageFile(row?.runner?.id).then(
                                  (res) => {
                                    this.setState({
                                      dialogTitle: "Collage",
                                      showImage: true,
                                      imagePath: `data:image/png;base64,${res.imageData}`,
                                    });
                                  }
                                );
                              }
                            }}
                          >
                            View
                          </p>
                        ) : (
                          <p>-</p>
                        )}
                      </TableCell>
                    )}
                    {video && (
                      <TableCell component="td" align="center">
                        {row?.isVideoGenerated &&
                        row?.runnerActivity?.videoStreamUrl ? (
                          <PlayVideo
                            url={row?.runnerActivity?.videoStreamUrl}
                            showIcon
                          />
                        ) : (
                          <p>-</p>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          // className="header__user--menu"
          style={{ marginTop: "40px" }}
          transformOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          // anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {programActionOptions?.certificateGeneration && (
            <MenuItem
              onClick={() => {
                generateCertificateofProgram &&
                  generateCertificateofProgram(runnerId);

                this.setState({ anchorEl: null });
              }}
            >
              Generate Certificate
            </MenuItem>
          )}
          {isChallenge && (
            <MenuItem
              onClick={() => {
                this.handleModifyEvent();
              }}
            >
              Modify Event
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              this.modifyHandleSelect(isProgram);
            }}
          >
            Modify
          </MenuItem>
          {!isProgram && !isProgramChallenge && (
            <MenuItem
              onClick={() => {
                this.handleActivityUpload();
              }}
            >
              Add Activity
            </MenuItem>
          )}
          {(eventData?.freeEvent ||
            isSupport ||
            (isChallenge && isSupport)) && (
            <MenuItem
              onClick={() => {
                this.setState({
                  deleteParticipantWarningOpen: true,
                });
              }}
            >
              Delete Participant
            </MenuItem>
          )}

          {welcomeSent && !isProgram && (
            <MenuItem onClick={this.sendWelcomeEmail}>
              Resend Welcome Email
            </MenuItem>
          )}
          {bibGenerated && !isProgram && (
            <MenuItem onClick={this.sendBIBEmail}>Resend Bib Email</MenuItem>
          )}
          {consentSent && !isProgram && (
            <MenuItem onClick={this.sendConsentEmail}>
              Resend Consent Email
            </MenuItem>
          )}
          {!isProgram && !isProgramChallenge && (
            <MenuItem
              onClick={() => {
                this.handleClose();
                this.setState({
                  openFeedbackForm: true,
                });
              }}
            >
              {"Send Message"}
            </MenuItem>
          )}
        </Menu>

        {/* Result actions */}
        <Menu
          anchorEl={resultAnchorEl}
          keepMounted
          open={Boolean(resultAnchorEl)}
          onClose={this.handleClose}
          // className="header__user--menu"
          style={{ marginTop: "40px" }}
        >
          {resultBib ? (
            <>
              <MenuItem onClick={this.regenerateBIB}>Regenerate BIB</MenuItem>
              <MenuItem onClick={this.sendBIBEmail}>Resend BIB Email</MenuItem>
            </>
          ) : (
            <MenuItem onClick={this.regenerateBIB}>Generate BIB</MenuItem>
          )}
          {resultCertificate ? (
            <>
              <MenuItem onClick={this.regenerateCertificate}>
                Regenerate Certificate
              </MenuItem>
              <MenuItem onClick={this.sendCertificate}>
                Resend Certificate
              </MenuItem>
            </>
          ) : (
            <>
              {canGenerateCertificate && (
                <MenuItem onClick={this.regenerateCertificate}>
                  Generate Certificate
                </MenuItem>
              )}
            </>
          )}
          {resultCollage ? (
            <>
              <MenuItem onClick={this.regenerateCollage}>
                Regenerate Collage
              </MenuItem>
              <MenuItem onClick={this.sendCollage}>Resend Collage</MenuItem>
            </>
          ) : (
            <>
              {canGenerateCollage && (
                <MenuItem onClick={this.regenerateCollage}>
                  Generate Collage
                </MenuItem>
              )}{" "}
            </>
          )}
        </Menu>
        <DialogBox
          open={this.state.showDialog}
          message={this.getDialogComponent()}
          onClick={() => this.closeWindow()}
          closeDisloag={() => this.closeWindow()}
        />
        <DialogBox
          fullWidth
          maxWidth={"md"}
          open={this.state.showForm}
          header={"Update Participant"}
          message={this.getFormComponent(isProgram)}
          onClick={() => this.closeWindow()}
          // closeDisloag={}
          // closeDisloag={() => this.closeWindow()}
        />

        {/*  */}
        <DialogBox
          fullWidth
          maxWidth={"md"}
          open={this.state.showActivityUploadForm}
          header={"Upload Activity"}
          message={this.getActivityUploadForm()}
          onClick={() => this.closeWindow()}
          closeDisloag={() => this.closeWindow()}
        />

        <ImageDialogBox
          title={this.state.dialogTitle}
          open={this.state.showImage}
          imagePath={this.state.imagePath}
          closeDisloag={() => this.closeWindow()}
        />

        {/* delete participant warning */}
        <DialogBox
          signup={true}
          open={this.state.deleteParticipantWarningOpen}
          message={this.getDeleteParticipantWarning()}
          header={this.state.dialogHeader}
          label={"No"}
          isSmall={true}
          onClick={() => {
            this.setState({
              deleteParticipantWarningOpen: false,
              anchorEl: null,
            });
          }}
          labelTwo={"Yes"}
          onClickTwo={() => {
            // this.handleActivityDelete();

            this.handleParticipantDelete(isProgram);
          }}
          closeDisloag={() => {
            this.setState({
              deleteParticipantWarningOpen: false,
              anchorEl: null,
            });
          }}
        />
        {/* send message */}
        <DialogBox
          fullWidth
          maxWidth={"md"}
          header={"Add Feedback"}
          open={this.state.openFeedbackForm}
          message={this.getFeedbackForm()}
          onClick={() => {
            this.setState({
              openFeedbackForm: false,
            });
          }}
          closeDisloag={() => {
            this.setState({
              openFeedbackForm: false,
            });
          }}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    supportDTO: state.supportUser.supportDTO,
    supportSelectedEvent: state.supportUser.supportSelectedEvent,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateSupportDTODetails,
    updateSupportSelectedEvent,
  })
)(OrganizerTableList);

function ListComponent({ list }) {
  const [show, setShow] = useState(false);

  return (
    <div>
      {list?.length > 0 ? (
        <>
          <span>{`${list[0]?.name}${list.length > 1 ? "," : ""}`}</span>
          {show && (
            <span>
              {list.slice(1).map((item, index) => (
                <span style={{ display: "block" }}>{`${item?.name}${
                  index === list.length - 2 ? "" : ","
                }`}</span>
              ))}
            </span>
          )}
          {list.length > 1 && (
            <span
              onClick={() => setShow(!show)}
              style={{
                cursor: "pointer",
                display: "block",
                fontSize: "14px",
                color: "#3788d8",
                textDecoration: "underline",
              }}
            >
              Show {show ? "less" : "more"}
            </span>
          )}
        </>
      ) : (
        <span>{"-"}</span>
      )}
    </div>
  );
}
