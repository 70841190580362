import React, { Component } from "react";
import ApplicationUtil from "../../utils/ApplicationUtil";
import CompleteEventListView from "./registration/events/CompleteEventListView";
import EventListView from "./registration/events/EventListView";

class HomeScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: ApplicationUtil.getRunnerDetails(),
    };
  }

  //selectedEvent ID gets null , but for navigating using backhandling of browser we need selectedEvent ID to show previous screen.
  // componentDidMount() {
  //   ApplicationUtil.handleRemoveSelectedEvent();
  // }

  render() {
    return (
      <div>
        {this.state.loggedIn?.token ? (
          <EventListView />
        ) : (
          <CompleteEventListView />
        )}
      </div>
    );
  }
}

export default HomeScreen;
