import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import {
  getImgPathForProgramParticipantCertificate,
  getProgramParticipantInfo,
} from "../../../../../apis/eventService";
import "../../../../../styles/organizer.css";
import Breadcrumbs from "../../../../common/Breadcrumbs";
import OrganizerCommonHeader from "../OrganizerCommonHeader";
import DynamicParticipantInfo from "./DynamicParticipantInfo";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import moment from "moment";

/**
 * -Vishal
 * Created to show the program - particular participant details
 * imported dynamic component - DynamicParticipantInfo - to show the details in dynamic way
 * @param {*} param0
 * @returns
 */
const OrganizerProgramParticipantInfo = ({ classes }) => {
  const [tabWiseData, setTabWiseData] = useState([]);
  const [commonData, setCommonData] = useState(null);
  const [goodiesData, setGoodiesData] = useState(null);
  const tabsArr = ["Personal", "Participation info", "Goodies"];

  /**
   * -Vishal
   * Created for Customize the data for passing the dynamic component and show
   * Customized data structure with labelKey as per requirement of dynamic component
   * @param {*} _data
   */
  const handleCustomizedDataToSend = (_data) => {
    //labelKey and tabsArr field should match to show the data
    let personalData = {
      labelKey: "Personal",
      details: {
        name: _data?.runner?.fullName,
        age: _data?.runner?.age,
        gender: _data?.runner?.gender,
        email: _data?.runner?.email,
        city: _data?.runner?.city,
        address: _data?.runner?.runnerAddress,
        country: _data?.runner?.country,
      },
    };
    let participationInfoData = {
      labelKey: "Participation info",
      details: {
        "registration date": moment(
          _data?.programRegistrationDate,
          "DD-MM-YYYY HH:mm:ss"
        ).format("DD-MM-YYYY"),
        consent: _data?.runnerConsents,
      },
    };
    setCommonData(personalData?.details);
    setTabWiseData([personalData, participationInfoData]);
  };

  /**
   * -Vishal
   * Fetch the data based on the participant id and program id
   * @param {*} participantId
   */
  const fetchTheParticipantData = (programId, participantId) => {
    getProgramParticipantInfo(programId, participantId).then((res) => {
      if (res?.data && res?.data?.programParticipant?.length > 0) {
        const _data = res?.data?.programParticipant[0];
        if (_data) {
          handleCustomizedDataToSend(_data);
        }
      }
    });
  };

  /**
   * fetching the certificate
   */
  const fetchParticipantCertificate = (programId, participantId) => {
    // Call for certificate img
    getImgPathForProgramParticipantCertificate(programId, participantId).then(
      (res) => {
        if (res) {
          let _data = {
            labelKey: "Goodies",
            details: { label: "Certificate", details: res },
          };
          setGoodiesData(_data);
        }
      }
    );
  };

  useEffect(() => {
    let supportRunnerId = localStorage.getItem("supportRunnerId");
    let programDetails = ApplicationUtil.getSupportsProgramDetails();
    let programId = programDetails?.id;
    fetchTheParticipantData(programId, supportRunnerId);
    fetchParticipantCertificate(programId, supportRunnerId);
  }, []);

  return (
    <div className="organizer">
      <OrganizerCommonHeader />
      <div style={{ marginTop: "60px", paddingInline: "20px" }}>
        <Breadcrumbs />
        <DynamicParticipantInfo
          tabsArr={tabsArr}
          tabsDetails={
            goodiesData ? [...tabWiseData, goodiesData] : tabWiseData
          }
          // goodiesData={goodiesData}
          commonData={commonData}
        />
      </div>
    </div>
  );
};

const styles = (theme) => ({
  // bottomMargin: {
  //   marginBottom: "15px",
  // },
});
export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(OrganizerProgramParticipantInfo);
