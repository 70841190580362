import React, { useEffect, useState } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { getChallengeParticipantsDetails } from "../../../../../apis/challengeService";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import { isMobile } from "react-device-detect";
import { Grid, Tab, Tabs, Typography } from "@material-ui/core";
import Properties from "../../../../../properties/Properties";
import UserBasedActivityList from "./UserBasedActivityList";

function OrganizerChallengeParticipantDetails({ classes }) {
  const [runnerDetails, setRunnerDetails] = useState(null);
  const [data, setData] = useState([]);
  const [activityList, setActivityList] = useState(null);
  const [ActiveTabIndex, setActiveTabIndex] = useState(0);
  const [activeTabTitle, setActiveTabTitle] = useState(
    Properties.PersonalDataLabel
  );
  const [isProgramChallenge, setIsProgramChallenge] = useState(false);
  const [isPastChallenge, setIsPastChallenge] = useState(false);

  useEffect(() => {
    onPageLoad();
  }, [isProgramChallenge]);

  const onPageLoad = () => {
    let challengeDetails = ApplicationUtil.getSupportsEventsDetails();
    let setPermissions = JSON.parse(localStorage.getItem("supportPermissions"));
    setIsPastChallenge(!setPermissions?.canModifyProgram);
    const isProgramChallenge =
      challengeDetails?.programChallengeType === "HYDRATION" ||
      challengeDetails?.programChallengeType === "NO_UNHEALTHY_EATING" ||
      challengeDetails?.programChallengeType === "ZERO_SUGAR";
    if (isProgramChallenge) {
      getChallengeParticipantsDetails().then((res) => {
        let activityList = null;
        setRunnerDetails(res?.data?.runner);

        if (res?.data?.challengeParticipantResponse) {
          activityList = res.data?.challengeParticipantResponse;
          activityList = activityList.sort((a, b) => {
            return a.viewDate - b.viewDate;
          });
        }
        setData(res.data);
        setActivityList(activityList);
      });
    }
  };

  const handleTabChange = (event, value) => {
    setActiveTabIndex(value);
    setActiveTabTitle(event.target.innerHTML);
  };

  const showPersonalData = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={6} className={classes.bottomMargin}>
            <Typography className={classes.displayTitle}>
              {Properties.Name_label}
            </Typography>
            <Typography className={classes.displayInfo}>
              {data?.runner?.firstName + " " + data?.runner?.lastName}
            </Typography>
          </Grid>
          {data?.runner?.age > 0 && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_age}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data?.runner?.age}
              </Typography>
            </Grid>
          )}
          {data?.runner?.gender && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_Gender}
              </Typography>
              <Typography className={classes.displayInfo}>
                {ApplicationUtil.getCamelCasing(data?.runner?.gender)}
              </Typography>
            </Grid>
          )}
          {data?.runner?.email && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_Email}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data?.runner?.email}
              </Typography>
            </Grid>
          )}
          {data?.runner?.city && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_City}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data?.runner?.city}
              </Typography>
            </Grid>
          )}
          {data?.runner?.runnerAddress && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.address_label}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data?.runner?.runnerAddress}
              </Typography>
            </Grid>
          )}
          {data?.runner?.country && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_Country}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.runner.country}
              </Typography>
            </Grid>
          )}
          {data?.runner?.countryCode && data?.runner?.contactNumber && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_ContatNumber}
              </Typography>
              <Typography className={classes.displayInfo}>
                {"+" +
                  data?.runner?.countryCode +
                  " " +
                  data?.runner?.contactNumber}
              </Typography>
            </Grid>
          )}
        </Grid>
      </>
    );
  };
  const showChallengeRegistrationData = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={6} className={classes.bottomMargin}>
            <Typography className={classes.displayTitle}>
              {Properties.RegistrationTypeLabel}
            </Typography>
            <Typography className={classes.displayInfo}>
              {data.imported ? Properties.OfflineLabel : Properties.OnlineLabel}
            </Typography>
          </Grid>
          {data.registrationDate && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.RegistrationDateLabel}
              </Typography>
              <Typography className={classes.displayInfo}>
                {ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                  data.registrationDate
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      </>
    );
  };
  const showActivityData = () => {
    return (
      <>
        <Grid container item xs={12} md={12} sm={12}>
          <Grid item xs={4} className={classes.bottomMargin}>
            <Typography className={classes.displayTitle}>
              Total Sustained
            </Typography>
            <Typography className={classes.displayInfo}>
              {data?.totalPositiveResponse}
            </Typography>
          </Grid>

          <Grid item xs={4} className={classes.bottomMargin}>
            <Typography className={classes.displayTitle}>
              Total Not Sustained
            </Typography>
            <Typography className={classes.displayInfo}>
              {data?.totalNegativeResponse}
            </Typography>
          </Grid>

          <Grid item xs={4} className={classes.bottomMargin}>
            <Typography className={classes.displayTitle}>
              Total Not Responded
            </Typography>
            <Typography className={classes.displayInfo}>
              {data?.totalNotRespondedCount}
            </Typography>
          </Grid>
        </Grid>

        <Grid>
          {activityList?.length > 0 ? (
            <UserBasedActivityList
              data={activityList}
              setIsProgramChallenge={setIsProgramChallenge}
              isPastChallenge={isPastChallenge}
            />
          ) : (
            <div
              style={{
                color: "gray",
                margin: "0 auto",
                fontSize: "25px",
              }}
            >
              <p>No Activities available</p>
            </div>
          )}
        </Grid>
      </>
    );
  };

  //   const showResults = () => {
  //     return "No Goodies";
  //   };

  return (
    <div className={classes.mainBody}>
      {data && data?.runner && (
        <Grid
          style={{ width: "95%", margin: "auto" }}
          className={classes.mainCss}
        >
          <Grid>
            <Typography className={classes.runnerNameCss}>
              {data?.runner?.firstName + " " + data?.runner?.lastName}
            </Typography>
          </Grid>

          <Tabs
            value={ActiveTabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            variant={isMobile ? "scrollable" : "fullWidth"}
            scrollButtons={isMobile ? "on" : "off"}
          >
            <Tab
              label={Properties.PersonalDataLabel}
              className={classes.textTransformCss}
            />

            <Tab
              label={Properties.RegistrationDataLabel}
              className={classes.textTransformCss}
            />

            <Tab
              label={Properties.ActivityDataLabel}
              className={classes.textTransformCss}
            />

            {/* <Tab
              label={Properties.GoodiesLabel}
              className={classes.textTransformCss}
            /> */}
          </Tabs>

          <div className={classes.root}>
            {activeTabTitle === Properties.PersonalDataLabel &&
              showPersonalData()}

            {activeTabTitle === Properties.RegistrationDataLabel &&
              showChallengeRegistrationData()}

            {activeTabTitle === Properties.ActivityDataLabel &&
              showActivityData()}

            {/* {activeTabTitle === Properties.GoodiesLabel && showResults()} */}
          </div>
        </Grid>
      )}
    </div>
  );
}

const styles = (theme) => ({
  mainBody: {
    marginTop: "0px",
    overscrollBehavior: "auto contain",
  },
  textTransformCss: {
    textTransform: "none",
    fontSize: "22px",
    fontWeight: "bold",
  },
  displayTitle: {
    color: "rgb(114, 114, 114)",
    fontSize: isMobile ? "16px" : "20px",
  },
  displayInfo: {
    color: "rgb(40, 131, 181)",
    fontSize: isMobile ? "20px" : "24px",
  },
  runnerNameCss: {
    color: "rgb(40, 131, 181)",
    fontWeight: "600",
    fontSize: "34px",
  },
  bottomMargin: {
    marginBottom: "15px",
  },
});

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(OrganizerChallengeParticipantDetails);
