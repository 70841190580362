import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateUserDetails,
  updateUserSelectedEvent,
  updateUserSelectedProgram,
} from "../../../redux/user";

import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { CustomTextField } from "../../common/CustomTextField";
import { validateEmail, validatePassword } from "./../../../utils/Validator";
import Grid from "@material-ui/core/Grid";
import Properties from "./../../../properties/Properties";
import { Container, Radio, Typography } from "@material-ui/core";
import SubmitCancelBtn from "../../common/SubmitCancelBtn";
import logo from "./ndLogo.png";
//import logo from '../../../nd.png';
import {
  loginCheck,
  loginWithOTP,
  removeDuplicateAccounts,
  verifyOTP,
  getOrganizerLogo,
} from "./../../../apis/loginService";
import LoginUtil from "./LoginUtil";

import {
  registerEvent,
  getDecodedUrl,
  getEventDetailsWithDistkey,
  userRegisteredForEvent,
  getProgramDetailsWithDistkey,
  userRegisteredForProgram,
} from "./../../../apis/eventService";

import Snackbar from "../../common/Snackbar";
import OtpInput from "react-otp-input";
import { loading } from "../../common/Spinner";
import { IconCalendarBlue, YoutubeIcon } from "../../common/SvgIcon";
import { getChallengeDetailsWithOrgkey } from "../../../apis/challengeService";
import DialogBox from "../../common/DialogBox";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import getUserDetails from "../../../apis/userService";
import { withRouter } from "react-router-dom";

import { GoogleLogin, GoogleLogout } from "react-google-login";
import Header from "../../common/Header";
import MultiSelect from "../../common/MultiSelect";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ApplicationConstants from "../../../constants/ApplicationConstants";
import moment from "moment";
import editUserProfile from "../../../apis/editProfile";
import AppToolBar from "../../common/AppToolBar";
import httpService from "../../../apis/httpService";

const styles = (theme) => ({
  mainPanel: {
    width: "40%",
    // boxShadow: "0px 1px 9px 5px rgba(0,0,0,0.25)",
    boxShadow: "0px 0px 7px 0px rgba(0,0,0,0.25)",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "97%",
      margin: "auto",
      borderRadius: "0px",
    },
  },
  innerMainPannel: {
    width: "100%",
    height: "109px",
    marginTop: "10px",
    paddingTop: "3%",
    justifyContent: "center",
    textAlign: "center",
    paddingBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10%",
    },
  },
  logosOuterDiv: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    alignItems: "center",
    // height: "15vh",
    width: "100%",
    flexBasis: "content",
    overFlow: "hidden",
    [theme.breakpoints.down("sm")]: {
      gap: "5px",
    },
  },
  logosCss: {
    width: "100%",
    maxHeight: "75px",
    // height: "100%",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "55px",
    },
    // width: "180px",
    // objectFit: "contain",
    // width: "50px",
    // paddingLeft: 0,
    // // marginTop: "10px",
    // [theme.breakpoints.down("sm")]: {
    //   height: "70%",
    // },
  },
  otpInput: {
    padding: "0 10px",
    margin: 0,
    marginBottm: "1px solid black",
  },
  logo: {
    height: "100px",
    width: "180px",
    objectFit: "contain",
  },
  emailField: {
    border: "1px solid #08456C",
    height: "45px",
    maxHeight: "45px",
    minHeight: "45px",
    width: "100%",
    backgroundColor: "#D1D5DB",
  },
  inputField: {
    border: "1px solid #08456C",
    height: "45px",
    maxHeight: "45px",
    minHeight: "45px",
    width: "360px",
  },
  labelColor: {
    color: "#727272",
  },
  required: {
    color: "red",
  },
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  googleFormContainer: {
    // paddingRight: theme.rightSpacing,
    // paddingLeft: theme.leftSpacing,
    width: "30%",
    // height:'80vh',
    boxShadow: "0px 1px 9px 5px rgba(0,0,0,0.25)",
    [theme.breakpoints.down("sm")]: {
      width: "97%",
      marginTop: "15%",
    },
    marginTop: "5%",
    padding: 10,
    // boxShadow: '0px 0px 12px 2px rgba(3,2,2,1)',
    // border: '1px solid blue',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  fieldLayout: {
    marginBottom: "5%",
    width: "100%",
  },
});

class LoginContainer extends Component {
  state = {
    emailError: false,
    passwordError: false,
    open: false,

    passwordFieldVisibility: true,
    showLoginScreen: true,
    showOTPForm: false,
    showPasswordForm: false,
    newlogo: null,
    orgLogos: [],
    distKey: "",
    usedFirefox: false,
    openActivitySync: false,
    byEmail: true,
    byMobile: false,
    duplicateUsers: [],
    selectedUser: null,
    openRemoveDuplicates: false,
    dialogHeader: "",
    dailogMsg: "",
    otpSeconds: 60,
    resendOtpDisabled: true,
    googleEmail: "",
    gender: "",
    genderList: [
      { label: "Male", value: "MALE" },
      { label: "Female", value: "FEMALE" },
      { label: "Other", value: "OTHER" },
    ],
    genderError: false,
    dob: "",
    dobError: false,
    height: "",
    heightError: false,
    weight: "",
    weightError: false,
    country: "",
    countryError: false,
    countryList: ApplicationConstants.countries,
    showHeightWeightOnSignup: false,
    showLoginFullScreen: true,
    showGoogleSignInForm: false,
    googleSignInUserDetails: null,
  };

  componentDidMount() {
    loading(false);
    document.title = Properties.Page_Title_Login;
    let browser = navigator.userAgent.indexOf("Firefox");
    if (browser !== -1) this.setState({ usedFirefox: true });
    let eventDetails = ApplicationUtil.getRunnersEventsDetails();
    let existingEventId = ApplicationUtil.getRunnersEventID();
    let programDetails = ApplicationUtil.getRunnersProgramDetails();
    let search = window.location.href;
    const urlParams = new URLSearchParams(window.location.search);
    let isProgram = Boolean(urlParams.get("isprogram"));
    let distKey = search.split("distkey=")[1]?.split("&")[0];
    if (!distKey) distKey = search.split("distKey=")[1]?.split("&")[0];
    localStorage.removeItem("newUserDetails");
    if (ApplicationUtil.isuserLoggedIn("runner")) {
      this.props.history.push("/");
    } else {
      if (distKey) {
        if (programDetails?.id || isProgram) {
          this.props.updateUserSelectedProgram({
            ...eventDetails,
            distKey: distKey,
          });
        } else {
          this.props.updateUserSelectedEvent({
            ...eventDetails,
            distKey: distKey,
          });
        }
        this.setState({
          distKey: programDetails?.id ? programDetails?.distKey : distKey,
        });
        //login with distKey
        if (programDetails?.programStartDate || isProgram) {
          getProgramDetailsWithDistkey(distKey).then(({ data }) => {
            this.setState({
              programId: data?.id,
              isProgram: true,
            });
            // vishal - in case of program && multiple org is there then show its logos
            if (data?.organizers && data?.organizers?.length > 0) {
              this.setState({
                orgLogos: data?.organizers,
                newlogo: null,
              });
            }
            // if (data?.organizers && data?.organizers[0]?.organizerLogo) {
            //   this.setState({
            //     newlogo: data.organizers[0].organizerLogo.includes("http")
            //       ? data.organizers[0].organizerLogo
            //       : httpService.API_URL() + data.organizers[0].organizerLogo,
            //   });
            // }
            this.props.updateUserSelectedProgram({
              id: data.id,
              ...data,
            });
            //data population
          });
        } else {
          getEventDetailsWithDistkey(distKey).then((res) => {
            this.setState({
              eventScope: res.data.eventScope,
              supportedDomainList: res.data.eventSupportedDomains,
              eventId: res.data?.id,
            });

            if (res.data?.organizers[0]?.organizerLogo) {
              this.setState({
                newlogo: res.data.organizers[0].organizerLogo.includes("http")
                  ? res.data.organizers[0].organizerLogo
                  : httpService.API_URL() +
                    res.data.organizers[0].organizerLogo,
              });
            }
            this.props.updateUserSelectedEvent({
              id: res.data.id,
              ...res.data,
            });
            this.props.updateUserSelectedProgram({});
            //data population
          });
        }
      } else if (!programDetails?.programStartDate && existingEventId) {
        getOrganizerLogo(existingEventId).then((res) => {
          let data = res?.data;
          this.setState({
            distKey: data?.distKey,
            newlogo: data?.organizers[0]?.organizerLogo
              ? data.organizers[0].organizerLogo.includes("http")
                ? data.organizers[0].organizerLogo
                : httpService.API_URL() + data.organizers[0].organizerLogo
              : null,
            eventId: data?.id,
          });
        });
      }

      let orgKey = search.split("o=")[1];
      if (orgKey !== undefined) {
        localStorage.setItem("orgKey", orgKey);

        this.setState({ orgKey: orgKey });

        getChallengeDetailsWithOrgkey(orgKey).then((res) => {
          if (res?.data) {
            this.setState({
              newlogo: "data:image/png;base64," + res?.data?.image?.imageData,
            });
            let organizer = {
              id: res?.data?.id,
              name: res?.data?.name,
            };
            localStorage.setItem("organizer", JSON.stringify(organizer));
          }
        });
      }
    }
    // let showGoogleForm = localStorage.getItem('showGoogleSignInForm')
    // if(JSON.parse(showGoogleForm)){
    //   this.setState({showLoginFullScreen:false, showGoogleSignInForm:true})
    // }
  }

  validateEmailId = (value) => {
    let val = value.trim();
    let isNumber = /^\d+$/.test(val);

    if (isNumber) {
      if (
        // value.length === 10
        ApplicationUtil.validateContactNumber(val)
      ) {
        this.setState({
          email: val,
          emailError: false,
          byMobile: true,
          byEmail: false,
        });
      } else {
        this.setState({
          emailError: true,
          email: val,
          byMobile: true,
          byEmail: false,
        });
      }
    } else {
      let isValidEmail = validateEmail(val);
      if (isValidEmail) {
        this.setState({
          email: val,
          emailError: false,
          byEmail: true,
          byMobile: false,
        });
      } else {
        this.setState({
          emailError: true,
          email: val,
          byEmail: true,
          byMobile: false,
        });
      }
    }
  };

  validatePasswordField = (key, value) => {
    let isValid = validatePassword(value);
    if (key === "password") {
      if (isValid) {
        this.setState({ password: value, passwordError: false });
      } else {
        this.setState({ passwordError: true, password: value });
      }
    } else {
      if (isValid && value === this.state.password) {
        this.setState({ confirmPassword: value, confirmError: false });
      } else {
        this.setState({ confirmError: true });
      }
    }
  };

  validateFields = () => {
    const {
      email = "",
      password = "",
      emailError = "",
      passwordError = "",
    } = this.state;
    if (email === "" || emailError === true) {
      this.setState({ emailError: true });
    } else if (password === "" || passwordError === true) {
      this.setState({ passwordError: true });
    } else {
      this.saveFields();
    }
  };

  navigationForgotPass = () => {
    return this.props.history.push("/forgotpass");
  };

  navigationSignUp = () => {
    const { history } = this.props;
    const { byMobile, byEmail, email } = this.state;

    localStorage.removeItem("newUserDetails");
    if (this.state.distKey !== "") {
      return history.push(`/signup?distKey=${this.state.distKey}`);
    } else {
      return history.push({
        pathname: "/signup",
        encodedUrl: history.location.encodedUrl
          ? history.location.encodedUrl
          : null,
      });
    }
  };

  saveFields = () => {
    const { email, password, isProgram, programId, eventId } = this.state;
    let time = new Date().getTime();
    time = "123" + time;

    let requestParams = {
      userName: email,
      password: LoginUtil.encryptPassword(time, password),
      role: "RUNNER",
    };

    //api
    loginCheck(requestParams, time).then((res) => {
      let profileData = res.data;
      let userId = res.data.id;
      let token = res.data.token;
      let runnerId = res.data.runnerId;
      //user Details
      const getData = async () => {
        await getUserDetails(token, userId).then((res) => {
          profileData.runner = { id: runnerId };
          profileData.profilePicture = res.data?.profilePicture?.imageData;
          this.props.updateUserDetails(profileData);
        });
      };

      getData().then(() => {
        if (this.state.distKey !== "") {
          let id = isProgram ? programId : eventId;
          if (isProgram) {
            userRegisteredForProgram(id).then((res) => {
              if (res?.data?.id && res?.data?.success?.code !== "409") {
                const urlParams = new URLSearchParams(window.location.search);
                let recordRes = Boolean(urlParams.get("recordRes"));
                let isDashboard = Boolean(urlParams.get("isDashboard"));
                if (recordRes) {
                  this.props.history.push("/record-response");
                } else if (isDashboard) {
                  this.props.history.push("/programDashboard");
                } else {
                  this.props.history.push("/calender");
                }
              } else this.props.history.push("/programRegistration");
            });
          } else {
            userRegisteredForEvent(id).then((res) => {
              if (res.data.success.code === "200")
                this.props.history.push("/eventDetails");
              else this.props.history.push("/eventRegistration");
            });
          }
        }
        if (this.state.orgKey !== "") {
          this.props.history.push("/eventlist");
        }
      });
    });
  };

  handleChangeButtonClick = () => {
    clearInterval(this.otpInterval);

    this.setState({
      showLoginScreen: true,
      showOTPForm: false,
      showPasswordForm: false,
      resendOtpDisabled: false,
    });
  };

  handleLoginWithPassword = () => {
    const { email = "", emailError = "" } = this.state;
    if (email === "" || emailError === true) {
      this.setState({ emailError: true });
      //email field not valid
    } else this.setState({ showPasswordForm: true, showLoginScreen: false });
  };

  // otp
  handleLoginWithOTP = () => {
    const {
      email = "",
      emailError = false,
      byEmail,
      supportedDomainList,
      byMobile,
    } = this.state;
    if (
      email === "" ||
      emailError === true ||
      (!/^\d+$/.test(email) && !this.validateEmailDomain(email))
    ) {
      this.setState({ emailError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: !this.validateEmailDomain(email)
          ? "Email domain is not supported for this event."
          : "Please enter valid email address or contact number.",
      });
      this.goToTop();
    } else {
      let requestParams = {
        userName: email,
        byMobile: byMobile,
        byEmail: byEmail,
      };

      if (this.state.distKey !== "") {
        if (this.state.isProgram) {
          requestParams.programDistKey = this.state.distKey;
        } else {
          requestParams.distKey = this.state.distKey;
        }
      }
      //api for login with OTP
      loginWithOTP(requestParams).then((res) => {
        let code = res?.data?.success?.code;
        let msg = res.data.success.verbose;
        if (code === "409") {
          this.setState({
            openRemoveDuplicates: true,
            duplicateUsers: res.data.success.data,
            selectedUser: res.data.success.data[0].id,
            dialogHeader: <span style={{ margin: "0px 10px" }}> {msg}</span>,
            dailogMsg: this.getLoginActionContainer(res.data.success.data),
          });
        } else if (code === "200") {
          // let otpButton = document.getElementById("resendOTP");
          // otpButton.disabled = true;
          this.otpTimer();
          this.setState({
            showOTPForm: true,
            showLoginScreen: false,
            showPasswordForm: false,
            resendOtpDisabled: true,
            otpSeconds: 60,
          });
        }
      });
    }
  };

  otpTimer = () => {
    this.otpInterval = setInterval(this.handleInterval, 1000);
  };
  handleInterval = () => {
    this.setState({ otpSeconds: this.state.otpSeconds - 1 });

    if (this.state.otpSeconds === 1) {
      clearInterval(this.otpInterval);
      this.setState({ resendOtpDisabled: false });
    }
  };

  handleResendOTP = () => {
    let requestParams = {
      userName: this.state.email,
    };

    if (this.state.distKey !== "") {
      if (this.state.isProgram) {
        requestParams.programDistKey = this.state.distKey;
      } else {
        requestParams.distKey = this.state.distKey;
      }
    }
    //api for login with OTP
    loginWithOTP(requestParams).then((res) => {
      this.setState({
        otp: "",
        showOTPForm: true,
        showLoginScreen: false,
        showPasswordForm: false,
      });
    });
  };

  handleGoogleSignInResponse = (googleResponse) => {
    const { supportedDomainList, isProgram, programId, eventId } = this.state;

    // console.log("Response : " + googleResponse);
    // var googleResponseJson = JSON.stringify(googleResponse);
    // var googleProfile = googleResponse.profileObj;
    let requestParams = {
      firstName: googleResponse.profileObj.givenName,
      lastName: googleResponse.profileObj.familyName,
      email: googleResponse.profileObj.email,
      sourceUniqueId: googleResponse.profileObj.googleId,

      signinSource: "GOOGLE",
      signinToken: googleResponse.tokenId,
      profilePicLink: googleResponse.profileObj.imageUrl,
    };
    if (this.state.distKey) {
      requestParams.distKey = this.state.distKey;
    }

    if (supportedDomainList?.length > 0) {
      this.setState({ emailError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Email domain is not supported for this event.",
      });
      this.goToTop();
    } else if (googleResponse) {
      registerEvent(requestParams, googleResponse.profileObj.imageUrl).then(
        (response) => {
          let userDetails = response?.data;
          // let token = userDetails.token
          // let userId = userDetails.id
          let selectGender = userDetails?.gender ? userDetails?.gender : "MALE";
          let selectCountry = userDetails?.country
            ? userDetails?.country
            : "India";
          let setGender = this.state.genderList.find(
            (x) => x.value === selectGender
          );
          let setCountry = this.state.countryList.find(
            (x) => x.value === selectCountry
          );

          let obj = { ...userDetails };
          obj.runner = { ...userDetails, id: userDetails?.runnerId };
          obj.profilePicture = userDetails?.profileLink;
          this.setState({
            googleSignInUserDetails: userDetails,
            googleEmail: userDetails?.email,
            gender: setGender,
            dob: userDetails?.dateOfBirth ? userDetails?.dateOfBirth : "",
            country: setCountry,
            showHeightWeightOnSignup: userDetails?.showHeightWeightOnSignup,
          });

          // userDetails.runner = userDetails
          // this.props.updateUserDetails(userDetails);
          if (userDetails?.skipManadatorySignupFields) {
            ApplicationUtil.setRunnerDetails(obj).then(() => {
              if (this.props.history.location.encodedUrl) {
                getDecodedUrl(this.props.history.location.encodedUrl).then(
                  (res) => {
                    if (res.data.fullUrl) {
                      if (res.data.fullUrl.includes("#")) {
                        let newUrl = ApplicationUtil.getRedirectedRoute(
                          res.data.fullUrl
                        );
                        this.props.history.push(`${newUrl}`);
                      } else {
                        this.props.history.push(`${res.data.fullUrl}`);
                      }
                    }
                  }
                );
              }
              if (this.state.distKey !== "") {
                let id = isProgram ? programId : eventId;
                if (isProgram) {
                  userRegisteredForProgram(id).then((res) => {
                    if (res?.data?.id && res?.data?.success?.code !== "409") {
                      const urlParams = new URLSearchParams(
                        window.location.search
                      );
                      let recordRes = Boolean(urlParams.get("recordRes"));
                      let isDashboard = Boolean(urlParams.get("isDashboard"));
                      if (recordRes) {
                        this.props.history.push("/record-response");
                      } else if (isDashboard) {
                        this.props.history.push("/programDashboard");
                      } else {
                        this.props.history.push("/calender");
                      }
                    } else this.props.history.push("/programRegistration");
                  });
                } else {
                  userRegisteredForEvent(id).then((res) => {
                    if (res.data.success.code === "200")
                      this.props.history.push("/eventDetails");
                    else this.props.history.push("/eventRegistration");
                  });
                }
              } else {
                this.props.history.push({
                  pathname: "/eventList",
                  openActivitySync:
                    this.state.distKey === ""
                      ? !userDetails?.isAuthorized && userDetails?.isRegToEvent
                      : false,
                  newMessagesPresent: userDetails?.newMessagesPresent,
                  newMessageEvents: userDetails?.newMessageEvents,
                });
              }
            });
          } else {
            this.setState({
              showLoginFullScreen: false,
              showGoogleSignInForm: true,
            });
          }
        }
      );
    }
  };

  verifyOTPForLogin = () => {
    let requestParams = {
      userName: this.state.email,
      otp: this.state.otp,
      byMobile: this.state.byMobile,
      byEmail: this.state.byEmail,
    };

    if (this.state.distKey !== "") {
      if (this.state.isProgram) {
        requestParams.programDistKey = this.state.distKey;
      } else {
        requestParams.distKey = this.state.distKey;
      }
    }

    //api for verifying OTP
    verifyOTP(requestParams).then((res) => {
      const { history } = this.props;
      const { byMobile, byEmail, email, isProgram, programId, eventId } =
        this.state;
      let data = res.data;
      // let profileData = res.data;
      let userId = data.id;
      let token = data.token;
      let runnerId = data.runnerId;
      localStorage.setItem("isFromLogin", true);

      if (data !== "") {
        if (data?.allowedAttemptCompleted) {
          this.setState({
            showOTPForm: false,
            showLoginScreen: true,
            showPasswordForm: false,
            otp: null,
            email: null,
          });
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message:
              "You have exceeded the number of login attempts. Please try after some time.",
          });
        } else {
          //user Details
          if (!data?.newUser) {
            let obj = { ...data };
            const programs = data?.programDtos;
            obj.runner = { ...data, id: data?.runnerId };
            obj.profilePicture =
              data?.profileImage?.imageData || data?.profileLink;
            this.props.updateUserDetails(obj);

            if (this.props.history.location.encodedUrl) {
              getDecodedUrl(this.props.history.location.encodedUrl).then(
                (urlResponse) => {
                  if (urlResponse.data.fullUrl) {
                    if (urlResponse.data.fullUrl.includes("#")) {
                      let newUrl = ApplicationUtil.getRedirectedRoute(
                        urlResponse.data.fullUrl
                      );
                      this.props.history.push(`${newUrl}`);
                    } else {
                      this.props.history.push(`${urlResponse.data.fullUrl}`);
                    }
                  }
                }
              );
            }

            /**
             * If diskey is present
             */
            if (this.state.distKey !== "") {
              let id = isProgram ? programId : eventId;
              if (isProgram) {
                userRegisteredForProgram(id).then((res) => {
                  if (res?.data?.id && res?.data?.success?.code !== "409") {
                    const urlParams = new URLSearchParams(
                      window.location.search
                    );
                    let recordRes = Boolean(urlParams.get("recordRes"));
                    let isDashboard = Boolean(urlParams.get("isDashboard"));
                    let currentRunningChallenge =
                      ApplicationUtil.getCurrentRunningProgramChallenge(
                        res?.data?.programChallenges || []
                      );

                    if (recordRes) {
                      this.props.history.push("/record-response");
                    } else if (isDashboard) {
                      this.props.history.push("/programDashboard");
                    } else if (currentRunningChallenge) {
                      let challengeStartDate =
                        currentRunningChallenge?.challengeStartDate?.split(
                          " "
                        )[0];
                      const today = moment().format("DD-MM-YYYY");
                      const isSameDay = moment(
                        challengeStartDate,
                        "DD-MM-YYYY"
                      ).isSame(moment(today, "DD-MM-YYYY"), true);
                      if (isSameDay) {
                        if (currentRunningChallenge?.videoLink) {
                          if (currentRunningChallenge?.isVideoViewed) {
                            this.props.history.push("/calender");
                          } else {
                            this.props.history.push(
                              "/program?distKey=" + res?.data?.distKey || ""
                            );
                          }
                        } else this.props.history.push("/calender");
                      } else {
                        this.props.history.push("/calender");
                      }
                    } else {
                      this.props.history.push("/calender");
                    }
                  } else this.props.history.push("/programRegistration");
                });
              } else {
                userRegisteredForEvent(id).then((res) => {
                  if (res.data.success.code === "200")
                    this.props.history.push("/eventDetails");
                  else this.props.history.push("/eventRegistration");
                });
              }
            } else {
              if (programs?.length === 1) {
                this.props.updateUserSelectedProgram(programs[0]);
                const urlParams = new URLSearchParams(window.location.search);
                let recordRes = Boolean(urlParams.get("recordRes"));
                let isDashboard = Boolean(urlParams.get("isDashboard"));
                let currentRunningChallenge =
                  ApplicationUtil.getCurrentRunningProgramChallenge(
                    programs[0]?.programChallenges || []
                  );

                if (recordRes) {
                  this.props.history.push("/record-response");
                } else if (isDashboard) {
                  this.props.history.push("/programDashboard");
                } else if (currentRunningChallenge) {
                  let challengeStartDate =
                    currentRunningChallenge?.challengeStartDate?.split(" ")[0];
                  const today = moment().format("DD-MM-YYYY");
                  const isSameDay = moment(
                    challengeStartDate,
                    "DD-MM-YYYY"
                  ).isSame(moment(today, "DD-MM-YYYY"), true);
                  if (isSameDay) {
                    if (currentRunningChallenge?.isVideoViewed) {
                      this.props.history.push("/calender");
                    } else {
                      this.props.history.push(
                        "/program?distKey=" + programs[0]?.distKey || ""
                      );
                    }
                  } else {
                    this.props.history.push("/calender");
                  }
                } else {
                  this.props.history.push("/calender");
                }
              } else {
                this.props.history.push({
                  pathname: "/eventList",
                  // pathname:'/calender',
                  // openActivitySync: this.state.distKey === "" ? data?.runner ? !data.runner.isAuthorized && data.runner.isRegToEvent : false : !data?.isAuthorized,
                  openActivitySync:
                    this.state.distKey === "" ? !data.isAuthorized : false,

                  newMessagesPresent: data?.newMessagesPresent,
                  newMessageEvents: data?.newMessageEvents,
                });
              }
            }

            // })
          } else {
            let details = {
              byMobile,
              byEmail,
              email,
              contactNumber: email,
              showSignupForm: true,
            };
            localStorage.setItem("newUserDetails", JSON.stringify(details));
            return this.props.history.push(
              `/signup?distKey=${this.state.distKey}`
            );
          }
        }
      } else {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "OTP entered is invalid",
        });
      }
    });
  };

  handleRadioChange = (event) => {
    this.setState({
      selectedUser: event.target.value,
    });
  };
  getLoginActionContainer = (data) => {
    const { duplicateUsers, selectedUser } = this.state;

    return (
      <div style={{ padding: "8px 24px" }}>
        <Typography style={{ display: "grid" }}>
          {duplicateUsers?.map((user, index) => (
            <Grid
              container
              style={{
                // alignItems: "self-end",
                marginBottom: "8px",
              }}
            >
              <Grid item>
                <Radio
                  checked={this.state.selectedUser == user.id}
                  onChange={this.handleRadioChange}
                  value={user.id}
                  name="radio-buttons"
                />
              </Grid>
              <Grid item>
                <div>
                  <div style={{ textTransform: "capitalize" }}>
                    {user?.lastName
                      ? `${user.firstName + " " + user?.lastName}`
                      : user.firstName}
                  </div>
                  <div>{user?.email}</div>
                </div>
              </Grid>
            </Grid>
          ))}
        </Typography>
      </div>
    );
  };

  handleRemoveDuplicates = () => {
    const { selectedUser, duplicateUsers, email } = this.state;
    let userIds = duplicateUsers.filter((el) => el.id != selectedUser);
    let arr = [];
    userIds.forEach((el) => {
      arr.push(el.id);
    });
    let requestParams = {
      userName: email,
      userIds: arr,
    };
    removeDuplicateAccounts(requestParams).then((res) => {
      this.setState({
        showOTPForm: true,
        showLoginScreen: false,
        showPasswordForm: false,
        openRemoveDuplicates: false,
      });
    });
  };

  validateEmailDomain = (value) => {
    
    let domainValid = false;
    let domain = value.split("@");
    domain = domain[1]?.toLowerCase();

    if (
      this.state.supportedDomainList &&
      this.state.supportedDomainList.length > 0
    ) {
      this.state.supportedDomainList.map((e) => {
        if (e.validationDomain.toLowerCase() === domain) {
          domainValid = true;
        }
      });
      return domainValid;
    } else {
      return true;
    }
  };
  handleChange = (otp) => this.setState({ otp });

  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  handleGenderChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        gender: selectedValues,
        genderError: false,
      });
    } else {
      this.setState({
        gender: selectedValues,
        genderError: true,
      });
    }
  };
  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };
  handleCountryChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        country: selectedValues,
        countryError: false,
      });
    } else {
      this.setState({
        country: selectedValues,
        countryError: true,
      });
    }
  };
  validateGoogleSignInFormFields = () => {
    const { gender, genderError, dob, dobError, country, countryError } =
      this.state;
    if (gender === "" || genderError) {
      this.setState({ genderError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select gender",
      });
    } else if (dob === "" || dobError) {
      this.setState({ dobError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter date of birth",
      });
    } else if (country === "" || countryError) {
      this.setState({ countryError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select country",
      });
    }
    //         else if(showHeightWeightOnSignup && ((height === '' || heightError) || (weight === '' || weightError))){
    // Snackbar.show({
    //   variant: "error",
    //   isAutoHide: true,
    //   preventDuplicate: true,
    //   message: heightError ? "" : "Please select country",
    // })
    //         }
    else {
      this.submitFields();
    }
  };
  submitFields = () => {
    const {
      googleSignInUserDetails,
      googleEmail,
      gender,
      dob,
      country,
      showHeightWeightOnSignup,
      height,
      weight,
      isProgram,
      programId,
      eventId,
    } = this.state;
    let userDetails = googleSignInUserDetails;
    let userId = userDetails?.id;
    let token = userDetails?.token;
    let formatDob = moment(dob, "YYYY-MM-DD").format("DD-MM-YYYY");
    // let userDetails = ApplicationUtil.getRunnerDetails()

    let params = {
      dob: formatDob,
      gender: gender.value,
      email: googleEmail,
      country: country.value,
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
    };
    if (userDetails?.countryCode) {
      params.countryCode = userDetails?.countryCode;
    }
    if (userDetails?.contactNumber) {
      params.contactNumber = userDetails?.contactNumber;
    }
    if (userDetails?.city) {
      params.city = userDetails?.city;
    }
    if (userDetails?.pincode) {
      params.pincode = userDetails?.pincode;
    }
    if (showHeightWeightOnSignup) {
      params.height = height;
      params.weight = weight;
    }

    editUserProfile(params, token, userId).then((res) => {
      let data = res.data;
      if (data?.success?.code == "200") {
        getUserDetails(token, userId).then((response) => {
          let userData = response.data?.user;
          let obj = { ...userData };
          obj.runner = { ...userData, id: userDetails?.runnerId };
          obj.profilePicture = userData?.profileLink;
          ApplicationUtil.setRunnerDetails(obj).then(() => {
            if (this.props.history.location.encodedUrl) {
              getDecodedUrl(this.props.history.location.encodedUrl).then(
                (res) => {
                  if (res.data.fullUrl) {
                    if (res.data.fullUrl.includes("#")) {
                      let newUrl = ApplicationUtil.getRedirectedRoute(
                        res.data.fullUrl
                      );
                      this.props.history.push(`${newUrl}`);
                    } else {
                      this.props.history.push(`${res.data.fullUrl}`);
                    }
                  }
                }
              );
            }
            if (this.state.distKey !== "") {
              let id = isProgram ? programId : eventId;
              if (isProgram) {
                userRegisteredForProgram(id).then((res) => {
                  if (res?.data?.id && res?.data?.success?.code !== "409") {
                    const urlParams = new URLSearchParams(
                      window.location.search
                    );
                    let recordRes = Boolean(urlParams.get("recordRes"));
                    //Vishal - Checking where user is comes from (Added check for = isDashboard / currentRunningChallenge) according to that push him to the respective page
                    let isDashboard = Boolean(urlParams.get("isDashboard"));
                    let currentRunningChallenge =
                      ApplicationUtil.getCurrentRunningProgramChallenge(
                        res?.data?.programChallenges || []
                      );
                    if (recordRes) {
                      this.props.history.push("/record-response");
                    } else if (isDashboard) {
                      this.props.history.push("/programDashboard");
                    } else if (currentRunningChallenge) {
                      let challengeStartDate =
                        currentRunningChallenge?.challengeStartDate?.split(
                          " "
                        )[0];
                      const today = moment().format("DD-MM-YYYY");
                      const isSameDay = moment(
                        challengeStartDate,
                        "DD-MM-YYYY"
                      ).isSame(moment(today, "DD-MM-YYYY"), true);
                      if (isSameDay) {
                        if (currentRunningChallenge?.videoLink) {
                          if (currentRunningChallenge?.isVideoViewed) {
                            this.props.history.push("/calender");
                          } else {
                            this.props.history.push(
                              "/program?distKey=" + res?.data?.distKey || ""
                            );
                          }
                        } else this.props.history.push("/calender");
                      } else {
                        this.props.history.push("/calender");
                      }
                    } else {
                      this.props.history.push("/calender");
                    }
                  } else this.props.history.push("/programRegistration");
                });
              } else {
                userRegisteredForEvent(id).then((res) => {
                  if (res.data.success.code === "200")
                    this.props.history.push("/eventDetails");
                  else this.props.history.push("/eventRegistration");
                });
              }
            } else {
              this.props.history.push({
                pathname: "/eventList",
                openActivitySync:
                  this.state.distKey === ""
                    ? !userDetails?.isAuthorized && userDetails?.isRegToEvent
                    : false,
                newMessagesPresent: userDetails?.newMessagesPresent,
                newMessageEvents: userDetails?.newMessageEvents,
              });
            }
          });
        });
      }
    });
  };
  render() {
    const { classes } = this.props;
    const {
      emailError,
      passwordError,
      otpSeconds,
      resendOtpDisabled,
      googleEmail,
      gender,
      genderList,
      genderError,
      dob,
      dobError,
      height,
      heightError,
      weight,
      weightError,
      country,
      countryError,
      countryList,
      showHeightWeightOnSignup,
      showLoginFullScreen,
      showGoogleSignInForm,
      eventId,
      orgLogos,
      newlogo,
    } = this.state;

    return (
      <>
        {showLoginFullScreen && (
          <div
            style={{
              height: "99vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Container className={classes.mainPanel}>
              <Grid className={classes.innerMainPannel} container spacing={1}>
                {orgLogos && orgLogos?.length > 0 ? (
                  <div className={classes.logosOuterDiv}>
                    {orgLogos?.map((organizer) => {
                      return (
                        <img
                          src={`${httpService.API_URL()}${
                            organizer?.organizerLogo
                          }`}
                          alt="org-logo"
                          className={classes.logosCss}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    <img
                      src={newlogo ? newlogo : logo} //{logo} //
                      alt="logo"
                      style={{
                        width: "180px",
                        height: "100px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
              </Grid>

              {this.state.showLoginScreen && (
                <>
                  {/* email field */}
                  <Grid
                    style={{
                      width: "100%",
                      height: "75px",
                      paddingTop: "10px",
                      margin: "auto",
                      padding: "10px",
                      // border:'1px solid red'
                    }}
                    container
                    spacing={1}
                  >
                    <Grid item xs={12}>
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.Label_Email}/ {Properties.Label_Mobile}
                        </span>
                      </Typography>{" "}
                      <CustomTextField
                        style={
                          emailError
                            ? {
                                border: "1px solid red",
                                height: "45%",
                                maxHeight: "45%",
                                minHeight: "45%",
                                width: "100%",
                              }
                            : {
                                border: "1px solid #08456C",
                                height: "45%",
                                maxHeight: "45%",
                                minHeight: "45%",
                                width: "100%",
                              }
                        }
                        type={"text"}
                        keyToSet={"email"}
                        value={this.state.email}
                        placeholder={"Email or Mobile"}
                        handleChange={(prop) => (event) => {
                          this.validateEmailId(event.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* submit button */}
                  <Grid
                    style={{
                      width: "100%",
                      height: "75px",
                      marginTop: "10px",
                      justifyContent: "center",
                      // margin: "0px",
                      textAlign: "center",
                    }}
                    container
                    spacing={1}
                  >
                    <Grid item xs={6}>
                      <SubmitCancelBtn
                        onClick={() => this.handleLoginWithOTP()}
                        label={Properties.loginUsingOTP}
                        type="submit"
                        style={{ width: "180px" }}
                      />
                    </Grid>

                    {/* <Grid item xs={6} style={{ marginTop: "5%" }}>
                  <SubmitCancelBtn
                    onClick={() => this.handleLoginWithPassword()}
                    label={"Login"}
                    type="submit"
                    style={{ width: "160px" }}
                  />
                </Grid> */}
                  </Grid>
                  {/* <Typography>OR</Typography> */}
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#6b6565" }}>OR</span>
                  </Typography>

                  {/* signup link */}
                  <Grid
                    style={{
                      width: "100%",
                      height: "75px",
                      // marginTop: "10px",
                      paddingTop: "5%",
                      justifyContent: "center",
                      // margin: "0px",
                      textAlign: "center",
                    }}
                    container
                    spacing={1}
                  >
                    <span
                      onClick={this.navigationSignUp}
                      style={{ cursor: "pointer" }}
                    >
                      <span style={{ color: "#999999" }}>New user? </span>
                      <span style={{ color: "#F79824", fontWeight: "bold" }}>
                        SIGN-UP
                      </span>
                    </span>
                  </Grid>
                  {/* <Typography>OR</Typography> */}
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#6b6565" }}>OR</span>
                  </Typography>
                  <Grid
                    style={{
                      width: "100%",
                      height: "75px",
                      // marginTop: "10px",
                      paddingTop: "5%",
                      justifyContent: "center",
                      // margin: "0px",
                      textAlign: "center",
                    }}
                    container
                    spacing={1}
                  >
                    <GoogleLogin
                      clientId="308570200209-egcv1h57uud5vk5fp6inan6js3gtjoo0.apps.googleusercontent.com"
                      // buttonText={Properties.loginWithGoogle}
                      onSuccess={(res) => {
                        this.handleGoogleSignInResponse(res);
                      }}
                      onFailure={(error) => console.log(error)}
                      cookiePolicy={"single_host_origin"}
                      // isSignedIn={true}
                    />

                    {/* <GoogleLogout
                  clientId="308570200209-egcv1h57uud5vk5fp6inan6js3gtjoo0.apps.googleusercontent.com"
                  buttonText="Logout"
                  onLogoutSuccess={(res) => console.log("logout", res)}
                ></GoogleLogout> */}
                  </Grid>
                  {/* youtube links */}
                  <Grid
                    style={{
                      width: "100%",
                      height: "75px",
                      justifyContent: "center",
                      marginTop: "25px",
                      textAlign: "center",
                    }}
                    container
                    spacing={1}
                  >
                    <Grid>
                      <a
                        href="https://www.youtube.com/channel/UCrFu2UJ69jWSfLot56wPabg"
                        target="_blank"
                        style={{
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* <a
                      href="https://youtu.be/6tylDhAQecs"
                      target="_blank"
                      position="relative"
                      rel="noopener noreferrer"
                      style={{ fontSize: "12px" }}
                    > */}
                        <YoutubeIcon style={{ height: "15" }} />
                        {/* </a> */}
                        <span
                          style={{
                            fontSize: "14px",
                            cursor: "pointer",
                            color: "blue",
                            textDecoration: "underline",
                            marginRight: this.state.usedFirefox ? "2px" : "0px",
                          }}
                        >
                          {" Help Videos "}
                        </span>
                      </a>
                    </Grid>
                  </Grid>
                </>
              )}

              {/* loginWithOTP form */}
              {this.state.showOTPForm && (
                <>
                  <Grid
                    style={{
                      width: "100%",
                      // height: "75px",
                      paddingTop: "10px",
                      margin: "0px",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    container
                    spacing={1}
                  >
                    {/* <Grid justifyContent="center"> */}
                    <Grid item xs={9}>
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.Label_Email}/ {Properties.Label_Mobile}{" "}
                        </span>
                      </Typography>{" "}
                      <CustomTextField
                        style={{
                          border: "1px solid #08456C",
                          height: "45%",
                          maxHeight: "45%",
                          minHeight: "45%",
                          width: "100%",
                          backgroundColor: "#D1D5DB",
                        }}
                        type={"text"}
                        keyToSet={"email"}
                        value={this.state.email}
                        readOnly={true}
                        placeholder={"Email or Mobile"}
                        handleChange={(prop) => (event) => {
                          this.validateEmailId(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} style={{ margin: "auto" }}>
                      <SubmitCancelBtn
                        onClick={this.handleChangeButtonClick}
                        label={" Change"}
                        type="submit"
                        style={{ width: "auto" }}
                      />
                      {/* <button onClick={this.handleChangeButtonClick}>change</button> */}
                    </Grid>
                    {/* </Grid> */}
                  </Grid>

                  <Grid
                    style={{
                      width: "100%",
                      // height: "75px",
                      paddingTop: "10px",
                      margin: "0px",
                      padding: "10px",
                    }}
                    container
                    spacing={1}
                    justify="center"
                  >
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Grid style={{ padding: "3% 0" }}>
                        <OtpInput
                          value={this.state.otp}
                          onChange={this.handleChange}
                          numInputs={6}
                          // separator={<span> </span>}
                          isInputNum={true}
                          inputStyle={{
                            border: 0,
                            borderBottom: "1px solid black",
                            marginRight: "10px",
                          }}
                          containerStyle={{ justifyContent: "center" }}
                        />
                      </Grid>
                      <Grid>
                        <button
                          style={{
                            fontSize: "11px",
                            height: "17px",
                            padding: "0px 0px 0px 0px",
                            marginBottom: "5px",
                          }}
                          id="resendOTP"
                          onClick={this.handleLoginWithOTP}
                          disabled={resendOtpDisabled}
                        >
                          Resend OTP
                        </button>
                        <br />

                        <span>{`00 : ${
                          otpSeconds < 10 ? "0" + otpSeconds : otpSeconds
                        }`}</span>
                      </Grid>
                      <Grid>
                        <SubmitCancelBtn
                          isDisabled={false}
                          onClick={() => this.verifyOTPForLogin()}
                          label={"Submit"}
                          type="submit"
                          style={{ width: "auto", marginTop: "2%" }}
                        />
                        {eventId && (eventId === 1631 || eventId === 451) && (
                          <Typography
                            variant="body2"
                            gutterBottom
                            style={{ width: "auto", marginTop: "2%" }}
                          >
                            <span
                              style={{ color: "#727272eA", fontSize: "15px" }}
                            >
                              {
                                "**I consent to sharing of my data for the purpose of participating in the Persistent Run"
                              }
                            </span>
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {/* loginWithPassword form */}
              {this.state.showPasswordForm && (
                <>
                  <Grid
                    style={{
                      width: "100%",
                      height: "75px",
                      paddingTop: "10px",
                      margin: "0px",
                      padding: "10px",
                    }}
                    container
                    spacing={1}
                  >
                    {/* <Grid justifyContent="center"> */}
                    <Grid item xs={10}>
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.Label_Email}/ {Properties.Label_Mobile}{" "}
                        </span>
                      </Typography>{" "}
                      <CustomTextField
                        style={{
                          border: "1px solid #08456C",
                          height: "45%",
                          maxHeight: "45%",
                          minHeight: "45%",
                          width: "100%",
                          backgroundColor: "#D1D5DB",
                        }}
                        type={"text"}
                        keyToSet={"email"}
                        value={this.state.email}
                        readOnly={true}
                        placeholder={"Email or Mobile"}
                        handleChange={(prop) => (event) => {
                          this.validateEmailId(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} style={{ margin: "auto" }}>
                      <SubmitCancelBtn
                        onClick={this.handleChangeButtonClick}
                        label={" Change"}
                        type="submit"
                        style={{ width: "auto" }}
                      />
                      {/* <button onClick={this.handleChangeButtonClick}>change</button> */}
                    </Grid>
                    {/* </Grid> */}
                  </Grid>
                  <Grid
                    style={{
                      width: "100%",
                      height: "75px",
                      paddingTop: "10px",
                      margin: "0px",
                      padding: "10px",
                    }}
                    container
                    spacing={1}
                    justify="center"
                  >
                    {/* password field */}
                    <Grid
                      style={{
                        width: "100%",
                        // height: "75px",
                        margin: "0px",
                        // padding: "10px",
                      }}
                      container
                      spacing={1}
                    >
                      <Grid item xs={10}>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ color: "#727272" }}>
                            {Properties.Label_Password}
                          </span>
                        </Typography>{" "}
                        <CustomTextField
                          style={
                            passwordError
                              ? {
                                  border: "1px solid red",
                                  height: "45%",
                                  minHeight: "45%",
                                  maxHeight: "45%",
                                  width: "100%",
                                }
                              : {
                                  border: "1px solid #08456C",
                                  height: "45%",
                                  width: "100%",
                                  minHeight: "45%",
                                  maxHeight: "45%",
                                }
                          }
                          type={"password"}
                          keyToSet={"password"}
                          placeholder={"password"}
                          handleChange={(prop) => (event) => {
                            this.validatePasswordField(
                              "password",
                              event.target.value
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ margin: "auto" }} item>
                      <SubmitCancelBtn
                        onClick={() => this.validateFields()}
                        label={"Login"}
                        type="submit"
                        style={{ width: "auto" }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <DialogBox
                signup={true}
                open={this.state.openRemoveDuplicates}
                message={this.getLoginActionContainer(
                  this.state.duplicateUsers
                )}
                header={this.state.dialogHeader}
                onClick={() => {
                  this.handleRemoveDuplicates();
                }}
                closeDisloag={() => {
                  this.setState({
                    openRemoveDuplicates: false,
                  });
                }}
              />
            </Container>
          </div>
        )}

        {showGoogleSignInForm && (
          <div
            style={{
              height: "90vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AppToolBar>
              <Header label={Properties.Profile} signup />
            </AppToolBar>
            <Container className={classes.googleFormContainer}>
              <div className={classes.fieldLayout}>
                <Typography variant="body2" gutterBottom>
                  <span className={classes.labelColor}>
                    {Properties.Label_Email}
                  </span>
                </Typography>{" "}
                <CustomTextField
                  className={classes.emailField}
                  type={"email"}
                  keyToSet={"email"}
                  value={googleEmail}
                  handleChange={(prop) => (event) => {
                    console.log(event.target.value);
                  }}
                  readOnly={true}
                />
              </div>
              {/* gender */}
              <div className={classes.fieldLayout}>
                <Typography variant="body2" gutterBottom>
                  <span className={classes.labelColor}>
                    {Properties.Label_Gender}
                  </span>
                  <span className={classes.required}>*</span>
                </Typography>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <MultiSelect
                    value={gender}
                    error={genderError}
                    options={genderList}
                    onChange={this.handleGenderChange}
                  />
                </div>
              </div>
              <div className={classes.fieldLayout}>
                <Typography variant="body2" gutterBottom>
                  <span className={classes.labelColor}>
                    {Properties.Label_DOB}
                  </span>
                  <span className={classes.required}>*</span>
                </Typography>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  disableFuture={true}
                  value={dob}
                  style={{
                    width: "100%",
                  }}
                  placeholder="DD/MM/YYYY"
                  error={dobError}
                  className={classes.fieldWidth}
                  InputProps={{
                    classes: {
                      root: dobError
                        ? classes.datePickerError
                        : classes.datePicker,
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      margin: "-3px 0px",
                    },
                  }}
                  helperText={""}
                  keyboardIcon={
                    <IconCalendarBlue className={classes.calendarIconCss} />
                  }
                  onChange={(date) => {
                    if (date > new Date()) {
                      this.setState({ dobError: true });
                    } else
                      this.setState({
                        dob: this.isValidDate(date) ? date : "",
                        dobError: this.isValidDate(date) ? false : true,
                      });
                  }}
                />
              </div>
              <div className={classes.fieldLayout}>
                <Typography variant="body2" gutterBottom>
                  <span className={classes.labelColor}>
                    {Properties.Label_Country}
                  </span>
                  <span className={classes.required}>*</span>
                </Typography>{" "}
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <MultiSelect
                    value={country} //{country}
                    error={countryError}
                    options={countryList}
                    onChange={this.handleCountryChange}
                  />
                </div>
              </div>
              {showHeightWeightOnSignup && (
                <>
                  {/* height */}
                  <div className={classes.fieldLayout}>
                    <Typography variant="body2" gutterBottom>
                      <span className={classes.labelColor}>
                        {Properties.Height_label} {Properties.Label_unit_cm}
                      </span>
                      <span className={classes.required}>*</span>
                    </Typography>{" "}
                    <CustomTextField
                      className={classes.inputField}
                      style={
                        heightError
                          ? {
                              border: "1px solid red",
                            }
                          : {
                              border: "1px solid #08456C",
                            }
                      }
                      type={"number"}
                      keyToSet={"postaclCode"}
                      error={heightError}
                      value={height}
                      handleChange={(prop) => (event) => {
                        this.setState({
                          height: event.target.value,
                          heightError: false,
                        });
                      }}
                    />
                  </div>
                  {/* weight */}
                  <div className={classes.fieldLayout}>
                    <Typography variant="body2" gutterBottom>
                      <span className={classes.labelColor}>
                        {Properties.Weight_label} {Properties.Label_Unit_kg}
                      </span>
                      <span className={classes.required}>*</span>
                    </Typography>{" "}
                    <CustomTextField
                      className={classes.inputField}
                      style={
                        weightError
                          ? {
                              border: "1px solid red",
                            }
                          : {
                              border: "1px solid #08456C",
                            }
                      }
                      type={"number"}
                      keyToSet={"postaclCode"}
                      error={weightError}
                      value={weight}
                      handleChange={(prop) => (event) => {
                        this.setState({
                          weight: event.target.value,
                          weightError: false,
                        });
                      }}
                    />
                  </div>
                </>
              )}

              <div>
                <SubmitCancelBtn
                  onClick={this.validateGoogleSignInFormFields}
                  label={"Submit"}
                  type="submit"
                  //  style={{ width: "" }}
                />
              </div>
            </Container>
          </div>
        )}
      </>
    );
  }

  componentWillUnmount() {
    clearInterval(this.otpInterval);
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    userSelectedEvent: state.user.userSelectedEvent,
  };
}

//export default LoginContainer;

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateUserDetails,
    updateUserSelectedEvent,
    updateUserSelectedProgram,
  })
)(LoginContainer);

/*
 <Grid style={{ marginTop: "5%" }} item xs={5}>
   <SubmitCancelBtn
     onKeyDown={(e) => this.handleKeyDown(e)}
     onClick={() => this.validateFields()}
     label={"Login"}
     type="submit"
     style={{ width: "80%" }}
   />
 </Grid>;

 */
