import Snackbar from "../components/common/Snackbar";
import ApplicationConstants from "../constants/ApplicationConstants";
import { history } from "../History";
import { store } from "../redux/store";
import {
  removeUserDetails,
  updateUserDetails,
  updateUserLink,
  updateUserSelectedEvent,
  updateUserPreferences,
  removeUserSelectedEvent,
} from "../redux/user";
import { flagList } from "../constants/FlagList";
import ReactHtmlParser from "react-html-parser";
import { createBrowserHistory, createHashHistory } from "history";
import httpService from "../apis/httpService";
var moment = require("moment");
class ApplicationUtil {
  constructor() {
    if (!this.instance) {
      this.instance = this;
    }
    return this.instance;
  }
  static Routing = [];
  static postRegistrationObject = {};

  static saveMatchUrl = (url) => {
    localStorage.setItem("matchUrl", url);
  };

  static getMatchUrl = () => {
    return localStorage.getItem("matchUrl");
  };

  static go = (route) => {
    history.push(route);
  };
  static capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  static routetoLoginWithUrl = (url) => {
    history.push({
      pathname: "/login",
      encodedUrl: url,
    });
  };

  static goBack = () => {
    let routeArr = ApplicationUtil.Routing.splice(-2, 2);
    let route = routeArr[0];

    if (route) {
      this.go(route);
    } else {
      history.go(-1);
    }
  };

  static isuserLoggedIn = (view) => {
    let token;
    if (view == "support" || view == "organizer") {
      let data = ApplicationUtil.getSupportDetails();
      token = data?.token;
    } else {
      let data = ApplicationUtil.getRunnerDetails(); //
      token = data?.token; // localStorage.getItem("token");
    }
    // if (view == "support" || view == "organizer") {
    //   token = localStorage.getItem("supportToken");
    // } else {
    //   token = localStorage.getItem("token");
    // }
    if (token) {
      return true;
    } else {
      return false;
    }
  };

  static handleRunnerLogout = async (url) => {
    // let keys = Object.keys(localStorage);
    // let isSupport = keys.includes("supportToken");
    // let getRunnerItems = keys.filter((x) => !x.includes("support"));
    // let i = getRunnerItems.length;
    // if (isSupport) {
    //   while (i--) {
    //     localStorage.removeItem(getRunnerItems[i]);
    //   }
    // } else {
    //   localStorage.clear();
    // }
    store.dispatch(removeUserDetails(null));

    // ApplicationUtil.go(url ? url : "/");
  };
  static handleRemoveSelectedEvent = async (url) => {
    store.dispatch(removeUserSelectedEvent(null));
  };

  static handleNavigateHome = async () => {
    store.dispatch(
      updateUserPreferences({
        drawerSidebar: "/eventlist",
      })
    );
  };
  static getRunnerDetails = () => {
    let data = store.getState();
    let userData = data?.user?.user;
    return userData ? userData : null;
  };

  //ApplicationUtil.setRunnerDetails(profileData); except profileData to be an object
  static setRunnerDetails = async (data) => {
    store.dispatch(updateUserDetails(data));
  };
  static getRunnerToken = () => {
    let data = store.getState();
    let token = data?.user?.user?.token;
    return token ? token : null;
  };
  static getRunnersEventID = () => {
    let data = store.getState();

    let id = data?.user?.userSelectedEvent?.id;
    return id ? id : null;
  };

  static getResultsSearchValue = () => {
    let data = store.getState();

    let value = data?.user?.resultsSearchValue;
    return value ? value : "";
  };
  static getRunnersEventsDetails = () => {
    let data = store.getState();
    let event = data?.user?.userSelectedEvent;
    return event ? event : null;
  };
  static getRunnersProgramDetails = () => {
    let data = store.getState();
    let event = data?.user?.userSelectedProgram;
    return event ? event : null;
  };

  static getRunnerVideoURL = () => {
    let data = store.getState();
    let url = data?.user?.userUrl;
    return url ? url : null;
  };
  static getSupportDetails = () => {
    let data = store.getState();
    let userData = data?.supportUser?.supportUser;
    return userData ? userData : null;
  };
  static getSupportToken = () => {
    let data = store.getState();
    let token = data?.supportUser?.supportUser?.token;
    return token ? token : null;
  };

  static getSupportsEventID = () => {
    let data = store.getState();
    let id = data?.supportUser?.supportSelectedEvent?.id;
    return id ? id : null;
  };

  static getSupportsEventsDetails = () => {
    let data = store.getState();
    let event = data?.supportUser?.supportSelectedEvent;
    return event ? event : null;
  };
  static getSupportsProgramDetails = () => {
    let data = store.getState();
    let event = data?.supportUser?.supportSelectedProgram;
    return event ? event : null;
  };

  // Change - Laxman
  static getProgramUnderCurrentChallenge = () => {
    let data = store.getState();
    let currentChallenge = data?.supportUser?.createdChallenge;
    return currentChallenge ? currentChallenge : null;
  };

  static getSupportListFixColumns = function (dataIndex) {
    let columns = [
      {
        columnName: "Name",
        dataIndex: "runnerName",
      },
      {
        columnName: "BIB",
        dataIndex: "bibNumber",
      },
      {
        columnName: "Category",
        dataIndex: "categoryName",
      },
    ];

    if (dataIndex) {
      let fields = [];
      columns.forEach((rec) => fields.push(rec.dataIndex));
      return fields.join(",");
    } else {
      return columns;
    }
  };

  static getSupportFilterColumns = function (dataIndex) {
    let columns = [
      {
        columnName: "All",
        dataIndex: "ALL",
      },
      {
        columnName: "Pending",
        dataIndex: "PENDING",
      },
      {
        columnName: "Active",
        dataIndex: "ACTIVE",
      },
      {
        columnName: "BIB",
        dataIndex: "BIB",
      },
      {
        columnName: "Participants",
        dataIndex: "PARTICIPANTS",
      },
    ];

    if (dataIndex) {
      let fields = [];
      columns.forEach((rec) => fields.push(rec.dataIndex));
      return fields.join(",");
    } else {
      return columns;
    }
  };

  static FormatDateToUTC = function (date, time) {
    let a = time ? date + " " + time : date; //"2020-08-22 23:30:00";
    let x = 0,
      y = 0;
    //let a="2020-08-22 23:30:00";
    if (a) {
      a = new Date(a.replace(/-/g, "/"));
      //a = new Date(a);
      a.setHours(a.getHours() + 5);
      a.setMinutes(a.getMinutes() + 30);

      let b = new Date(a);
      x = b.getDate() < 10 ? "0" + b.getDate() : b.getDate();

      y = b.getMonth() + 1 < 10 ? "0" + (b.getMonth() + 1) : b.getMonth() + 1;

      // return b.getDate() + "-" + (b.getMonth() + 1) + "-" + b.getFullYear();
      return x + "-" + y + "-" + b.getFullYear();
    } else {
      return null;
    }
  };

  static changeDateFormat = function (inputDate) {
    // expects Y-m-d
    if (inputDate) {
      var splitDate = inputDate.split("-");

      if (splitDate?.count == 0) {
        return null;
      }

      var year = splitDate[0];
      var month = splitDate[1];
      var day = splitDate[2];

      return day + "-" + month + "-" + year;
    } else {
      return null;
    }
  };
  static changeDateFormatDate = function (inputDate) {
    // expects Y-m-d
    if (inputDate) {
      var splitDate1 = inputDate.split(" ");
      var splitDate = splitDate1[0].split("-");
      if (splitDate.count == 0) {
        return null;
      }

      var day = splitDate[0];
      var month = splitDate[1];
      var year = splitDate[2];

      return year + "-" + month + "-" + day;
    } else {
      return null;
    }
  };

  static changeDateFormatDateDDMM = function (inputDate) {
    // expects Y-m-d
    var splitDate1 = inputDate.split(" ");
    var splitDate = splitDate1[0].split("-");
    if (splitDate.count == 0) {
      return null;
    }

    var day = splitDate[0];
    var month = splitDate[1];
    var year = splitDate[2];

    return day + "-" + month + "-" + year;
  };

  static changeDateFormatDateMMDD = function (inputDate) {
    // expects Y-m-d
    var splitDate1 = inputDate.split(" ");
    var splitDate = splitDate1[0].split("-");
    if (splitDate.count == 0) {
      return null;
    }

    var day = splitDate[0];
    var month = splitDate[1];
    var year = splitDate[2];

    return month + "-" + day + "-" + year;
  };

  static changeDateFormatDateMMDDWithTime = function (inputDate) {
    // expects Y-m-d

    var splitDate1 = inputDate?.split(" ");
    var splitDate = splitDate1[0]?.split("-");
    var splitTime = splitDate1[1]?.split(":");
    if (splitDate?.length == 0) {
      return null;
    }

    var day = splitDate[0];
    var month = splitDate[1];
    var year = splitDate[2];

    var hh = splitTime[0];
    var mm = splitTime[1];
    var ss = splitTime[2];

    return (
      year + "-" + month + "-" + day + "T" + hh + ":" + mm + ":" + ss + "Z"
    );
  };
  static changeDateToLocalFormat = function (inputDate) {
    // expects Y-m-d
    var splitDate1 = inputDate.split(" ");
    var splitDate = splitDate1[0].split("-");
    var splitTime = splitDate1[1].split(":");
    if (splitDate.count == 0) {
      return null;
    }

    var day = splitDate[0];
    var month = splitDate[1];
    var year = splitDate[2];

    var hh = splitTime[0];
    var mm = splitTime[1];
    var ss = splitTime[2];
    //2021-02-15
    // moment(res.data.eventDate + " " + res.data.eventTime).format(
    //   "YYYY-MM-DDTHH:mm"
    // );
    let d = moment(
      year + "-" + month + "-" + day + " " + hh + ":" + mm + ":" + ss
    ).format("YYYY-MM-DDTHH:mm");
    return d;
    //return year + "-" + month + "-" + day + "T" + hh + ":" + mm;
  };

  static changeDateFormatDateMMDDWithTimeDDMM = function (inputDate) {
    // expects DD:MM:YY:HH:MM:SS  => DD:MM:YY
    var splitDate1 = inputDate.split(" ");
    var splitDate = splitDate1[0].split("-");
    var splitTime = splitDate1[1].split(":");
    if (splitDate.count == 0) {
      return null;
    }

    var day = splitDate[0];
    var month = splitDate[1];
    var year = splitDate[2];

    var hh = splitTime[0];
    var mm = splitTime[1];
    var ss = splitTime[2];

    return day + "-" + month + "-" + year;
  };
  static getFormattedKM = function (distance) {
    let data = distance?.split(".");
    if (data[1] === "00") {
      return data[0];
    } else {
      return distance;
    }
  };
  static getFormatedDistance = function (distance) {
    if (distance) return parseInt(distance);
  };
  static getCamelCasing = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toUpperCase() : word.toLowerCase();
      })
      .replace(/\s+/g, "");
  };

  static objectReorder = (obj, order) => {
    var newObject = {};
    for (var i = 0; i < order.length; i++) {
      if (obj.hasOwnProperty(order[i].trim())) {
        newObject[order[i]] = obj[order[i].trim()];
      }
    }
    return newObject;
  };

  static getAspectRatio(x, y) {
    function gcd(a, b) {
      if (b > a) {
        let temp;
        temp = a;
        a = b;
        b = temp;
      }
      while (b != 0) {
        let m;
        m = a % b;
        a = b;
        b = m;
      }
      return a;
    }

    let c = gcd(x, y);
    return "" + x / c + ":" + y / c;
  }
  static dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  static paymentGatewayHandler = (
    data,
    captureHandler,
    failureHandler,
    dismissHandler
  ) => {
    const options = {
      key: ApplicationConstants.paymentGatewayKey,
      name: data.applicationName,
      description: "",
      order_id: data.orderId,
      // image: { logo },
      prefill: {
        email: data.participantEmail,
        contact: data.contact,
      },
      theme: {
        color: "#686CFD",
      },

      modal: {
        //when the modal is closed by the user.
        ondismiss: function () {
          if (dismissHandler) {
            dismissHandler();
          }
        },
      },
      // retry: false,
      // success handler
      handler: async (response) => {
        try {
          let requestCaptureParams = {};

          requestCaptureParams.amount = data.amount;
          requestCaptureParams.orderId = response.razorpay_order_id;
          requestCaptureParams.paymentId = response.razorpay_payment_id;
          requestCaptureParams.signature = response.razorpay_signature;

          requestCaptureParams.transactionID = response.razorpay_payment_id;

          captureHandler(requestCaptureParams);
        } catch (err) {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Failed to process the payment.", //Razorpay
          });
        }
      },
    };

    const razorpay = new window.Razorpay(options);

    openRazorpayPayment();
    function openRazorpayPayment() {
      razorpay.open();
    }
    function destroyRazorpay() {
      if (razorpay) {
        razorpay.destroy();
        razorpay = null;
        // Clean up any other associated resources
      }
    }

    // rzp1.open();
    razorpay.on("payment.failed", function (response) {
      let requestCaptureParams = {};
      requestCaptureParams.amount = data.amount;
      requestCaptureParams.orderId = response.razorpay_order_id;
      requestCaptureParams.paymentId = response.razorpay_payment_id;
      requestCaptureParams.signature = response.razorpay_signature;

      requestCaptureParams.transactionID = response.razorpay_payment_id;
      requestCaptureParams.transactionStatus = "Success";
      let runnerData = ApplicationUtil.getRunnerDetails();
      let runnerId = runnerData?.runner?.id; //localStorage.getItem("runnerId");
      let eventId = ApplicationUtil.getRunnersEventID(); //localStorage.getItem("eventId");

      requestCaptureParams.runnerId = runnerId;
      requestCaptureParams.eventId = eventId;
      requestCaptureParams.response = response;
      failureHandler(response);
      destroyRazorpay();
      // captureHandler(requestCaptureParams);
    });
  };

  static getFileNameFromHeaders(contentDisposition) {
    var filename = contentDisposition
      ?.split(";")[1]
      ?.split("filename")[1]
      ?.split("=")[1]
      ?.trim();

    return filename;
  }
  //react native webview common function
  //message should be STRING format
  static ReactNative(message) {
    if (window.ReactNativeWebView?.postMessage) {
      if (message) {
        window.ReactNativeWebView.postMessage(message);
      }
      return true;
    } else {
      return false;
    }
  }

  static getRedirectedRoute(url) {
    url = url.split("#");

    if (url[0] === "/eventDetails") {
      let shorted = url[1].split("=");

      // localStorage.setItem("eventId", shorted[1]);
      store.dispatch(updateUserSelectedEvent({ id: shorted[1] }));
      return url[0];
    } else if (url[0] === "/summary") {
      let shorted = url[1].split("=");

      let summaryUrl = shorted[1].split("/");
      let runnerId = shorted[2];
      let localRunnerId = localStorage.getItem("runnerId");
      // localStorage.setItem(
      //   "eventId",
      //   summaryUrl[0]
      //   // shorted[1]
      // );
      // let profileData = ApplicationUtil.getRunnerDetails();
      // let newData = { runner: { id: runnerId } };
      // profileData = { ...profileData, newData };
      // store.dispatch(updateUserDetails({ profileData }));
      store.dispatch(updateUserSelectedEvent({ id: summaryUrl[0] }));
      if (runnerId === undefined) {
        runnerId = localRunnerId;
      }
      localStorage.setItem("summaryRunnerId", runnerId);
      return runnerId === undefined || runnerId === null ? false : url[0];
    } else if (url[0] === "/consent") {
      let shorted = url[1].split("/");
      let eventId = shorted[0].split("eventId=")[1];
      let userId = shorted[1].split("userId=")[1];
      let runnerId = shorted[2].split("runnerId=")[1];

      let localUserId = localStorage.getItem("userId");
      sessionStorage.setItem(
        "consentEventId",
        eventId
        // shorted[1]
      );
      if (userId === undefined) {
        userId = localUserId;
      }
      sessionStorage.setItem("consentUserId", userId);
      return userId === undefined || userId === null ? false : url[0];
    } else if (url[0] === "/player") {
      let shorted = url[1].split("/");
      let eventId = shorted[0].split("eventId=")[1];
      let link = url[2].split("videoLink=")[1];

      let filename = "";
      if (url[3]) {
        filename = url[3].split("name=")[1];
      }
      let source = "";
      // localStorage.setItem("eventId", shorted[1]);
      store.dispatch(updateUserSelectedEvent({ id: eventId }));
      store.dispatch(
        updateUserLink({
          link: link,
          source: source,
          filename: filename,
        })
      );
      return url[0];
    } else if (url[0] === "/event") {
      return `${url[0]}?${url[1]}`;
    } else if (url[0] === "/program") {
      return `${url[0]}?${url[1]}`;
    }
  }

  static getZoomlevel(totalDistance) {
    let zoomLevel = 7;
    if (totalDistance > 9244649) {
      zoomLevel = 4;
    } else if (totalDistance > 4622324) {
      zoomLevel = 6;
    } else if (totalDistance > 2311162) {
      zoomLevel = 6;
    } else if (totalDistance > 1155581) {
      zoomLevel = 6;
    } else if (totalDistance > 577790) {
      //till 1100km
      zoomLevel = 6;
    } else if (totalDistance > 328895) {
      zoomLevel = 6;
    } else if (totalDistance > 248895) {
      //till 320km
      zoomLevel = 6;
    } else if (totalDistance > 118000) {
      zoomLevel = 7;
    } else if (totalDistance > 90000) {
      zoomLevel = 8;
    } else if (totalDistance > 59000) {
      //till 120-59Km
      zoomLevel = 9;
    } else if (totalDistance > 33000) {
      //64-33Km
      zoomLevel = 10;
    } else if (totalDistance > 16000) {
      //18Km
      zoomLevel = 11;
    } else if (totalDistance > 8000) {
      zoomLevel = 11;
    } else if (totalDistance > 4513) {
      zoomLevel = 13;
    } else if (totalDistance > 2256) {
      zoomLevel = 14;
    } else if (totalDistance > 1128) {
      //1Km
      zoomLevel = 15;
    } else {
      //default

      zoomLevel = 15;
    }

    return zoomLevel;
  }

  static dynamicColors() {
    // return (
    //   "hsl(" +
    //   360 * Math.random() +
    //   "," +
    //   (25 + 70 * Math.random()) +
    //   "%," +
    //   (65 + 10 * Math.random()) +
    //   "%)"
    // );
    return "#" + (((1 << 24) * Math.random()) | 0).toString(16);
  }
  static pinSymbol(color) {
    return {
      path:
        // "M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z",
        "M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z",
      // "M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z",
      // "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
      fillColor: color,
      fillOpacity: 0.9,
      strokeColor: color,
      strokeWeight: 1,
      scale: 0.4,
    };
  }
  static async downloadImage(imageSrc) {
    const image = await fetch(imageSrc);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "Summary.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  static async navigatorShare(details) {
    const { imageSrc, title, url, text } = details;
    const response = await fetch(imageSrc);
    const blob = await response.blob();
    const filesArray = [
      new File([blob], "summary.png", {
        type: blob.type,
        lastModified: new Date().getTime(),
      }),
    ];

    const shareData = {
      files: filesArray,
      title: title,
      text: text,
    };
    if (url) {
      shareData.url = url;
    }

    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log("Error: ", err);
    }
  }

  static validateContactNumber(contact) {
    if (contact.length >= 9 && contact.length <= 12) {
      return true;
    } else {
      return false;
    }
  }

  static getCountryFlag(code) {
    let flags = flagList;
    let key = code.toLowerCase();

    let flag = flags[key];
    return flag; //<Icon width={size} height={size} fill={color} />;
  }

  static checkPhysicalEvent(eventParticipantType, showParticipationType) {
    if (eventParticipantType === "PHYSICAL") {
      return true;
    } else if (eventParticipantType === "VIRTUAL") {
      return false;
    } else if (eventParticipantType === "REGULAR") {
      return false;
    } else if (
      showParticipationType &&
      eventParticipantType === "REGULAR_PHYSICAL"
    ) {
      return true;
    } else if (eventParticipantType === "ALL") {
      return false;
    } else {
      return true;
    }
  }

  static formatDesc = (desc, url) => {
    let parts = desc.split("$$");
    let msg = "";
    let newURL =
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";
    parts.map((part) => {
      if (part === "videoLink") {
        if (url !== undefined) {
          msg =
            msg +
            `<a href=${this.getAPI_URL()}` +
            "#/player?link=" +
            `${url}>${url}</a>`;
          //${url}
        }
      } else {
        msg = msg + `<span >${part}</span>`;
      }
    });

    return ReactHtmlParser(`${msg}`);
  };

  static getDateParts = function (inputDate) {
    if (inputDate) {
      var splitDate = inputDate.split("-");
      if (splitDate?.count == 0) {
        return null;
      }
      var day = splitDate[0];
      var month = splitDate[1];
      var year = splitDate[2];

      return [year, month - 1, day];
    }
  };

  static getAPI_URL = function () {
    //dev
    // return "http://localhost:3000/";

    //QA
    return "https://192.168.1.49:8443/";

    //prod
    // return "https://events.necessarydevil.com/";
  };

  static getHistoryObject = function () {
    let url = window.location.href;
    // if (url.includes('/#/')) {
    //   return createHashHistory({
    //     hashType: "slash",
    //     //alwaysEnableState: true
    //     createHref: (location) =>
    //      this.basename + this.createPath(location),
    //   });
    // } else {
    return createBrowserHistory();
    // }
  };

  static getCooperTestFullScore = function ({ gender, age }) {
    if (gender?.toLowerCase() === "male") {
      if (age >= 20 && age <= 29) {
        return 2800;
      }
      if (age >= 30 && age <= 39) {
        return 2700;
      }
      if (age >= 40 && age <= 49) {
        return 2500;
      }
      if (age >= 50) {
        return 2400;
      }
    }
    if (gender?.toLowerCase() === "female") {
      if (age >= 20 && age <= 29) {
        return 2700;
      }
      if (age >= 30 && age <= 39) {
        return 2500;
      }
      if (age >= 40 && age <= 49) {
        return 2300;
      }
      if (age >= 50) {
        return 2200;
      }
    }
  };
  static hideMedal_Rank = function (eventId) {
    if (httpService.API_URL() === ApplicationConstants.prod_url) {
      return ApplicationConstants.hideMedal_RanksEventIds_PROD.includes(
        eventId
      );
    } else {
      return ApplicationConstants.hideMedal_RanksEventIds_QA.includes(eventId);
    }
  };

  static isCohortBasedEventId = function (eventId) {
    if (httpService.API_URL() === ApplicationConstants.prod_url) {
      return ApplicationConstants.cohortBasedLeaderBoard_PROD.includes(eventId);
    } else {
      return ApplicationConstants.cohortBasedLeaderBoard_QA.includes(eventId);
    }
  };
  static formatNumberWithCommas(number) {
    return number.toLocaleString("en-IN");
    // return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  static applicationPaths = {
    "/": ["/"],
    "/eventlist": ["/"],
    "/event": ["/", "/event"],
    "/completeEventList": ["/"],
    "/videoGallery": ["/", "/videoGallery"],
    "/uploadrun": ["/", "/eventDetails", "/uploadrun"],
    "/profile": ["/", "/profile"],
    "/editProfile": ["/", "/editProfile"],
    "/editProfilePicture": ["/", "/editProfilePicture"],
    "/consent": ["/", "/consent"],
    "/postRegister": ["/", "/postRegister"],
    "/invitationForm": ["/", "/invitationForm"],
    "/invitationList": ["/", "/invitationList"],
    "/wellnessDetails": ["/", "/eventDetails", "/wellnessDetails"],
    "/refer": ["/", "/refer"],
    "/challenge": ["/", "/challenge"],
    "/challengeRegistration": ["/", "/challengeRegistration"],
    "/training": ["/", "/eventDetails", "/training"],
    "/summary": ["/", "/eventDetails", "/summary"],
    "/activitydetailsview": ["/", "/eventDetails", "/activitydetailsview"],
    "/feedback": ["/", "/eventDetails", "/feedback"],
    "/results": ["/", "/eventDetails", "/results"],
    "/calender": ["/", "/program", "/calender"],
    "/programDashboard": ["/", "/program", "/calender", "/programDashboard"],
    "/programResults": ["/", "/program", "/calender", "/programResults"],
    "/eventDetails": ["/", "/eventDetails"],
    "/eventRegistration": ["/", "/eventRegistration"],
    "/programRegistration": ["/", "/eventRegistration"],
    "/program": ["/", "/program"],
    "/record-response": ["/", "/program", "/calender", "/record-response"],
    "/challengeView": ["/", "/program", "/calender", "/challengeView"],
  };
  static getPathnamesArray(pathname) {
    return ApplicationUtil.applicationPaths[pathname];
  }

  static getCurrentRunningProgramChallenge(data) {
    return data?.find((challenge) => {
      const startDate = challenge?.challengeStartDate?.split(" ")[0];
      const endDate = challenge?.challengeEndDate?.split(" ")[0];
      const today = moment().format("DD-MM-YYYY");

      if (
        moment(today, "DD-MM-YYYY").isSameOrAfter(
          moment(startDate, "DD-MM-YYYY"),
          true
        ) &&
        moment(today, "DD-MM-YYYY").isSameOrBefore(
          moment(endDate, "DD-MM-YYYY"),
          true
        )
      ) {
        return challenge;
      }
    });
  }

  static getChallengeEndedYesterday(data) {
    return data?.find((challenge) => {
      const startDate = challenge?.challengeStartDate?.split(" ")[0];
      const endDate = challenge?.challengeEndDate?.split(" ")[0];
      const yesterday = moment().subtract(1, "day").format("DD-MM-YYYY");

      if (
        moment(yesterday, "DD-MM-YYYY").isSame(
          moment(endDate, "DD-MM-YYYY"),
          true
        )
      ) {
        return challenge;
      }
    });
  }

  static getYesterdaysQue = ({ questionnaires }) => {
    return questionnaires?.filter((que) => {
      let yesterday = moment().subtract(1, "day").format("DD-MM-YYYY");
      let viewDate = que?.programChallengeQuestionDto?.viewDate?.split(" ")[0];

      let condition = moment(yesterday, "DD-MM-YYYY").isSame(
        moment(viewDate, "DD-MM-YYYY")
      );
      if (condition) {
        return que;
      }
    });
  };
  static getUpcomingProgramChallenge(data) {
    return data?.find((challenge) => {
      const startDate = challenge?.challengeStartDate?.split(" ")[0];
      const today = moment().format("DD-MM-YYYY");

      if (
        moment(today, "DD-MM-YYYY").isBefore(
          moment(startDate, "DD-MM-YYYY"),
          true
        )
      ) {
        return challenge;
      }
    });
  }

  static formattedDate(date) {
    return moment(date, "DD-MM-YYYY HH:mm:ss").format("Do MMM");
  }

  static getChallengeFromId(data, challengeId) {
    return data?.find((challenge) => {
      if (challenge?.id == challengeId) {
        return challenge;
      }
    });
  }
  static formatLabelValuesFromArray(data) {
    if (data?.length) {
      const labelValues = data?.map((item) => {
        return { label: item, value: item };
      });
      return labelValues;
    }
    return [];
  }
  /**
   * For disabling Participant to record response
   * @param {Object} question
   * @param {Boolean} response
   * @returns boolean value
   */
  static handleFieldDisable = (question, response) => {
    if (question?.responseSubmissionEndDate) {
      const isSubmissionAllowed = moment()
        .startOf("day")
        .isSameOrBefore(
          moment(
            question?.responseSubmissionEndDate?.split(" ")[0],
            "DD-MM-YYYY"
          ),
          true
        );
      if (isSubmissionAllowed) {
        return response ? true : false;
      } else return true;
    } else return response ? true : false;
  };

  /**
   * For disable the onScroll textfield of type number
   * increase and decrease count of textfield,
   * without removing textfield focus
   * this can be done by using onWheel function but,
   * but onWheel removes the inpt focus also so used below approach
   * @param {*} e
   * @returns
   */
  static disabledIncreaseDecreaseTextFieldCountOnScroll = (e) => {
    return e.target.addEventListener(
      "wheel",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
  };

  /**
   * Vishal
   * to extract the plain html string to the plain innerText
   * @param {*} html
   * @returns
   */
  static _extractPlainHtmlToPlainString = (html) => {
    return html
      ? new DOMParser().parseFromString(html, "text/html").documentElement
          .textContent
      : null;
  };
}
export default ApplicationUtil;
