import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  Slider,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { submitResponse } from "../../../../apis/programService";
import { useSelector } from "react-redux";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import Snackbar from "../../../common/Snackbar";
import SingleSelectQue from "./SingleSelectQue";
import MultiSelectQue from "./MultiSelectQue";
import Properties from "../../../../properties/Properties";
import NumericalQue from "./NumericalQue";
import { CiCircleInfo } from "react-icons/ci";
import MultiSelect from "../../../common/MultiSelect";
import ApplicationConstants from "../../../../constants/ApplicationConstants";

function QuestionComponent({
  classes,
  history,
  viewDate,
  setQueArray,
  queArray,
  currentQueIndex,
  setCurrentQueIndex,
  getGraphData,
  initialLoad,
  challengeId,
}) {
  const [quizResponse, setQuizResponse] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [activityLevel, setActivityLevel] = useState(null);
  const [fieldsError, setFieldsError] = useState({
    activityLevelError: false,
    currentClimateError: false,
  });
  const [activityLevelList, setActivityLevelList] = useState([]);
  const [currentClimate, setCurrentClimate] = useState();
  const [climateList, setClimateList] = useState([]);
  const [quizError, setQuizError] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState("Liters");
  const [otherValue, setOtherValue] = useState("");
  const [approxGlassNote, setApproxGlassNote] = useState(false);
  const selectedQueBlock = useSelector((state) => state.user.selectedQueBlock);
  const urlParams = new URLSearchParams(window.location.search);
  let isQueBlock = Boolean(urlParams.get("queBlock"));
  let runnerData = ApplicationUtil.getRunnerDetails();
  const currentRunningProgramChallenge = useSelector(
    (state) => state.user.currentRunningProgramChallenge
  );
  const categoryType =
    queArray[currentQueIndex]?.questionResponseType?.categoryType;
  const isTextCategory = categoryType == "Text";
  const isSingleSelectType =
    queArray[currentQueIndex]?.expectedOption?.length === 1;
  const isRangeCategory = categoryType == "Range";
  const isNumericalCategory = categoryType == "Numerical";

  const isHydration =
    queArray[currentQueIndex]?.programChallengeType === "HYDRATION";

  const handleOldResponse = () => {
    const response = queArray[currentQueIndex]?.response;
    const lifeStyles = queArray[currentQueIndex]?.lifeStyles;
    const selectedLifeStyle = queArray[currentQueIndex]?.lifeStyle;
    const climates = queArray[currentQueIndex]?.climates;
    const selectedClimate = queArray[currentQueIndex]?.currentClimate;

    if (response) {
      if (isNumericalCategory) {
        const value = response?.split(" ")[0];
        const unit = response?.split(" ")[1];
        setQuizResponse(value);
        setSelectedUnit(unit);
      }

      if (isTextCategory) {
        const isSingleSelect =
          queArray[currentQueIndex]?.expectedOption?.length === 1;
        if (!isSingleSelect) {
          const splittedValue = response?.split(";");
          const otherText = fetchUserText(splittedValue);
          setSelectedValues(splittedValue);
          setOtherValue(otherText);
        } else {
          setQuizResponse(response);
        }
      } else {
        setQuizResponse(response);
      }
    } else if (isRangeCategory) {
      const min = Number(queArray[currentQueIndex]?.range?.split("-")[0] || 0);
      setQuizResponse(min);
    }

    if (selectedLifeStyle) {
      setActivityLevel({
        value: selectedLifeStyle,
        label: selectedLifeStyle?.replace("_", " "),
      });
    }
    if (lifeStyles && !selectedLifeStyle) {
      setActivityLevelList(() => {
        return lifeStyles?.map((item) => {
          return {
            value: item,
            label: ApplicationUtil.capitalize(item?.replace("_", " ")),
          };
        });
      });
      //Vishal - Defect_Id_755 - Below committed code - Removed by default select value of activity

      // setActivityLevel({
      //   value: lifeStyles[0],
      //   label: ApplicationUtil.capitalize(lifeStyles[0]?.replace("_", " ")),
      // });
    }

    if (selectedClimate) {
      setCurrentClimate({
        value: selectedClimate,
        label: selectedClimate?.replace("_", " "),
      });
    }

    setClimateList(() => {
      return climates?.map((item) => {
        return {
          value: item,
          label: ApplicationUtil.capitalize(item?.replace("_", " ")),
        };
      });
    });
    if (climates && selectedClimate) {
      //   setClimateList(() => {
      //     return climates?.map((item) => {
      //       return {
      //         value: item,
      //         label: ApplicationUtil.capitalize(item?.replace("_", " ")),
      //       };
      //     });
      //   });
      setCurrentClimate({
        value: selectedClimate,
        label: selectedClimate?.replace("_", " "),
      });
    }
  };

  const fetchUserText = (items) => {
    return items
      .map((item) => {
        if (item.includes("Any other $$ ")) {
          let splittedParts = item.split(" $$ ");
          return splittedParts[1];
        } else {
          return null;
        }
      })
      .filter((removeNull) => removeNull !== null);
  };

  const handleSliderChange = (event, newValue) => {
    setQuizError(false);
    setQuizResponse(newValue);
  };

  const handleInputChange = (event) => {
    setQuizError(false);
    setQuizResponse(event.target.value === "" ? 0 : Number(event.target.value));
  };

  const handleUnitSelect = (value) => {
    setSelectedUnit(value?.value);
    value?.value === "Glass"
      ? setApproxGlassNote(true)
      : setApproxGlassNote(false);
    //reset quiz response whenever unit change
    setQuizResponse(null);
  };

  const handleBlur = () => {
    if (quizResponse < 0) {
      setQuizResponse(0);
    } else if (quizResponse > 100) {
      setQuizResponse(100);
    }
  };

  const handleSubmitResponse = () => {
    const isSingleSelect =
      queArray[currentQueIndex]?.expectedOption?.length === 1;
    let response = quizResponse;
    
    if (isTextCategory) {
      if (isSingleSelect && !quizResponse) {
        setQuizError(true);
        return;
      }

      if (!isSingleSelect && selectedValues?.length === 0) {
        setQuizError(true);
        return;
      }
      response = isSingleSelect ? quizResponse : selectedValues?.join(";");
    }

    if (isRangeCategory) {
      if (!quizResponse) {
        setQuizError(true);
        return;
      }
    }
    if (isNumericalCategory) {
      if (!quizResponse) {
        setQuizError(true);
        return;
      }
      response = quizResponse;
      if (queArray[currentQueIndex]?.showUnit) {
        response = quizResponse + " " + selectedUnit || "";
      }
    }

    setQuizError(false);
    let runnerId = runnerData?.runner?.id;
    let params = {
      questionId: queArray[currentQueIndex]?.id,
      programChallengeId: challengeId,
      runnerId: runnerId,
      response: response,
      viewDate: viewDate,
    };
    if (isHydration && (!activityLevel || !currentClimate)) {
      !activityLevel
        ? setFieldsError({ ...fieldsError, activityLevelError: true })
        : setFieldsError({ ...fieldsError, currentClimateError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: !activityLevel
          ? "Please Select Activity !!"
          : "Please Select Climate",
      });
    } else {
      if (isHydration && activityLevel) {
        params.lifeStyle = activityLevel?.value;
        params.currentClimate = currentClimate?.value;
      }
      submitResponse(params).then(({ data }) => {
        if (data?.httpResponse?.code === "200") {
          if (data?.question) {
            getGraphData();
            let temp = queArray.filter(
              (que) => que.id == queArray[currentQueIndex]?.id
            );
            temp = temp[0];
            if (isTextCategory) {
              temp.response = isSingleSelect
                ? quizResponse
                : selectedValues?.join(";");
            }
            if (isNumericalCategory) {
              temp.response = response;
            }

            let tempArray = [...queArray, temp];
            tempArray = tempArray.filter((obj, index) => {
              return index === tempArray.findIndex((o) => obj.id === o.id);
            });
            setQueArray([...tempArray, data]);
            setCurrentQueIndex(currentQueIndex + 1);
            setQuizResponse(null);
          } else {
            // let temp = queArray.filter(
            //   (que) => que.id == queArray[currentQueIndex]?.id
            // );
            // temp = temp[0];
            // if (isTextCategory) {
            //   temp.response = isSingleSelect
            //     ? quizResponse
            //     : selectedValues?.join(";");
            // }
            // if (isNumericalCategory || isRangeCategory) {
            //   temp.response = response;
            // }
            // let tempArray = [...queArray, temp];
            // tempArray = tempArray.filter((obj, index) => {
            //   return index === tempArray.findIndex((o) => obj.id === o.id);
            // });
            // setQueArray([...tempArray]);
            initialLoad(challengeId, viewDate);
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Response Submitted Successfully!",
            });
          }
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: data?.httpResponse?.verbose
              ? data?.httpResponse?.verbose
              : Properties.Something_went_wrong,
          });
        }
      });
    }
  };

  const handleNext = () => {
    setCurrentQueIndex(currentQueIndex + 1);
  };
  const handlePrev = () => {
    setQuizError(false);
    setCurrentQueIndex(currentQueIndex - 1);
  };

  /**
   * handleNumericalChange
   * Check the selected unit type and according to that pass the value and maxVal
   * to input setter function
   * @param {*} event
   */
  const handleNumericalChange = (event) => {
    const { value } = event.target;
    if (isHydration && value == 0) {
      // Vishal defect 842
      setQuizError(true);
      setQuizResponse(null);
    } else {
      setQuizError(false);
      //vishal - defect_id_667 - validation for user input
      if (selectedUnit === "Liters") {
        if (value && value <= 10) {
          //Ankit - Defect_id_859 for liters upto two decimal digit
          setQuizResponse(Number(value).toFixed(2));
        }
      } else if (selectedUnit === "Glass") {
        let _val = parseInt(event.target.value)?.toString();
        if (_val && _val <= 20) {
          setQuizResponse(_val);
        }
      } else {
        setQuizResponse(value);
      }
    }
  };

  const handleMultiSelectChange = (value) => {
    if (selectedValues.includes(value)) {
      let newSelectedValues = selectedValues.filter((v) => v !== value);
      if (value === "Any other") {
        newSelectedValues = newSelectedValues.filter(
          (v) => !v.startsWith("Any")
        );
      }
      setSelectedValues(newSelectedValues);
    } else {
      setSelectedValues([...selectedValues, value]);
    }

    if (value === "None of these") {
      if (selectedValues.includes("None of these")) {
        setSelectedValues([]);
      } else {
        setSelectedValues(["None of these"]);
      }
    } else {
      if (selectedValues.includes(value)) {
        setSelectedValues(selectedValues.filter((v) => v !== value));
      } else {
        setSelectedValues([
          ...selectedValues.filter((v) => v !== "None of these"),
          value,
        ]);
      }
    }
  };

  useEffect(() => {
    handleOldResponse();
  }, [queArray, currentQueIndex]);

  return (
    <div className={classes.que_container}>
      <>
        <div className="questionBox">
          {queArray[currentQueIndex]?.question
            ? queArray[currentQueIndex]?.question +
              (queArray[currentQueIndex]?.question?.includes("?") ? "" : " ?")
            : ""}
        </div>
        {isTextCategory && (
          <>
            {isSingleSelectType ? (
              <SingleSelectQue
                quizResponse={quizResponse}
                setQuizResponse={setQuizResponse}
                options={queArray[currentQueIndex]?.options}
                response={queArray[currentQueIndex].response}
                question={queArray[currentQueIndex]}
              />
            ) : (
              <>
                {/* multipleOptions */}
                <MultiSelectQue
                  options={queArray[currentQueIndex]?.options}
                  selectedValues={selectedValues}
                  response={queArray[currentQueIndex].response}
                  handleMultiSelectChange={handleMultiSelectChange}
                  setSelectedValues={setSelectedValues}
                  question={queArray[currentQueIndex]}
                  otherValue={otherValue}
                  setOtherValue={setOtherValue}
                />
              </>
            )}
          </>
        )}

        {isRangeCategory && (
          <RangeSelector
            range={queArray[currentQueIndex]?.range || "0-7"}
            handleBlur={handleBlur}
            handleSliderChange={handleSliderChange}
            quizResponse={quizResponse}
            response={queArray[currentQueIndex].response}
            question={queArray[currentQueIndex]}
            handleInputChange={handleInputChange}
          />
        )}

        {isNumericalCategory && (
          <NumericalQue
            question={queArray[currentQueIndex]}
            quizResponse={quizResponse?.split(" ")[0]}
            handleChange={handleNumericalChange}
            response={queArray[currentQueIndex].response?.split(" ")[0]}
            handleUnitSelect={handleUnitSelect}
            selectedUnit={selectedUnit}
            approxGlassNote={approxGlassNote}
          />
        )}

        {quizError && (
          <p style={{ color: "red", margin: "5px 0" }}>
            {Properties.select_response_label}
          </p>
        )}

        {approxGlassNote && (
          <FormHelperText style={{ margin: "0px !important" }}>
            {Properties.conversion_helper_text}
          </FormHelperText>
        )}

        {queArray[0]?.programChallengeType === "HYDRATION" && (
          <Grid className={classes.activity_climate_list_container}>
            <Grid xs={12} md={3} style={{ marginBottom: "10px" }}>
              <Typography
                variant="body2"
                gutterBottom
                style={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ color: "#727272" }}>Activity</span>
                <span style={{ color: "red" }}>*</span>

                <Tooltip
                  enterTouchDelay={0}
                  title={Properties.activity_level_tip}
                >
                  <IconButton style={{ padding: 0 }}>
                    <CiCircleInfo size={15} />
                  </IconButton>
                </Tooltip>
              </Typography>

              <MultiSelect
                isMenuMaxHeight={true}
                value={activityLevel}
                isReadOnly={ApplicationUtil.handleFieldDisable(
                  queArray[currentQueIndex],
                  queArray[currentQueIndex]?.response
                )}
                options={activityLevelList}
                onChange={(event) => {
                  setActivityLevel(event);
                  setFieldsError({ ...fieldsError, activityLevelError: false });
                }}
                error={fieldsError?.activityLevelError}
              />
            </Grid>

            <Grid xs={12} md={3} style={{ marginBottom: "10px" }}>
              <Typography
                variant="body2"
                gutterBottom
                style={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ color: "#727272" }}>Climate</span>
                <span style={{ color: "red" }}>*</span>

                <Tooltip enterTouchDelay={0} title={Properties.Climate_tip}>
                  <IconButton style={{ padding: 0 }}>
                    <CiCircleInfo size={15} />
                  </IconButton>
                </Tooltip>
              </Typography>

              <MultiSelect
                // isMenuMaxHeight={true}
                value={currentClimate}
                isReadOnly={ApplicationUtil.handleFieldDisable(
                  queArray[currentQueIndex],
                  queArray[currentQueIndex]?.response
                )}
                options={climateList}
                onChange={(event) => {
                  setCurrentClimate(event);
                  setFieldsError({
                    ...fieldsError,
                    currentClimateError: false,
                  });
                }}
                error={fieldsError?.currentClimateError}
              />
            </Grid>
          </Grid>
        )}

        <div style={{ display: "flex", gap: "5px" }}>
          {/* PREV NEXT BTN in case of response already present */}
          {queArray[currentQueIndex]?.response && queArray.length > 1 ? (
            <>
              <div className={classes.next_prev_btn_container}>
                {currentQueIndex > 0 && (
                  <SubmitCancelBtn
                    label="Prev"
                    type="submit"
                    onClick={handlePrev}
                    style={{ borderRadius: "10px", width: "fit-content" }}
                  />
                )}
                {currentQueIndex !== queArray.length - 1 && (
                  <SubmitCancelBtn
                    label="Next"
                    type="submit"
                    onClick={handleNext}
                    style={{ borderRadius: "10px", width: "fit-content" }}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              {/* PREV, SUBMIT Btn in case of prev is submitted and next one is not */}
              <div className={classes.next_prev_btn_container}>
                {currentQueIndex > 0 && (
                  <SubmitCancelBtn
                    label="Prev"
                    type="submit"
                    onClick={handlePrev}
                    style={{ borderRadius: "10px", width: "fit-content" }}
                  />
                )}

                {!ApplicationUtil.handleFieldDisable(
                  queArray[currentQueIndex],
                  isNumericalCategory
                    ? queArray[currentQueIndex]?.response?.split(" ")[0]
                    : queArray[currentQueIndex]?.response
                ) && (
                  <SubmitCancelBtn
                    label="Submit Response"
                    type="submit"
                    onClick={handleSubmitResponse}
                    style={{ borderRadius: "10px", width: "fit-content" }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </>
    </div>
  );
}
const styles = (theme) => ({
  que_container: {
    // margin: "20px",
    borderRadius: "10px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    background: "linear-gradient(to right, #ebf1f6, #759ac0)",
    // background: "linear-gradient(to right, #fdf5ee, #f2b888)",
    padding: "10px",
    "& .questionBox": {
      color: "black",
      fontWeight: "600",
      fontSize: "x-large",
      "& .formControlLabel": {},
      [theme.breakpoints.down("sm")]: {
        fontSize: "large",
      },
    },
  },
  next_prev_btn_container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  thank_you_container: {
    padding: "20px",
    textAlign: "center",
    "& .title": {
      marginBottom: "10px",
      fontSize: "large",
      fontWeight: "600",
    },
  },

  activity_climate_list_container: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
});
export default compose(withStyles(styles), withRouter)(QuestionComponent);

const RangeSelector = ({
  handleSliderChange,
  quizResponse,
  handleBlur,
  handleInputChange,
  range,
  response,
  question,
}) => {
  const min = Number(range?.split("-")[0] || 0);
  const max = Number(range?.split("-")[1] || 10);
  return (
    <Grid container spacing={1} alignItems="center" className="sliderContainer">
      <Grid item xs={2}>
        <Input
          defaultValue={min}
          value={quizResponse || min}
          size="small"
          onChange={handleInputChange}
          onBlur={handleBlur}
          disabled={ApplicationUtil.handleFieldDisable(question, response)}
          onFocus={(e) =>
            //Vishal -  Defect_Id_669
            ApplicationUtil.disabledIncreaseDecreaseTextFieldCountOnScroll(e)
          }
          inputProps={{
            // step: 10,
            min: min,
            max: max,
            type: "number",
            "aria-labelledby": "input-slider",
          }}
        />
      </Grid>
      <Grid item xs={10}>
        <Slider
          disabled={ApplicationUtil.handleFieldDisable(question, response)}
          value={quizResponse || min}
          min={min}
          max={max}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          style={{ width: "95%", marginInline: "10px" }}
        />
      </Grid>
    </Grid>
  );
};
