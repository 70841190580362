import { Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import parse from "html-react-parser";

function TipComponent({
  classes,
  history,
  tip,
  style,
  hydrationStatus,
  suggestedQuantity,
}) {
  const textColor =
    (hydrationStatus && hydrationStatus.toLowerCase()) === "under hydrated"
      ? "red"
      : (hydrationStatus && hydrationStatus.toLowerCase()) ===
        "adequately hydrated"
      ? "green"
      : "black";

  const displayedStatus =
    (hydrationStatus && hydrationStatus.toLowerCase()) === "under hydrated"
      ? `${hydrationStatus}!`
      : hydrationStatus;

  let displaySuggestedQuantity = suggestedQuantity;

  if (suggestedQuantity && typeof suggestedQuantity === "string") {
    let splittedParts = suggestedQuantity.split(" ");
    if (splittedParts.length === 2 && splittedParts[1] === "Glass") {
      let quantity = parseFloat(splittedParts[0]);
      let roundedQuantity = Math.round(quantity);
      displaySuggestedQuantity = roundedQuantity + " " + splittedParts[1];
    }
  }

  return (
    <div className={classes.container} style={style}>
      {tip ? (
        <div className="tipSection">
          <span className={"title"}>{"Tip of the day"}:</span>
          &nbsp;
          <span> {parse(`${tip}`)}</span>
        </div>
      ) : (
        <p style={{ color: "black" }}>
          <span className={"title"}>{"Hydration Status"}:</span>
          &nbsp;
          <span className="textValueHydration" style={{ color: textColor }}>
            {" "}
            {displayedStatus}
          </span>
          {suggestedQuantity && (
            <p>
              <span className={"title"}>Suggested Quantity:</span>
              &nbsp;
              <span className="textValueHydration">
                {displaySuggestedQuantity}
              </span>
            </p>
          )}
        </p>
      )}
    </div>
  );
}

const styles = (theme) => ({
  container: {
    borderRadius: "10px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    // background: "linear-gradient(to right, #fdf5ee, #f2b888)",
    padding: "10px",
    "& .title": {
      fontWeight: 600,
      fontSize: "22px",
      minWidth: "fit-content",
      [theme.breakpoints.down("sm")]: {
        fontSize: "17px",
      },
    },

    "& .textValue": {
      textTransform: "capitalize",
    },
    "& .textValueHydration": {
      textTransform: "capitalize",
      fontWeight: "bold",
      fontSize: "18px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
    },
    "& .tipSection": {
      color: "black",
      display: "flex",
      alignItems: "baseline",
      gap: "5px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        gap: "0px",
      },
    },
  },
});

export default compose(withStyles(styles), withRouter)(TipComponent);
