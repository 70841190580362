import React, { useEffect, useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import AppToolBar from "../../../common/AppToolBar";
import Properties from "../../../../properties/Properties";
import Header from "../../../common/Header";
import { withRouter } from "react-router-dom";
import {
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { CustomTextField } from "../../../common/CustomTextField";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import MultiSelect from "../../../common/MultiSelect";
import ApplicationConstants from "../../../../constants/ApplicationConstants";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import Snackbar from "../../../common/Snackbar";
import {
  getProgramEventList,
  modifyProgramParticipant,
  registerMultipleEventCategory,
  registerProgram,
  registerProgramSession,
  userRegisteredForProgram,
} from "../../../../apis/eventService";
import httpService from "../../../../apis/httpService";
import { loading } from "../../../common/Spinner";
import OrganizerCommonHeader from "../../support/organizer/OrganizerCommonHeader";
import { validateEmail } from "../../../../utils/Validator";
import { programChallengeRegister } from "../../../../apis/challengeService";
import { CiCircleInfo } from "react-icons/ci";
import { updateUserSelectedProgram } from "../../../../redux/user";
import moment from "moment";
function RegisterProgram({
  classes,
  history,
  modifyForm,
  participantData,
  closeModifyWindow,
  updateParticipantsList,
  updateUserSelectedProgram,
}) {
  const [textFieldValues, setTextFieldValues] = useState({
    // firstName: null,
    // lastName: null,
    // sex: null,
    age: null,
    height: null,
    weight: null,
    bmi: null,
    email: null,
    contactNumber: null,
    // department: null,
    employeeId: null,
  });
  const [errorField, setErrorField] = useState(false);
  const [contactNumberError, setContactNumberError] = useState(false);
  const [termsCheckboxSelected, setTermsCheckboxSelected] = useState(false);
  const [programDetails, setProgramDetails] = useState(null);
  const [selectFieldValues, setSelectFieldValues] = useState({
    selectedMedicalCondition: {},
    climate: ApplicationConstants.climateList[0],
    department: {},
    participantEventsList: [],
    participantChallengeList: [],
    participantSessionList: [],
  });
  const [departmentList, setDepartmentList] = useState();
  let userDetails = ApplicationUtil.getRunnerDetails();
  useEffect(() => {
    if (modifyForm) {
      OrganizerSideModifyForm();
    } else {
      RunnerSideRegisterProgram();
    }
  }, []);


  const OrganizerSideModifyForm = () => {
    let programDetailsFromRedux = ApplicationUtil.getSupportsProgramDetails();
    let showClimate = false;
    let showHeightWeight = false;
    let showEmployeeId = false;
    let depList = [];
    let programEventList = [];
    let programSessionList = [];
    let programChallengeList = [];
    let participantEventsList = [];
    let participantChallengeList = [];
    let participantSessionList = [];

    if (participantData.programData?.departments?.length > 0) {
      depList = participantData.programData?.departments?.map((x) => {
        let object = {
          label: x,
          value: x,
        };
        return object;
      });
    }

    if (participantData?.eventDTOs && participantData?.eventDTOs.length) {
      participantEventsList = participantData?.eventDTOs?.map((event) => {
        return { label: event.name, value: event.id };
      });
    }
    if (
      participantData?.programChallengeDTOs &&
      participantData?.programChallengeDTOs?.length
    ) {
      participantChallengeList = participantData?.programChallengeDTOs?.map(
        (challenge) => {
          return { label: challenge.name, value: challenge.id };
        }
      );
    }
    if (
      participantData?.programSessionList &&
      participantData?.programSessionList.length
    ) {
      participantSessionList = participantData?.programSessionList?.map(
        (event) => {
          return { label: event.name, value: event.id };
        }
      );
    }

    setDepartmentList(depList);

    setSelectFieldValues({
      selectedMedicalCondition: participantData?.medicalCondition,
      department: participantData?.department,
      climate: participantData?.climate,
      participantEventsList,
      participantChallengeList,
      participantSessionList,
    });
    const valuesObject = {
      age: participantData?.runner?.age,
      firstName: participantData?.runner?.firstName,
      lastName: participantData?.runner?.lastName,
      height: participantData?.runner?.height,
      weight: participantData?.runner?.weight,
      bmi: participantData?.runner?.bmi,
      email: participantData?.runner?.email,
      contactNumber: participantData?.runner?.contactNumber,
      employeeId: participantData?.employeeId,
      climate: participantData?.climate,
    };
    if (participantData?.medicalCondition?.value === true) {
      valuesObject.medicalConditionText = participantData?.medDescription;
    }
    setTextFieldValues(valuesObject);
    if (
      participantData?.programData?.eventDTO &&
      participantData?.programData?.eventDTO.length
    ) {
      programEventList = participantData?.programData?.eventDTO?.map(
        (event) => {
          return { label: event.name, value: event.id };
        }
      );
    }
    if (
      participantData?.programData?.programChallenges &&
      participantData?.programData?.programChallenges?.length
    ) {
      programChallengeList =
        participantData?.programData?.programChallenges?.map((challenge) => {
          return { label: challenge.name, value: challenge.id };
        });
    }
    if (
      participantData?.programData?.programSessions &&
      participantData?.programData?.programSessions.length
    ) {
      programSessionList = participantData?.programData?.programSessions?.map(
        (event) => {
          //vishal-defect 530 : Expired session is only for read, not able to select
          const today = new Date();
          const _isSessionExpired =
            event?.sessionEndDate &&
            moment(event?.sessionEndDate, "DD-MM-YYYY HH:mm:ss").isBefore(
              moment(today, "DD-MM-YYYY HH:mm:ss")
            );
          return {
            label: event.name,
            value: event.id,
            isDisabled: _isSessionExpired,
          };
        }
      );
    }
    if (participantData?.programData?.showClimate) {
      showClimate = participantData?.programData?.showClimate;
    }
    if (participantData?.programData?.showHeightWeight) {
      showHeightWeight = participantData?.programData?.showHeightWeight;
    }
    if (participantData?.programData?.showEmployeeId) {
      showEmployeeId = participantData?.programData?.showEmployeeId;
    }

    setProgramDetails({
      ...programDetailsFromRedux,
      programEventList,
      programChallengeList,
      programSessionList,
      showClimate,
      showHeightWeight,
      showEmployeeId
    });
  };

  const RunnerSideRegisterProgram = () => {
    if (!ApplicationUtil.isuserLoggedIn()) {
      history.push("/");
      return;
    }
    let programDetails = ApplicationUtil.getRunnersProgramDetails();

    userRegisteredForProgram(programDetails?.id).then(({ data }) => {
      if (data?.success?.code === "409") {
        getProgramEventList({ programId: programDetails?.id }).then(
          ({ data }) => {
            let depList = [];
            if (data?.departments?.length > 0) {
              depList = data?.departments.map((x) => {
                let object = {
                  label: x,
                  value: x,
                };
                return object;
              });
            }
            setDepartmentList(depList);
            setProgramDetails(data);
            updateUserSelectedProgram(data);
          }
        );

        let details = {
          age: userDetails?.age,
          height: userDetails?.height,
          weight: userDetails?.weight,
          bmi: userDetails?.bmi,
          email: userDetails?.email ? userDetails?.email : null,
          contactNumber: userDetails?.contactNumber,
        };

        setTextFieldValues({ ...textFieldValues, ...details });
      } else {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: `Already registered`,
        });
        // history.push("/calender");
      }
    });
  };
  const onTextHandleChange = (event) => {
    let bmi = textFieldValues?.bmi;
    if (event.target.name === "height" || event.target.name === "weight") {
      let height =
        event.target.name === "height"
          ? event.target.value
          : textFieldValues?.height;
      let weight =
        event.target.name === "weight"
          ? event.target.value
          : textFieldValues?.weight;
      if (height && height > 50 && weight && weight > 0) {
        bmi = Number(weight / ((height / 100) * (height / 100))).toFixed(2);
      }
    }
    if (event.target.name === "contactNumber") {
      setContactNumberError(false);
    }
    setTextFieldValues({
      ...textFieldValues,
      [event.target.name]: event.target.value,
      bmi,
    });
  };
  const onSelectHandleChange = (name, value) => {
    if (name === "selectedMedicalCondition" && value?.value === true) {
      setTextFieldValues({ ...textFieldValues, medicalConditionText: null });
    }
    if (name === "selectedMedicalCondition" && value?.value === false) {
      let object = { ...textFieldValues };
      delete object.medicalConditionText;
      setTextFieldValues(object);
    }
    setSelectFieldValues({ ...selectFieldValues, [name]: value });
  };

  const onSubmit = () => {
    let programDetails = ApplicationUtil.getRunnersProgramDetails();
    const API_URL_FILE = httpService.API_URL();
    const showHeightWeight = programDetails?.showHeightWeight;
    const showEmployeeId = programDetails?.showEmployeeId;
    let allFieldValues = { ...textFieldValues, ...selectFieldValues };
    let fieldValueList = Object.entries(allFieldValues);
    let _finalVal = fieldValueList;

    if (!showHeightWeight) {
      _finalVal = fieldValueList?.filter((item) => {
        const [key, value] = item;
        return key !== "height" && key !== "weight" && key !== "bmi";
      });
    }

    let _finalValUpdated = _finalVal;

    if (departmentList && departmentList.length > 0) {
      _finalValUpdated = _finalVal;
    } else {
      _finalValUpdated = _finalVal?.filter((item) => {
        const [key, value] = item;
        return key !== "department";
      });
    }

    //Ankit Defect_id_899 employee_id not mandatory.
    let validatedValues = _finalValUpdated?.filter((item) => {
      const [key, value] = item;
        return key !== "employeeId";
    });

    let isFieldNotProvided = validatedValues?.filter((x) =>
      x[0] === "selectedMedicalCondition" || x[0] === "department"
        ? x[1].value === undefined
        : !x[1]
    );

    //Defect_id_518 - Laxman
    if (!textFieldValues.contactNumber || !textFieldValues.email) {
      setErrorField(true);
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: `Please provide required fields`,
      });
    }
    //Defect_id_495 - Laxman Nemane
    if (
      textFieldValues.contactNumber &&
      textFieldValues.email &&
      !validateEmail(textFieldValues.email) &&
      !ApplicationUtil.validateContactNumber(textFieldValues.contactNumber)
    ) {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: `Please enter valid email address or contact number`,
      });
    } else if (
      textFieldValues.contactNumber &&
      !ApplicationUtil.validateContactNumber(textFieldValues.contactNumber)
    ) {
      setContactNumberError(true);
      setErrorField(true);
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: `Please enter valid contact number`,
      });
    } else if (textFieldValues.email && !validateEmail(textFieldValues.email)) {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: `Please enter valid email address`,
      });
    } else if (isFieldNotProvided?.length > 0) {
      setErrorField(true);
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: `Please provide required fields`,
      });
    } else {
      setErrorField(false);
      const showClimate = programDetails?.showClimate;
      let params = {
        programId: programDetails?.id,
        userId: userDetails?.id,
        firstName: allFieldValues?.firstName,
        lastName: allFieldValues?.lastName,
        sex: allFieldValues?.sex,
        age: allFieldValues?.age,
        height: allFieldValues?.height,
        weight: allFieldValues?.weight,
        bmi: allFieldValues?.bmi,
        email: allFieldValues?.email,
        contactNumber: allFieldValues?.contactNumber,
        medicalCondition: allFieldValues?.selectedMedicalCondition?.value,
        employeeId: allFieldValues?.employeeId,
        department: allFieldValues?.department?.value,
        climate: allFieldValues?.climate?.value,
      };

      if (!showClimate) {
        delete params.climate;
      }

      if (allFieldValues?.selectedMedicalCondition?.value) {
        params.medDescription = allFieldValues?.medicalConditionText;
      }
      registerProgram(params)
        .then(({ data }) => {
          if (data?.success?.code === "200") {
            //-------------------------------------------------------------
            //-----------------Register Program Events------------------
            //-------------------------------------------------------------

            if (programDetails?.eventDTO) {
              let eventParamArr = [];
              programDetails.eventDTO.map((event, idx) => {
                eventParamArr.push({
                  id: idx + 1,
                  url: API_URL_FILE + "api/v1/register",
                  params: {
                    eventId: event?.id,
                    categoryId: event?.eventRunCategories[0]?.id,
                    isTermsNcondition: true,
                    paymentMode: "NOT_APPLICABLE",
                    paymentStatus: "SUCCESS",
                    userId: userDetails?.id,
                  },
                });
              });
              if (eventParamArr?.length > 0) {
                registerMultipleEventCategory(eventParamArr).then((res) => {
                  let anyResponseFailed = res.find(
                    (data) => data.status === "rejected"
                  );
                  if (anyResponseFailed) {
                    Snackbar.show({
                      variant: "error",
                      isAutoHide: true,
                      preventDuplicate: true,
                      message: Properties.Something_went_wrong,
                    });
                  } else {
                    if (
                      userDetails?.isAuthorized ||
                      programDetails?.isConsentRequired === false
                    ) {
                      history.push(
                        "/program?distKey=" + programDetails?.distKey
                      );
                    } else {
                      history.push("/consent");
                    }
                  }
                });
              }
            }

            //-------------------------------------------------------------
            //-----------------Register Program Sessions------------------
            //-------------------------------------------------------------
            if (
              programDetails?.programSessions &&
              programDetails?.programSessions?.length
            ) {
              registerProgramSession();
            }

            //-------------------------------------------------------------
            //-----------------Register Program Challenge------------------
            //-------------------------------------------------------------
            if (
              programDetails?.programChallenges &&
              programDetails?.programChallenges?.length
            ) {
              programChallengeRegister(
                userDetails?.runnerId,
                programDetails?.id
              ).then(({ data }) => {
                if (programDetails?.isConsentRequired === false) {
                  history.push("/program?distKey=" + programDetails?.distKey);
                } else {
                  history.push("/consent");
                }
              });
            }

            //-------------------------------------------------------------
            //-----------------Only Program Register-----------------------
            //-------------------------------------------------------------
            if (
              !programDetails?.eventDTO &&
              !programDetails?.programChallenges &&
              !programDetails?.programSessions
            ) {
              if (programDetails?.isConsentRequired === false) {
                history.push("/calender");
              } else {
                history.push("/consent");
              }
            }

            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: `Registration Successful`,
            });
          }

          if (data?.success?.code === "409") {
            history.push("/calender");
          }
          if (data?.success?.code !== "200") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message:
                data?.success?.verbose || Properties.Something_went_wrong,
            });
          }
        })
        .catch((error) => {
          if (error.response?.data?.error?.code === "409") {
            history.push("/consent");
          }
        });
    }
  };

  const onModify = () => {
    let allFieldValues = { ...textFieldValues, ...selectFieldValues };
    let {
      participantEventsList,
      participantChallengeList,
      participantSessionList,
      lastName,
      ...requiredFields
    } = allFieldValues;
    const showHeightWeight = programDetails?.showHeightWeight;
    const showClimate = programDetails?.showClimate;
    const showEmployeeId = programDetails?.showEmployeeId;
    let fieldValueList = Object.entries(requiredFields);

    let _finalVal = fieldValueList;

    if (!showHeightWeight) {
      _finalVal = fieldValueList?.filter((item) => {
        const [key, value] = item;
        return key !== "height" && key !== "weight" && key !== "bmi";
      });
    }

    if (!showClimate) {
      _finalVal = fieldValueList?.filter((item) => {
        const [key, value] = item;
        return key !== "climate";
      });
    }

    let _finalValUpdated = _finalVal;

    if (departmentList && departmentList.length > 0) {
      _finalValUpdated = _finalVal;
    } else {
      _finalValUpdated = _finalVal?.filter((item) => {
        const [key, value] = item;
        return key !== "department";
      });
    }

    //Ankit Defect_id_899 - employee_id not mandatory
    let validatedValues = _finalValUpdated?.filter((item) => {
      const [key, value] = item;
        return key !== "employeeId";
    });

    let isFieldNotProvided = validatedValues?.filter((x) => {
      if (x[0] === "selectedMedicalCondition" || x[0] === "department") {
        if (x[1].value === undefined) return x;
      } else if (!x[1]) {
        return x;
      }
    });

    if (isFieldNotProvided?.length > 0) {
      setErrorField(true);
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: `Please provide required fields`,
      });
    } else {
      setErrorField(false);
      let eventIds = allFieldValues?.participantEventsList?.map(
        (event) => event?.value
      );
      let programSessionId = allFieldValues?.participantSessionList?.map(
        (event) => event?.value
      );
      let programChallengeIds = allFieldValues?.participantChallengeList?.map(
        (challenge) => challenge?.value
      );

      let params = {
        programId: programDetails?.id,
        programParticipantId: participantData?.id,
        userId: participantData?.runner?.userId,
        firstName: allFieldValues?.firstName,
        lastName: allFieldValues?.lastName,
        gender: participantData?.runner?.gender,
        age: participantData?.runner?.age,
        height: allFieldValues?.height,
        weight: allFieldValues?.weight,
        bmi: allFieldValues?.bmi,
        email: allFieldValues?.email,
        contactNumber: allFieldValues?.contactNumber,
        medicalCondition: allFieldValues?.selectedMedicalCondition?.value,
        employeeId: allFieldValues?.employeeId,
        department: allFieldValues?.department?.value,
        climate: allFieldValues?.climate?.value,
        eventIds,
        programSessionId,
        programChallengeIds,
      };

      if (!showClimate) {
        delete params.climate;
      }

      if (allFieldValues?.selectedMedicalCondition?.value) {
        params.medDescription = allFieldValues?.medicalConditionText;
      }


      modifyProgramParticipant(params).then((res) => {
        if (res.status === 200) {
          closeModifyWindow();
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: `Modified participant successfully`,
          });
          updateParticipantsList();
        }
      });
    }
  };

  return (
    <div>
      <AppToolBar>
        {modifyForm ? (
          <OrganizerCommonHeader
            title={
              programDetails?.programName || programDetails?.name
                ? programDetails?.programName || programDetails?.name
                : Properties.Label_ProgramDashboard
            }
          />
        ) : (
          <Header
            label={
              programDetails?.programName
                ? programDetails?.programName
                : Properties.Label_RegisterProgram
            }
            fromDate={ApplicationUtil.changeDateFormat(
              programDetails?.localStartDate
            )}
            toDate={ApplicationUtil.changeDateFormat(
              programDetails?.localEndDate
            )}
            title={programDetails?.programName}
          />
        )}

        <div style={{ width: 35 }} />
      </AppToolBar>

      <div
        className={classes.mainContainer}
        style={{
          marginTop: modifyForm ? "10px" : "80px",
          maxHeight: modifyForm && "70vh",
        }}
      >
        {modifyForm && (
          <Grid container xs={12}>
            <TextFieldContainer
              label={Properties.Label_FirstName}
              value={textFieldValues.firstName}
              required
              handleChange={onTextHandleChange}
              style={classes.textFieldContainer}
              name={"firstName"}
              error={errorField && !textFieldValues.firstName}
            />
            <TextFieldContainer
              label={Properties.Label_LastName}
              value={textFieldValues.lastName}
              handleChange={onTextHandleChange}
              style={classes.textFieldContainer}
              name={"lastName"}
              // error={errorField && !textFieldValues.lastName}
            />
          </Grid>
        )}
        <Grid container xs={12}>
          <TextFieldContainer
            label={Properties.Label_ContatNumber}
            value={textFieldValues.contactNumber}
            required
            disabled={!modifyForm && userDetails?.contactNumber ? true : false}
            handleChange={onTextHandleChange}
            style={classes.textFieldContainer}
            type="number"
            name={"contactNumber"}
            error={errorField && contactNumberError}
          />
          <TextFieldContainer
            label={Properties.Label_Email}
            value={textFieldValues.email}
            required
            disabled={userDetails?.email ? true : false}
            // disabled={!modifyForm}
            handleChange={onTextHandleChange}
            style={classes.textFieldContainer}
            name={"email"}
            error={errorField && !textFieldValues.email}
          />
        </Grid>
        {programDetails?.showHeightWeight && (
          <Grid container xs={12}>
            <TextFieldContainer
              label={`${Properties.Height_label} ${Properties.Label_unit_cm}`}
              value={textFieldValues.height}
              required
              handleChange={onTextHandleChange}
              style={classes.textFieldContainer}
              name={"height"}
              error={errorField && !textFieldValues.height}
            />
            <TextFieldContainer
              label={`${Properties.Weight_label} ${Properties.Label_Unit_kg}`}
              value={textFieldValues.weight}
              required
              handleChange={onTextHandleChange}
              style={classes.textFieldContainer}
              name={"weight"}
              error={errorField && !textFieldValues.weight}
            />
          </Grid>
        )}

        <Grid container xs={12}>
          {/* <TextFieldContainer
            label={Properties.Label_Department}
            value={textFieldValues.department}
            required
            handleChange={onTextHandleChange}
            style={classes.textFieldContainer}
            name={'department'}
            error={errorField && !textFieldValues.department}

          /> */}
          {departmentList && departmentList.length > 0 && (
            <SelectFieldContainer
              label={Properties.Label_Department}
              value={selectFieldValues.department}
              required
              onHandleChange={(value) =>
                onSelectHandleChange("department", value)
              }
              list={departmentList}
              error={errorField && !selectFieldValues.department?.value}
            />
          )}

          {programDetails?.showEmployeeId && (
              <TextFieldContainer
              label={Properties.Label_EmployeeId}
              value={textFieldValues.employeeId}
              // required
              handleChange={onTextHandleChange}
              style={classes.textFieldContainer}
              name={"employeeId"}
              // error={errorField && !textFieldValues.employeeId}
            />
           )} 
        
        </Grid>

        <Grid container xs={12}>
          <TextFieldContainer
            label={Properties.Label_Age}
            value={
              modifyForm ? participantData?.runner?.age : textFieldValues.age
            }
            required
            disabled={modifyForm}
            handleChange={onTextHandleChange}
            style={classes.textFieldContainer}
            name={"age"}
            error={errorField && !textFieldValues.age}
          />
          {programDetails?.showHeightWeight && (
            <TextFieldContainer
              label={Properties.BMIlabel}
              value={
                modifyForm ? participantData?.runner?.bmi : textFieldValues.bmi
              }
              // required
              handleChange={onTextHandleChange}
              style={classes.textFieldContainer}
              type="number"
              disabled
              name={"bmi"}
              error={errorField && !textFieldValues.bmi}
            />
          )}
        </Grid>

        <Grid container xs={12}>
          {programDetails?.showClimate && (
            <SelectFieldContainer
              label={Properties.Label_Climate}
              value={selectFieldValues.climate}
              required
              onHandleChange={(value) => onSelectHandleChange("climate", value)}
              list={ApplicationConstants.climateList}
              error={
                errorField && selectFieldValues.climate?.value === undefined
              }
            />
          )}

          <SelectFieldContainer
            label={Properties.Label_Medical_Condition}
            value={selectFieldValues.selectedMedicalCondition}
            required
            onHandleChange={(value) =>
              onSelectHandleChange("selectedMedicalCondition", value)
            }
            list={ApplicationConstants.medicalConditionList}
            error={
              errorField &&
              selectFieldValues.selectedMedicalCondition?.value === undefined
            }
          />
        </Grid>
        <Grid container xs={12}>
          {selectFieldValues?.selectedMedicalCondition?.value && (
            <TextFieldContainer
              label={Properties.Label_Specify_Condition}
              value={textFieldValues.medicalConditionText}
              required
              handleChange={onTextHandleChange}
              style={classes.textFieldContainer}
              name={"medicalConditionText"}
              error={
                errorField &&
                selectFieldValues?.selectedMedicalCondition &&
                !textFieldValues.medicalConditionText
              }
            />
          )}
        </Grid>
        {modifyForm && (
          <>
            <Grid container xs={12}>
              <SelectFieldContainer
                label={Properties.Events_label}
                value={selectFieldValues?.participantEventsList}
                isMulti={true}
                onHandleChange={(value) =>
                  onSelectHandleChange("participantEventsList", value)
                }
                list={programDetails?.programEventList}
                isMenuMaxHeight={true}
                // error={
                //   errorField &&
                //   selectFieldValues.participantEventsList?.length === 0
                // }
              />
              <SelectFieldContainer
                label={Properties.Sessions_label}
                value={selectFieldValues?.participantSessionList}
                isMulti={true}
                onHandleChange={(value) =>
                  onSelectHandleChange("participantSessionList", value)
                }
                isMenuMaxHeight={true}
                list={programDetails?.programSessionList}
                // error={
                //   errorField &&
                //   selectFieldValues.participantSessionList?.length === 0
                // }
              />
            </Grid>
            <Grid container xs={12}>
              <SelectFieldContainer
                label={Properties.Challenges_label}
                value={selectFieldValues?.participantChallengeList}
                isMulti={true}
                onHandleChange={(value) =>
                  onSelectHandleChange("participantChallengeList", value)
                }
                list={programDetails?.programChallengeList}
                isMenuMaxHeight={true}
                // error={
                //   errorField &&
                //   selectFieldValues.participantEventsList?.length === 0
                // }
              />
            </Grid>
          </>
        )}

        {!modifyForm && (
          <Grid container xs={12} display="flex" style={{ padding: "6px" }}>
            <div>
              <input
                type="checkbox"
                onChange={(event) =>
                  setTermsCheckboxSelected(event.target.checked)
                }
                value={termsCheckboxSelected}
                required
              />
            </div>
            <div style={{ flex: 1, marginLeft: "3px" }}>
              <span style={{ color: "gray", fontSize: "14px" }}>
                "I confirm that I am in good health and physically capable of
                participating in the fitness activities under this program. I
                also confirm that I don't have any pre-existing medical
                conditions or concerns which might put me to risk or injury."
              </span>
            </div>
          </Grid>
        )}
        <Grid container xs={12} style={{ marginTop: modifyForm && "20px" }}>
          <Grid item className="btnItem" xs={6}>
            <SubmitCancelBtn
              onClick={() => {
                if (modifyForm) {
                  closeModifyWindow();
                } else {
                  history.goBack();
                }
              }}
              label={Properties.CancelLabel}
              type="submit"
            />
          </Grid>
          <Grid item className="btnItem" xs={6}>
            <SubmitCancelBtn
              onClick={modifyForm ? onModify : onSubmit}
              isDisabled={!modifyForm && !termsCheckboxSelected}
              label={
                modifyForm ? Properties.Label_Modify : Properties.Label_Register
              }
              type="submit"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const TextFieldContainer = ({
  label,
  required,
  disabled = false,
  value,
  handleChange,
  style,
  type,
  name,
  error,
}) => {
  return (
    <Grid item className={style} xs={6}>
      <Typography variant="body2" gutterBottom>
        <span style={{ color: "#727272" }}>{label}</span>
        {required && <span style={{ color: "red" }}>*</span>}
      </Typography>

      <CustomTextField
        style={{
          border: error ? "1px solid red" : "1px solid #08456C",
          width: "100%",
        }}
        name={name}
        readOnly={disabled}
        type={type === "number" ? type : "text"}
        keyToSet={label}
        value={value}
        handleChange={(prop) => (event) => {
          handleChange(event);
        }}
      />
    </Grid>
  );
};
const SelectFieldContainer = ({
  label,
  required,
  list,
  error = false,
  value,
  onHandleChange,
  style,
  isMulti = false,
  isMenuMaxHeight = false,
}) => {
  return (
    <Grid item className={style} xs={6}>
      <Typography
        variant="body2"
        gutterBottom
        style={{ display: "flex", alignItems: "center" }}
      >
        <span style={{ color: "#727272" }}>{label}</span>
        {required && <span style={{ color: "red" }}>*</span>}
        {label === "Climate" && (
          <Tooltip title="Climate helps us calculate your expected water intake based on your current climate.">
            <IconButton style={{ padding: 0 }}>
              <CiCircleInfo size={15} />
            </IconButton>
          </Tooltip>
        )}
      </Typography>

      <MultiSelect
        isMenuMaxHeight={isMenuMaxHeight}
        value={value}
        error={error}
        isMulti={isMulti}
        options={list}
        onChange={onHandleChange}
      />
    </Grid>
  );
};
const styles = (theme) => ({
  mainContainer: {
    width: "55%",
    margin: "auto",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    //    "& .MuiGrid-container":{
    //     display:'flex',
    //     alignItems:'center',
    //     justifyContent:'center'
    //    },
    "& .btnItem": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .MuiGrid-item": {
      // flex:0.5,
      padding: "10px",
    },
  },
  textFieldContainer: {
    "& .MuiOutlinedInput-input": {
      padding: "6.5px 13px",
    },
  },
});
function mapStateToProps(state) {
  return {
    userSelectedProgram: state.user.userSelectedProgram,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateUserSelectedProgram,
  })
)(RegisterProgram);
