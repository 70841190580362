import React, { Component } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromHTML as draftConvertFromHtml,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./../../styles/NewTextEditor.css";
class EditorConvertToHTML extends Component {
  constructor(props) {
    super(props);

    let htmlState;
    if (this.props?.htmlText !== "") {
      const blocksFromHTML = draftConvertFromHtml(this.props.htmlText);
      htmlState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
    }
    this.state = {
      editorState:
        this.props?.htmlText !== ""
          ? EditorState.createWithContent(htmlState)
          : EditorState.createEmpty(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.htmlText !== this.props.htmlText && this.props.propsText) {
      const blocksFromHTML = draftConvertFromHtml(this.props.htmlText);
      const contentBlocks = blocksFromHTML.contentBlocks.reverse();
      const htmlState = ContentState.createFromBlockArray(
        contentBlocks,
        blocksFromHTML.entityMap
      );

      this.setState({
        editorState: EditorState.createWithContent(htmlState),
      });
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      editorContentHtml: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ),
    });

    if (this.props?.getEditedValue)
      this.props.getEditedValue(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      );

    if (this.props?.isResponses) {
      //added this props for to know that user is entered or not any text/response except the HTML tag - for validation purpose
      let val = convertToRaw(editorState.getCurrentContent())?.blocks || [];
      let isResponded = val?.some((obj) => obj?.text && obj?.text !== "");
      this.props.isResponses(isResponded);
    }
  };

  render() {
    const { editorState } = this.state;
    const { readOnly } = this.props;

    return (
      <div className="root">
        <Editor
          editorState={editorState}
          readOnly={readOnly}
          toolbarHidden={readOnly}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          toolbarClassName="toolbar"
          forceSelection={true}
          onEditorStateChange={this.onEditorStateChange}
          handlePastedText={() => false}
          toolbar={{
            options: ["inline", "blockType", "list", "history", "link"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
            blockType: {
              options: ["Normal", "H1", "H2", "H3", "H4"],
              className: "blockType",
            },
          }}
        />
      </div>
    );
  }
}

export default EditorConvertToHTML;
